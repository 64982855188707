import clsx from 'clsx';
import { cva, type VariantProps } from 'cva';
import React, { type ReactNode } from 'react';

// TODO: Update loader to support colorizing
import { Loader } from '@/components/common/loader/loader';
import { ComponentThemeClass } from '@/components/common/theme/component-theme';

import styles from './button-cva.module.scss';

const buttonVariants = cva(
  [
    styles.button,
    'inline-flex items-center justify-center',
    'truncate rounded-lg font-medium outline-none',
    'disabled:cursor-not-allowed disabled:shadow-none',
    'ring-offset-2 focus-visible:ring-2',
  ],
  {
    variants: {
      variant: {
        primary: [
          'bg-component-accent-500 text-gray-100',
          'fill-gray-50 stroke-gray-50',
          'transparent border border-solid',
          'hover:bg-component-accent-600',
          'ring-component-accent-600',
          'disabled:bg-gray-300',
        ],
        secondary: [
          'bg-component-accent-50 text-component-accent-500',
          'fill-component-accent-500 stroke-component-accent-500',
          'hover:bg-component-accent-100',
          'ring-component-accent-500',
          'disabled:text-muted-foreground disabled:bg-gray-100',
          'disabled:fill-gray-300 disabled:stroke-gray-300',
        ],
        subtle: [
          'bg-white text-gray-700',
          'fill-gray-500 stroke-gray-500',
          'border border-solid border-gray-300',
          'hover:bg-gray-100',
          'ring-component-accent-500',
          'disabled:text-muted-foreground disabled:bg-gray-50',
          'disabled:fill-gray-300 disabled:stroke-gray-300',
        ],
        raised: [
          'bg-gray-50 text-component-accent-500',
          'fill-component-accent-500 stroke-component-accent-500',
          'shadow transition-shadow hover:shadow-md',
          'ring-component-accent-500',
          'disabled:text-muted-foreground disabled:bg-gray-100',
          'disabled:fill-gray-300 disabled:stroke-gray-300',
        ],
        outline: [
          'bg-gray-50 text-component-accent-500',
          'fill-component-accent-500 stroke-component-accent-500',
          'border border-solid border-component-accent-200',
          'hover:bg-component-accent-50',
          'ring-component-accent-500',
          'disabled:bg-component-accent-50 disabled:text-component-accent-300',
          'disabled:fill-component-accent-200 disabled:stroke-component-accent-200',
        ],
        ghost: [
          'bg-transparent text-component-accent-500',
          'fill-component-accent-500 stroke-component-accent-500',
          'hover:bg-component-accent-50',
          'ring-component-accent-500',
          'disabled:text-component-accent-300',
          'disabled:fill-component-accent-200 disabled:stroke-component-accent-200',
        ],
      },
      size: {
        xs: ['gap-2 px-2.5 py-1.5 text-xs'],
        sm: ['gap-2 px-3 py-2 text-sm'],
        base: ['gap-2 px-4 py-2 text-sm'],
        lg: ['gap-3 px-4 py-2 text-base'],
        xl: ['gap-3 px-6 py-3 text-base'],
      },
      isFullWidth: {
        true: ['w-full'],
        false: ['w-fit'],
      },
      isIconOnly: { true: '' },
      isColumnDirection: { true: 'flex-col' },
      isTrailingIcon: { true: 'flex-row-reverse' },
      pointerEvents: {
        none: 'pointer-events-none',
        auto: 'pointer-events-auto',
      },
    },
    compoundVariants: [
      { isIconOnly: true, size: 'xs', class: '!p-1' },
      { isIconOnly: true, size: 'sm', class: '!p-1.5' },
      { isIconOnly: true, size: 'base', class: '!p-2' },
      { isIconOnly: true, size: 'lg', class: '!p-2' },
      { isIconOnly: true, size: 'xl', class: '!p-3' },
    ],
  },
);

type ButtonVariantProps = VariantProps<typeof buttonVariants>;

type CoreProps = {
  color?: 'accent' | 'motivation' | 'black' | 'red';
  variant?: ButtonVariantProps['variant'];
  size?: ButtonVariantProps['size'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isFullWidth?: boolean;
  isSubmitBtn?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isIconOnly?: boolean;
  isSolidIcon?: boolean;
  isColumnDirection?: boolean;
  pointerEvents?: 'none' | 'auto';
};

export const ButtonCVAWithChildren = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<CoreProps>
>(
  (
    {
      variant = 'primary',
      color = 'accent',
      size = 'base',
      isFullWidth = false,
      isSubmitBtn = false,
      disabled = false,
      isLoading = false,
      isIconOnly = false,
      isSolidIcon = false,
      isColumnDirection = false,
      onClick,
      children,
      pointerEvents,
    },
    ref,
  ): React.ReactElement => {
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      if (typeof onClick !== 'function') return;
      event.stopPropagation();
      onClick(event);
    };

    const classes = clsx(
      ComponentThemeClass,
      buttonVariants({
        variant,
        size,
        isFullWidth,
        isIconOnly,
        isColumnDirection,
        pointerEvents,
      }),
      {
        [styles.isSolidIcon]: isSolidIcon,
        [styles[size!]]: true,
      },
    );

    return (
      <button
        ref={ref}
        data-theme={color}
        type={isSubmitBtn ? 'submit' : 'button'}
        className={classes}
        disabled={disabled}
        onClick={handleClick}
      >
        {/* Prevent button size changing while displaying loading indicator */}
        {isLoading && (
          <>
            <div className="absolute">
              <Loader size="sm" transparent />
            </div>
            <div className="invisible">{children}</div>
          </>
        )}
        {!isLoading && children}
      </button>
    );
  },
);
ButtonCVAWithChildren.displayName = 'ButtonCVAWithChildren';

export const ButtonCVA = React.forwardRef<
  HTMLButtonElement,
  CoreProps & {
    text?: string;
    frontIcon?: ReactNode;
    trailingIcon?: ReactNode;
  }
>(
  ({ text, frontIcon, trailingIcon, ...props }, ref): React.ReactElement => (
    <ButtonCVAWithChildren {...props} ref={ref}>
      {frontIcon}
      {text}
      {trailingIcon}
    </ButtonCVAWithChildren>
  ),
);
ButtonCVA.displayName = 'ButtonCVA';
