import { LOGO } from 'Constants';

type AuthDashBoardProps = {
  children: React.ReactNode | [React.ReactNode];
  pageTitle: string;
};

export function AuthDashboardIndex({
  children,
  pageTitle,
}: AuthDashBoardProps): React.ReactElement {
  return (
    <div className="auth-dashboard__root">
      <div className="left">
        <div className="page-header__root">
          <img
            className="page-header__logo"
            width="48"
            height="48"
            src={LOGO}
            alt="f4s logo"
          />
          <div className="page-header__title">{pageTitle}</div>
        </div>
        {children}
      </div>
      <div className="right">
        <div className="right-content-default" />
      </div>
    </div>
  );
}
