import { passwordEstimate } from 'Utils/password-estimate';

type Props = {
  password: string;
  visible?: boolean;
};

const calculatePWDStrength = ({ password = '' }) => {
  const score = passwordEstimate(password);

  let strength;

  if (password === '' || score <= 0) {
    strength = { status: 1, color: '#777474', text: 'Weak' };
  } else if (score === 1) {
    strength = { status: 2, color: '#FFAC1D', text: 'So-so' };
  } else if (score === 2) {
    strength = { status: 3, color: '#A6C060', text: 'Good' };
  } else if (score >= 3) {
    strength = { status: 4, color: '#45C8A0', text: 'Great' };
  }

  return strength;
};

type PasswordStrengthProps = {
  password: string;
  visible?: boolean;
};

export const PasswordStrength: React.FC<Props> = ({
  password,
  visible = true,
}: PasswordStrengthProps) => {
  const strength = calculatePWDStrength({ password });
  if (!strength) return null;

  const blocks = Array.from({ length: 4 }).map((_, index) => {
    const color = index < strength?.status ? strength.color : '#E8E8E8';
    return (
      // Password strength blocks never change order

      <div key={index} className="password-block" style={{ backgroundColor: color }} />
    );
  });

  return (
    <div className={`password-strength-indicator clearfix ${!visible ? 'hidden' : ''}`}>
      {blocks}
      <span style={{ color: strength.color }} className="password-text">
        Password Strength: {strength.text}
      </span>
    </div>
  );
};
