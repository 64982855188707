import type { XOR } from 'ts-xor';

import { AuthDashboardFooter } from '@/layouts/auth-dashboard-v2/auth-dashboard-footer';
import { AuthDashboardHeader } from '@/layouts/auth-dashboard-v2/auth-dashboard-header';

import styles from './auth-dashboard-index-v2.module.scss';

type AuthDashBoardProps = {
  children: React.ReactNode | [React.ReactNode];
  displayCompleted?: boolean;
  displayLogin?: boolean;
  displaySignup?: boolean;
  rightContent?: React.ReactElement | null;
} & XOR<
  { displayStep: number; totalSteps: number },
  { displayStep?: false; totalSteps?: number }
>;

function AuthDashboardIndexV2({
  children,
  displayStep = false,
  totalSteps = 1,
  displayLogin = false,
  displaySignup = false,
  displayCompleted = false,
  rightContent,
}: AuthDashBoardProps): React.ReactElement {
  return (
    <div className={styles.authDashboardRootSignUp}>
      <div className="auth-dashboard__root">
        <div className="left">
          <AuthDashboardHeader
            displayStep={displayStep}
            totalSteps={totalSteps}
            displayLogin={displayLogin}
            displaySignup={displaySignup}
            displayCompleted={displayCompleted}
          />
          <div className="leftContent">
            <div className="contentWrapper">{children}</div>
          </div>
          <AuthDashboardFooter />
        </div>
        <div className="right">
          {rightContent || <div className="right-content-default" />}
        </div>
      </div>
    </div>
  );
}

export { AuthDashboardIndexV2 };
