import { PlusIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Loader } from '@/components/common/loader/loader';
import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';
import type { Callback } from 'Types/index';
import { errorHandler } from 'Utils/error-handler';

import { Typography } from '../common/typography/typography';

import './avatar-uploader.scss';

type Props = {
  image?: {
    preview: string;
    path?: string;
    name?: string;
  };
  buttonText?: string;
  customButtonComponent?: React.ReactElement;
  onFileSelected?: Callback<File>;
  showImageName?: boolean;
  showButton?: boolean;
  size?: 'md' | 'lg';
  bodyText?: string;
};

function getImageSrc(imageFile: any) {
  if (!imageFile) return '';
  if (imageFile.path) return URL.createObjectURL(imageFile);
  return imageFile?.preview || '';
}

export function AvatarUploader({
  image,
  buttonText,
  onFileSelected,
  showImageName = false,
  showButton = false,
  customButtonComponent,
  bodyText,
  size = 'lg',
}: Props): React.ReactElement {
  const [isUploading, setIsUploading] = useState(false);
  const [imageFile, setImageFile] = useState(image);

  const imageSrc = getImageSrc(imageFile);
  const imageClasses = imageSrc ? 'preview' : 'empty';

  const clearPreview = () => {
    if (imageFile?.preview) window.URL.revokeObjectURL(imageFile.preview);
  };
  const uploadImage = async (files: any[]) => {
    clearPreview();

    if (typeof onFileSelected === 'function') {
      setImageFile(files[0]);
      onFileSelected(files[0]);
      return;
    }

    setIsUploading(true);

    try {
      await API.utils.uploadImage(files[0]);
    } catch (error) {
      errorHandler(error, Notification);
    }

    setIsUploading(false);
  };

  useEffect(() => setImageFile(image), [JSON.stringify(image)]);

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept: { 'image/*': ['.jpeg', '.png'] },
    onDrop: uploadImage,
  });

  return (
    <div data-private className="image-uploader">
      <div className={clsx('comparison-image', imageClasses, `size-${size}`)}>
        <div className="dropzone-container" {...getRootProps()}>
          {isUploading ? (
            <Loader />
          ) : (
            <img alt="" src={imageSrc} className={imageClasses} />
          )}
          {!imageSrc && <PlusIcon className="h-5 w-5" />}

          <input {...getInputProps()} />
        </div>
      </div>
      {showImageName && imageFile?.name && (
        <div className="image-name">{imageFile.name}</div>
      )}
      {showButton && (
        <button type="button" onClick={() => open()} className="upload-button">
          {buttonText}
        </button>
      )}
      {(customButtonComponent || bodyText) && (
        <div className="ml-5 grid gap-2">
          {customButtonComponent &&
            React.cloneElement(customButtonComponent, { onClick: open })}
          <Typography size="sm">{bodyText}</Typography>
        </div>
      )}
    </div>
  );
}
