import clsx from 'clsx';
import { useState, type ChangeEventHandler } from 'react';

import { uniqueId } from 'Underscore';

import './checkbox.scss';

export function Checkbox({
  checked = false,
  inactive = false,
  type = 'square',
  label,
  className = '',
  onchange = () => {},
  darkVariant = false,
}: {
  checked?: boolean;
  inactive?: boolean;
  type?: string;
  label?: string;
  className?: string;
  onchange?: ChangeEventHandler<HTMLInputElement>;
  darkVariant?: boolean;
}) {
  const [labelId] = useState(() => uniqueId('input'));

  return (
    <label
      htmlFor={labelId}
      className={`checkbox_container ${label ? 'labelled' : ''} ${className}`}
    >
      <input
        id={labelId}
        type="checkbox"
        className="checkbox_input"
        onChange={onchange}
        checked={checked}
      />
      <span
        className={clsx(
          `checkbox_checkmark ${type}`,
          darkVariant && 'checkbox_checkmark_dark',
          inactive && 'inactive',
        )}
      />
      {label}
    </label>
  );
}
