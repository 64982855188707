import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { useUser } from '@/api-queries/app-settings';
import { useCoachingGoals } from '@/api-queries/coaching-goals';
import { useFocusAreas } from '@/api-queries/content';
import { Button } from '@/components/common/button/button';

import { type GoalStepType } from '../types';
import { Messages } from './goal-type-step.messages';

import styles from '../style-signup.module.scss';

export const GoalTypeStep = ({
  signUpDetails = {},
  handleInputChange,
  nextStep,
  handleSelectedGoal,
}: GoalStepType): React.ReactElement => {
  const [subAreas, setSubAreas] = useState([]);
  const { user } = useUser();

  const { data: allGoals } = useCoachingGoals(user.id);
  // useQuery custom hook
  const { data: focusArea } = useFocusAreas({ subarea: true, goals: true });
  const [preSelectedGoals, setPreSelectedGoals] = useState<(number | undefined)[]>([]);
  // this useEffect calls when API response back
  useEffect(() => {
    if (focusArea !== undefined) {
      setSubAreas(focusArea[0]?.subareas);
    }
  }, [focusArea]);
  const { goal } = signUpDetails;
  useEffect(() => {
    const preSelected = allGoals?.length
      ? allGoals.map((item) => item?.predefinedGoal?.subAreas?.id)
      : [];
    setPreSelectedGoals(preSelected);
  }, [allGoals]);

  const goalSelectHandler = (option: any) => {
    handleSelectedGoal({
      ...option,
      goals: [...option.goals],
    });
    handleInputChange?.('goal', option.id);
  };
  return (
    <>
      <div className={styles.signUpStepsHeadingBox}>
        <h2 className={styles.signUpStepsHeading}>{Messages.goalTypeTitle()}</h2>
        <p className="about-section-3">{Messages.goalTypeDescription()}</p>
      </div>
      <div className={clsx(styles.container, styles.mb20Px)}>
        {subAreas?.map(
          (option: {
            description: string;
            id: number;
            name: string;
            goals: Array<any>;
          }) => (
            <div key={option.id} className="mr-1.5 mt-3 inline-block">
              <Button
                variant={
                  option.id === goal || preSelectedGoals.includes(option.id)
                    ? 'secondary'
                    : 'white'
                }
                onClick={() => goalSelectHandler(option)}
                disabled={preSelectedGoals.includes(option.id)}
                text={option.name}
                size="lg"
              />
            </div>
          ),
        )}
      </div>
      <div className={styles.signupBtnWrapper}>
        <Button
          variant="primary"
          text={Messages.nextStepButton()}
          size="lg"
          disabled={goal === 0}
          onClick={nextStep}
          isFullWidth
        />
      </div>
    </>
  );
};
