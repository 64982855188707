import queryString, { type ParsedQuery, type ParseOptions } from 'query-string';
import { useLocation } from 'react-router-dom';

export function useQuery<
  T extends
    | ParsedQuery<string>
    | ParsedQuery<string | boolean>
    | ParsedQuery<string | boolean | number>
    | ParsedQuery<string | number>,
>(options?: ParseOptions): T {
  const { search } = useLocation();
  return queryString.parse(search, {
    parseBooleans: true,
    ...options,
  }) as T;
}
