import {
  type ZoomInvitationList,
  type ZoomVirtualForegroundPosition,
  type ZoomVirtualForegroundStatus,
} from '@f4s/types';
import { type ConvincerInputResultsData } from '@f4s/types/common/insights';

import { httpAgent as http } from './agents/http';

export const getAllIntegrations = () => http().get<string[]>(`/api/v2/channels/all`);

export const updateCandidate = (data: { token: string; status: 'signup' | 'complete' }) =>
  http().put('/api/v2/integrations/candidates', data);

export const getZoomMe = () => http().get('/api/v2/channels/zoom/me');

export const getZoomPublishableData = () =>
  http().get<{
    client_id: string;
    redirect_uri: string;
  }>('/api/v2/channels/zoom/publishable');

export const getZoomParticipants = (meetingId: string) =>
  http().get<number[]>(
    `/api/v2/channels/zoom/participants/${encodeURIComponent(meetingId)}`,
  );

export const getZoomParticipantResults = (meetingId: string) =>
  http().get<ConvincerInputResultsData>(
    `/api/v2/channels/zoom/participant-results/${encodeURIComponent(meetingId)}`,
  );

export const getZoomInvitationList = (meetingId: string) =>
  http().get<ZoomInvitationList>(
    `/api/v2/channels/zoom/invitation/${encodeURIComponent(meetingId)}`,
  );

export const setMeeting = (body: { meetingId: string }) =>
  http().post(`/api/v2/channels/zoom/setMeeting`, body);

export const inviteAllParticipants = (body: { meetingId: string }) =>
  http().post(`/api/v2/channels/zoom/invite-all-participants`, body);

export const resetZoomLinkId = (userId?: number | null) =>
  http().put(`/api/v2/channels/zoom/resetZoomLinkId`, { userId });

export const signoutZoomUser = () => http().delete(`/api/v2/channels/zoom/sign-out`);

export const getZoomVirtualForegroundStatus = () =>
  http().get<ZoomVirtualForegroundStatus>(
    '/api/v2/channels/zoom/virtualForegroundStatus',
  );

export const setZoomVirtualForegroundStatus = (data: ZoomVirtualForegroundStatus) =>
  http().put<ZoomVirtualForegroundStatus>(
    '/api/v2/channels/zoom/virtualForegroundStatus',
    data,
  );

export const setZoomVirtualForegroundPosition = (data: ZoomVirtualForegroundPosition) =>
  http().put<ZoomVirtualForegroundPosition>(
    '/api/v2/channels/zoom/virtual-foreground-position',
    data,
  );

export const getZoomVirtualForegroundPosition = () =>
  http().get<ZoomVirtualForegroundPosition>(
    '/api/v2/channels/zoom/virtual-foreground-position',
  );
