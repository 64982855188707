export const JobAreas = [
  'Marketing',
  'Sales',
  'People and Culture',
  'Product',
  'Engineering',
  'Leadership',
  'Supporting Startup Success',
  'Finance and Operations',
  'Other',
] as const;

export type JobAreaTypes = (typeof JobAreas)[number];
