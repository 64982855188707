import { z, type Schema } from 'zod';

type ObjectName = 'comparison';

const comparisonBaseSchema = z.object({
  groupSize: z.number(),
  includesPersonal: z.boolean(),
  includesDemoUsers: z.boolean(),
  includesGroup: z.boolean(),
  includesWorkspace: z.boolean(),
  comparedLocation: z.string().optional(),
  demoUserIds: z.string().optional(),
});

export type ComparisonAnalyticBase = z.infer<typeof comparisonBaseSchema>;

const comparisonSchemas = {
  comparison_compared: comparisonBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { comparisonSchemas };
