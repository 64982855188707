import { z, type Schema } from 'zod';

import { AskMarleeQuestionInputType, AskMarleeQuestionType } from '../common/ask-marlee';

type ObjectName = 'askMarlee';

const askMarleeBaseSchema = z.object({
  questionInputType: z.nativeEnum(AskMarleeQuestionInputType).optional(),
  questionType: z.nativeEnum(AskMarleeQuestionType).optional(),
  questionUserSize: z.number().optional(),
  questionIsAboutSelf: z.boolean().optional(),
  promptCardOrder: z.number().optional(),
  creditCount: z.number().optional(),
  source: z.enum(['widget', 'report', 'other']).optional(),
  widgetTitle: z.string().optional(),
  widgetType: z.string().optional(),
  dashboardTemplateSlug: z.string().optional(),
});

const askMarleeFeedbackSchema = z.object({
  questionInputType: askMarleeBaseSchema.shape.questionInputType.optional(),
  questionType: askMarleeBaseSchema.shape.questionType.optional(),
  questionUserSize: askMarleeBaseSchema.shape.questionUserSize.optional(),
  questionIsAboutSelf: askMarleeBaseSchema.shape.questionIsAboutSelf.optional(),
  promptCardOrder: askMarleeBaseSchema.shape.promptCardOrder,
  creditCount: askMarleeBaseSchema.shape.creditCount,
  feedbackResponse: z.enum(['positive', 'negative']),
  templateId: z.number().nullish(),
});

const askMarleeSchemas = {
  askMarlee_submitted: askMarleeBaseSchema,
  askMarlee_feedback: askMarleeFeedbackSchema,
  askMarlee_creditsEmptied: askMarleeBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { askMarleeSchemas };
