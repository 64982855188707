import { z } from 'zod';

/**
 * Sends an event to the Events API
 */
export const ProcessEventRequestSchema = z.object({
  /**
   * The associated user id. Can be an internal id (number) or a uuid (if origin is an external service)
   */
  userId: z.number().or(z.string().uuid()),
  eventName: z.string(),
  occurredAt: z
    .date()
    .nullable()
    .optional()
    .default(() => new Date()),
  executeAt: z.date().nullable().optional(),
  payload: z.any(),
});
export type ProcessEventRequest = z.infer<typeof ProcessEventRequestSchema>;
