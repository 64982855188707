import clsx from 'clsx';
import { type ReactElement } from 'react';

import { Image } from '@f4s/ui';

import { head } from '@/lib/underscore';
import MOTIVATIONS from 'Lib/motivations.json';

import styles from './profile.module.scss';

type OrgBadge = {
  name?: string;
  icon?: string;
  id: number;
};

type ProfileProps = {
  text?: string;
  imageUrl?: string;
  altText?: string;
  id?: number;
  orgBadges?: OrgBadge[] | null;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isExtraMembersCircle?: boolean;
};

const MAX_ORG_BADGES = 3;

const getMotivationForAvatar = (id = 1) => {
  const motivationCodeForUser = MOTIVATIONS[id % 48];
  return `ui-motivation-${motivationCodeForUser}`;
};

const renderBadge = (badge: OrgBadge) => {
  const { icon, name, id } = badge;

  if (icon && (icon.startsWith('http') || icon.startsWith('/'))) {
    return (
      <img
        key={icon}
        className={clsx(styles.badge, getMotivationForAvatar(id))}
        alt="badge"
        src={icon}
      />
    );
  }

  return (
    <div className={clsx(styles.badge, getMotivationForAvatar(id))}>
      {head(name)?.toUpperCase()}
    </div>
  );
};

export const Profile = ({
  text,
  imageUrl,
  size = 'sm',
  altText,
  orgBadges,
  id,
  isExtraMembersCircle,
}: ProfileProps): ReactElement => {
  const profileSize = styles[`${size}Size`];
  if (text) {
    return (
      <div data-private className={styles.profileRoot}>
        <div
          className={clsx(
            'rounded-full border-2 border-white',
            styles.profileText,
            profileSize,
            {
              [getMotivationForAvatar(id)]: id,
              [styles.extraMembersCircle]: isExtraMembersCircle,
            },
          )}
        >
          {text.toUpperCase()}
        </div>
        {!!orgBadges?.length &&
          orgBadges.slice(0, MAX_ORG_BADGES).map((x) => renderBadge(x))}
      </div>
    );
  }

  return (
    <div data-private className={styles.profileRoot}>
      <Image
        src={imageUrl!}
        alt={altText!}
        customStyle={clsx(
          'overflow-hidden rounded-full border-2 border-white',
          profileSize,
        )}
      />
      {!!orgBadges?.length &&
        orgBadges.slice(0, MAX_ORG_BADGES).map((x) => renderBadge(x))}
    </div>
  );
};
