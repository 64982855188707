import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { type Organization } from '@f4s/types';

import { Typography } from '@/components/common/typography/typography';
import { AvatarPlaceholder } from '@/components/invitation-modal/common/avatar-placeholder';

import { Messages } from './org-invite-banner.messages';

import styles from '../style-signup.module.scss';

export const OrgInviteBanner = ({
  org,
  token,
  from,
}: {
  org: Organization;
  token: string;
  from: string;
}) => {
  const isFromSignIn = from === 'sign-in';
  const orgType = org.type === 'space' ? 'workspace' : 'organization';

  return (
    <div
      className={clsx(styles.signUpOrgInviteBanner, {
        [styles.fromSignIn]: isFromSignIn,
      })}
    >
      {org.logo ? (
        <img alt="Organization logo" src={org.logo} style={{ width: 42, height: 42 }} />
      ) : (
        <AvatarPlaceholder name={org.name} />
      )}

      <Typography color="gray-500">
        {isFromSignIn ? (
          <>
            {Messages.orgInviteSignUpText(`${org.name} ${orgType}`)}
            <Link
              to={
                token ? `/sign-up/organization/join/${token}/create-account` : '/sign-up'
              }
              className={styles.orgBannerLink}
            >
              {Messages.signUpLinkText()}
            </Link>
          </>
        ) : (
          <>
            {Messages.orgInviteSignInText(`${org.name} ${orgType}`)}
            <Link
              to={token ? `/sign-in/organization/join/${token}/login` : '/sign-in'}
              className={styles.orgBannerLink}
            >
              {Messages.signInLinkText()}
            </Link>
          </>
        )}
      </Typography>
    </div>
  );
};
