import { httpAgent as http } from './agents/http';

export type ReferralProgram = {
  name: string;
  url: string;
  eligibility: any;
  createsConnection: boolean;
  showInList: boolean;
  landingPage: string;
  landingPageData: { [key: string]: any };
  id: number;
  createdAt: string;
  startsAt: string;
  endsAt: string;
  completionStage: 'sign-up' | 'questionnaire-completed';
  referrals: number;
};

export const getPrograms = (): Promise<{
  data: { active: ReferralProgram[]; historic: ReferralProgram[] };
}> => http().get('/api/v2/referrals/programs');

export const getProgram = (url: string): Promise<{ data: ReferralProgram }> =>
  http().get(`/api/v2/referrals/programs/${url}`);
