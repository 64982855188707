import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useUser } from '@/api-queries/app-settings';

import { CoachingLinkDone } from './coaching';

export function OauthRoot() {
  const { path } = useRouteMatch();
  const { isLoggedIn } = useUser();
  if (!isLoggedIn) return <Redirect to={`/sign-in?redir=${window.location.pathname}`} />;

  return (
    <Switch>
      <Route path={`${path}/coaching/done`} component={CoachingLinkDone} />
      <Route path={`${path}/:provider/:service`} component={Redirector} />
    </Switch>
  );
}

// Forces a hard redirect to get out of the react app, and to the server
function Redirector({ match }) {
  window.location.href = `/api/v2/oauth/link/${match.params.provider}/${match.params.service}`;
  return '';
}
