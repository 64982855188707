import type { XOR } from 'ts-xor';

import { type Organization } from '@f4s/types';

import {
  type CreatableTeamMember,
  type SelectableTeamMember,
  type Team,
} from '../../types';
import { getTeamMemberKey } from '../../utils';
import { MemberRow } from './member-row';
import { OrgInviteAlert } from './org-invite-alert';
import { MemberRemoveControls } from './remove-controls';
import { MemberRoleControls } from './role-controls';

export const MemberListBase = <T extends SelectableTeamMember>({
  members,
  renderControls,
  styleOverride,
}: {
  members: T[];
  renderControls?: (member: T) => React.ReactNode;
  styleOverride?: string;
}) => (
  <div className={styleOverride ?? 'flex flex-col gap-4'}>
    {members?.map((member) => (
      <MemberRow
        member={member}
        key={getTeamMemberKey(member)}
        controls={renderControls?.(member)}
      />
    ))}
  </div>
);

export const MemberListWithControls = ({
  members,
  updateMembers,
  onMemberRemoved,
  hasRemove,
  hasRoles,
  hasOrgInviteAlert,
  orgId,
  org,
  styleOverride,
}: {
  members: CreatableTeamMember[];
  updateMembers: (members: CreatableTeamMember[]) => void;
  hasRemove?: boolean;
  hasRoles?: boolean;
  styleOverride?: string;
  onMemberRemoved?: (member: CreatableTeamMember) => void;
} & XOR<
  { hasOrgInviteAlert: true; orgId: Team['organizationId']; org?: Organization },
  { hasOrgInviteAlert?: false }
>) => (
  <MemberListBase
    members={members}
    styleOverride={styleOverride}
    renderControls={(member: CreatableTeamMember) => (
      <>
        {hasOrgInviteAlert && <OrgInviteAlert member={member} orgId={orgId} org={org} />}
        {hasRoles && (
          <MemberRoleControls
            members={members}
            member={member}
            updateMembers={updateMembers}
          />
        )}
        {hasRemove && (
          <MemberRemoveControls
            members={members}
            member={member}
            updateMembers={updateMembers}
            onMemberRemoved={onMemberRemoved}
          />
        )}
      </>
    )}
  />
);
