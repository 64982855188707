import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { FormFlow } from './form-flow';

function SignInMagicInviteRoot(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:step?`} component={FormFlow} />;
    </Switch>
  );
}
export { SignInMagicInviteRoot };
