import queryString from 'query-string';
import { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { lazyPreload } from '@/lib/lazy-preload';
import { SigninRoot } from '@/pages/sign-in/root';
import { SignupRoot } from '@/pages/sign-up/root';
import { SnackBar } from 'Components/view/snackbar';
import { BrowserStorage } from 'Lib/utils/local-storage';

import { useMaybeUser } from './api-queries/app-settings';
import { Loader } from './components/common/loader/loader';
import { AppScrollArea } from './components/common/scroll-area/app-scroll-area';
import { useAppContext } from './contexts';
import { LanguageContext } from './contexts/language';
import { ScrollContextWrapper } from './contexts/scroll';
import { PageViewLogger } from './lib/page-view-logger';
import { identify } from './lib/segment';
import { OauthRoot } from './pages/oauth/root';
import { AfterSocialSignInOrUp } from './pages/oauth/social-home';
import { MemberJoinRoot } from './pages/sign-up/join/root';
import { ZoomInstallPage } from './pages/zoom/install-page';
import { isZoomClient } from './pages/zoom/util';
import { InviteRoutes, OrgMagicLinkRoute } from './routes/invite-routes';

const sessionStorage = BrowserStorage('session');

const LazyAppRoutes = lazyPreload(() => import('./routes/app-routes'), 'AppRoutes');
const LazyShareRoutes = lazyPreload(() => import('./routes/share-routes'), 'ShareRoutes');
const LazyLandingRoutes = lazyPreload(() => import('./pages/landing'), 'LandingRoutes');
const LazyOnboardAssessmentSignUpRoutes = lazyPreload(
  () => import('./routes/on-board-routes'),
  'OnboardRoutes',
);

export const App = (): React.ReactElement | null => {
  // async preload app-routes
  LazyAppRoutes.preload();
  // TODO: Convert to a hook? Yes
  const params = queryString.parse(window.location.search) as Partial<{
    refereeId: string;
    referralCode: string;
    from: string;
    utm_campaign: string;
    utm_medium: string;
    utm_source: string;
  }>;
  // Save sign-up tracking query params in session storage
  useEffect(() => {
    const referralCode = params.refereeId || params.referralCode || params.from;
    if (referralCode) sessionStorage.setItem('referralCode', referralCode);
    if (params.utm_campaign) sessionStorage.setItem('utmCampaign', params.utm_campaign);
    if (params.utm_medium) sessionStorage.setItem('utmMedium', params.utm_medium);
    if (params.utm_source) sessionStorage.setItem('utmSource', params.utm_source);
  }, [
    params.refereeId,
    params.referralCode,
    params.utm_campaign,
    params.utm_medium,
    params.utm_source,
  ]);

  useAppContext(LanguageContext);
  const { pathname, search, hash } = useLocation();

  const { isLoggedIn, isLoading, user } = useMaybeUser();
  useEffect(() => {
    if (user) identify(user);
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageViewLogger />
      <ScrollContextWrapper>
        <AppScrollArea>
          <SnackBar />
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* Invites
              Personal invite magic link: /invite/join/:token/general
              Personal invite via email: /invite/:token?type=connection
              Personal invite rejection: /invite/:token/reject

              Group invite via email: /invite/:token?type=group

              Org invite magic link: /join/org-name-slug?token=:token
              Org invite via email: /invite/:token?type=org
              Org invite rejection: /invite/:token/reject?type=org
            */}

              <Route path="/join" component={OrgMagicLinkRoute} />
              <Route path="/invite" component={InviteRoutes} />

              {/* Brand Join */}
              <Route path="/brand-join">
                <MemberJoinRoot isLoggedIn={isLoggedIn} />
              </Route>

              {/* Sign In / Sign Up / Social landing */}
              <Route path="/sign-in" component={SigninRoot} />
              <Route path="/sign-up" component={SignupRoot} />
              <Route path="/oauth" component={OauthRoot} />
              <Route path="/social-home" component={AfterSocialSignInOrUp} />

              {/* Public Landing Pages */}
              <Route path="/landing" component={LazyLandingRoutes} />

              {/* Public Sharing */}
              <Route path="/share" component={LazyShareRoutes} />

              <Route path="/on-board" component={LazyOnboardAssessmentSignUpRoutes} />

              {/* if we are not in the zoom client and the zoom path is accessed show the install zoom page */}
              {!isZoomClient && <Route path="/zoom" component={ZoomInstallPage} />}

              {/* Proceed to main app routes if logged in */}
              <Route path="*">
                {isLoggedIn ? (
                  <LazyAppRoutes />
                ) : (
                  <Redirect
                    to={`/sign-in?redir=${encodeURIComponent(
                      `${pathname}${search}${hash}`,
                    )}`}
                  />
                )}
              </Route>
            </Switch>
          </Suspense>
        </AppScrollArea>
      </ScrollContextWrapper>
    </>
  );
};
