import { z } from 'zod';

/**
 * Names of supported feature flags.
 * Add and remove here as feature flags are added and removed from the codebase
 */
export const FeatureFlags = {
  ahaTeamUpgradeFlowOptimization: 'Aha.TeamUpgradeFlowOptmization',
  shareInsights: 'share-insight',
  organizationInvitesEmailNov2022: 'Invites.Organization.Email.Nov2022',
  organizationInvitesLinkNov2022: 'Invites.Organization.Link.Nov2022',
  insightsOnly: 'insightsOnly',
  insightsFallback: 'insightsFallback',
  insightsMeRoles: 'Insights.MeRoles',
  insightsTeamRoles: 'Insights.TeamRoles',
  insightsAskMarlee: 'Insights.AskMarlee',
  insightsTeamDashboard: 'Insights.TeamDashboard',
  insightsTalentDashboard: 'Insights.TalentDashboard',
  experimentAhaBusinessEmail: 'Experiment.Aha.BusinessEmail',
  experimentAhaDebrief: 'Aha.DebriefExperiment',
  experimentAhaRecommendedTeam: 'Experiment.Aha.RecommendTeam',
  experimentAhaTeamFocusedSignupFlow: 'Experiment.Aha.TeamFocusedSignupFlow',
  experimentMonetizationBanner: 'Experiment.Monetization.Banner',
  experimentMonetizationHumanCoaching: 'Experiment.Monetization.Human.Coaching',
  experimentMonetizationRTAModal: 'Experiment.Monetization.RTAModal',
  ahaTeamActivation: 'Aha.TeamActivation',
  itJustWorks: 'Aha.ItJustWorks',
  monetizationCoachMarleeLimit: 'Monetization.CoachMarleeLimit',
  monetizationFeatureUpgrade: 'Monetization.FeatureUpgrade',
  researchQuestions: 'Research.Questions',
  zoomAnnouncement: 'Announcement.Zoom',
  monetizationNewPlanUpgradeFlow: 'Monetization.NewPlanUpgradeFlow',
  itJustWorksPt2: 'Aha.ItJustWorksPt2',
  setupGuide: 'Aha.SetupGuide',
  i18nLocaleSelector: 'i18n.LocaleSelector',
} as const;

export type FeatureFlag = (typeof FeatureFlags)[keyof typeof FeatureFlags];

export type FeatureFlagMap = Record<FeatureFlag, boolean>;

export const FeatureContextSchema = z.object({
  userId: z.number().optional(),
  properties: z.object({
    createdAt: z.date().optional(),
    organizationNames: z.string().optional(),
    emailAddress: z.string().email().optional(),
    organizationType: z.string().optional(),
  }),
});

/**
 * A context object ot pass into the feature flag check
 */
export type FeatureContext = z.infer<typeof FeatureContextSchema>;
