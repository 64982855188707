import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@/components/common/button/button';
import { Row } from '@/components/common/layouts/row/row';
import { LinkButton } from '@/components/common/link-button/link-button';
import { Input } from '@/components/form_elements';
import { AuthDashboardIndex } from '@/layouts/auth-dashboard-index';
import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';
import { usePageView } from 'Hooks/usePageView';
import { errorHandler } from 'Utils/error-handler';
import { formSubmit } from 'Utils/form-submit';

import {
  getPendingMiniAssessmentInfo,
  removeLocalStorageForOnboarding,
} from './mini-assessment';

export function ForgotPassword() {
  usePageView('Forgot Password');

  const [emailAddress, setEmailAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onSubmit = (e) =>
    formSubmit(e, async () => {
      setLoading(true);

      // Get locally saved assessment answers to link to password reset token sign-in
      const { areQuestionsAnswered, blockSlug, answers } = getPendingMiniAssessmentInfo();
      const pendingActions =
        areQuestionsAnswered && blockSlug && answers
          ? [{ type: 'assessmentCreate', data: { blockSlug, answers } }]
          : undefined;

      // Remove in case they are double-handled later.
      removeLocalStorageForOnboarding();

      try {
        const {
          data: { message },
        } = await API.password.resetRequest({ emailAddress, pendingActions });
        Notification.create(message, 'success');
        history.push('/sign-in');
      } catch (error) {
        errorHandler(error, Notification);
      }
      setLoading(false);
    });

  return (
    <AuthDashboardIndex pageTitle="Forgot your password?">
      <form className="login-form__root" onSubmit={onSubmit}>
        <div className="login-form__subtitle text-center">
          Enter your email here and we’ll send you a password reset link.
        </div>
        <Input
          type="email"
          label="Email address"
          onchange={setEmailAddress}
          autoFocused
          forceFocused
          required
        />
        <Button
          variant="primary"
          text="Continue"
          isLoading={loading}
          isSubmitBtn
          isFullWidth
        />
        <Row justify="center" padding="sm">
          <LinkButton to="/sign-in" variant="link" text="Return to sign in" size="sm" />
        </Row>
      </form>
    </AuthDashboardIndex>
  );
}
