import { z, type Schema } from 'zod';

type ObjectName = 'sort';

export const sortTrackingEventSchema = z.object({
  feature: z.enum(['insights']),
  infoEngaged: z.boolean(),
  searchEngaged: z.boolean(),
  sortingDisplay: z.enum([
    'highestFirst',
    'lowestFirst',
    'alphabeticalAsc',
    'alphabeticalDesc',
    'lastViewed',
    'featured',
  ]),
  sortingFilter: z.string(),
  sortingView: z.enum(['grid', 'list']),
});

const sortSchemas = {
  sort_clicked: sortTrackingEventSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { sortSchemas };

export type sortTrackingEvent = z.infer<typeof sortTrackingEventSchema>;
