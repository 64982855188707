import { z, type Schema } from 'zod';

type ObjectName = 'share';

const shareBaseSchema = z.object({
  shareName: z.string(),
  shareType: z.enum(['link']),
});

const v1ShareSchema = shareBaseSchema.extend({ shareResults: z.boolean() });

const v2ShareSchema = shareBaseSchema.extend({
  shareCode: z.string(),
  reportId: z.number().optional(),
  dashboardId: z.number().optional(),
});

const shareSchemas = {
  share_clicked: v1ShareSchema,
  share_copied: v1ShareSchema,
  share_created: v2ShareSchema,
  share_viewed: v2ShareSchema,
  share_onboard: v2ShareSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { shareSchemas };
