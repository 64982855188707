import { Loader } from '@/components/common/loader/loader';

type Props = {
  header?: React.ReactNode;
  children: React.ReactNode | [React.ReactNode];
  loading?: boolean;
};
const Loading = () => (
  <div className="loading-container">
    <Loader />
  </div>
);

export const SignupOnboardingIndex: React.FC<Props> = ({
  header,
  children,
  loading = false,
}) => {
  const headerComponent = header || (
    <img className="logo" src="/assets/f4s_mark.svg" alt="Fingerprint for Success" />
  );

  return (
    <div className="signup-flow-onboarding">
      {headerComponent}
      {loading ? <Loading /> : children}
    </div>
  );
};
