import { z, type Schema } from 'zod';

type ObjectName = 'brain';

const brainDummySchema = z.object({});

const brainSchemas = {
  brain_dummy: brainDummySchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

type BrainEventKeys = keyof typeof brainSchemas;

export { brainSchemas, type BrainEventKeys };
