import { z, type Schema } from 'zod';

type ObjectName = 'setupGuide';

const setupGuideBaseSchema = z.object({
  currentCompletionPercentage: z.number(),
  source: z.enum(['mobile', 'desktop']),
});

const setupGuideItemBaseSchema = z.object({
  isComplete: z.boolean(),
  itemName: z.string(),
});

const setupGuideCompletedSchema = z.object({
  source: z.enum(['mobile', 'desktop']),
  isComplete: z.boolean(),
});

const setupGuideSchemas = {
  setupGuide_viewed: setupGuideBaseSchema,
  setupGuide_itemClicked: setupGuideItemBaseSchema,
  setupGuide_completed: setupGuideCompletedSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { setupGuideSchemas };
