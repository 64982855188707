import { z, type Schema } from 'zod';

type ObjectName = 'zoomApp';

const zoomBaseSchema = z.object({});

const zoomMeetingSchema = zoomBaseSchema.extend({
  zoomMeetingStatus: z.enum(['started', 'finished']),
});

const zoomEmojiSchema = zoomBaseSchema.extend({
  zoomEmojis: z.enum(['added', 'deleted']),
});

const zoomEmojiPositionSchema = zoomBaseSchema.extend({
  zoomEmojisPosition: z.enum(['bottomRight', 'bottomLeft']),
});

const zoomClickSchema = zoomBaseSchema.extend({
  zoomDownloadedApp: z.boolean(),
  source: z.string(),
});

const zoomSchemas = {
  zoomApp_clicked: zoomClickSchema,
  zoomApp_added: zoomEmojiSchema,
  zoomApp_started: zoomMeetingSchema,
  zoomApp_finished: zoomMeetingSchema,
  zoomApp_deleted: zoomEmojiSchema,
  zoomApp_switched: zoomEmojiPositionSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { zoomSchemas };
