import { z } from 'zod';

const RecipientSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  emailAddress: z.string(),
});

export const RecipientsSchema = RecipientSchema.array();

export type Recipient = z.infer<typeof RecipientSchema>;
