import { Link } from 'react-router-dom';

import { useZoomPublishableData } from '@/api-queries/zoom';

export const ZoomButton = () => {
  const { data } = useZoomPublishableData();

  return (
    <Link
      target="_blank"
      to={{
        pathname: `https://zoom.us/oauth/authorize?response_type=code&state=f4sApp&client_id=${data?.client_id}&redirect_uri=${data?.redirect_uri}`,
      }}
      className="m-1 w-48"
      rel="noopener noreferrer"
    >
      <img
        src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
        height="32"
        alt="Add F4S to ZOOM"
      />
    </Link>
  );
};
