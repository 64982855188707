import { useHistory } from 'react-router-dom';

import { useUser } from '@/api-queries/app-settings';
import { Button } from '@/components/common/button/button';
import styles from '@/pages/sign-up/sign-up-by-invites/style-sign-up-by-invites.module.scss';

import { Typography } from '../common/typography/typography';
import { Messages } from './index.messages';
import { type RequestSubmittedType } from './types';

export const RequestSubmitted = ({ nextStep, orgName }: RequestSubmittedType) => {
  const { user } = useUser();
  const history = useHistory();
  const requestSubmission = () => {
    if (!user.isQuestionnaireCompleted) {
      nextStep();
    } else {
      history.push('/me');
    }
  };
  return (
    <div>
      <div className={styles.signUpStepsHeadingBox}>
        <h2 className={styles.signUpStepsHeading}>{Messages.requestSubmittedTitle()}</h2>
        <p className={styles.signUpHeadingDesc}>
          <Typography color="gray-500">
            {Messages.requestSubmittedDescription(orgName)}
          </Typography>
        </p>
        <p className={styles.signUpHeadingDesc}>
          <Typography color="gray-500">
            {user.isQuestionnaireCompleted
              ? Messages.checkoutAppInstruction()
              : Messages.takeAssessmentInstruction()}
          </Typography>
        </p>
      </div>

      <Button
        variant="primary"
        text={Messages.nextStepButton()}
        onClick={requestSubmission}
        size="lg"
        isFullWidth
      />
    </div>
  );
};
