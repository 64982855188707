import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';

import { useUser } from '@/api-queries/app-settings';
import { SignupOnboardingIndex } from '@/layouts/signup-onboarding-index';
import { FlowForm } from 'Components/flow-form';
import { Notification } from 'Components/view/snackbar';
import joinScript from 'JSONs/member-join.json';
import * as segment from 'Lib/segment';
import { get, isEmpty } from 'Underscore';

import { getLocalOrgData } from '../utils';
import { verifyOrgOrClient } from './join-utils';

export function MemberWelcome(): React.ReactElement {
  const history = useHistory();
  const { signOut } = useUser();

  const [orgOrClient, setOrgOrClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    hasAccountResponse: '',
    orgOrClientName: '',
    message1: AppText.org_sign_up_welcome_intro_1,
    message2: AppText.org_sign_up_welcome_intro_2,
    message3: AppText.org_sign_up_welcome_intro_3,
  });

  const getOrgOrClient = async () => {
    const orgOrClientData = await verifyOrgOrClient();

    if (isEmpty(orgOrClientData)) {
      history.push('/sign-in');
    }

    segment.pageView('Organization Sign Up Welcome', {
      orgName: 'name' in orgOrClientData ? orgOrClientData.name : undefined,
    });

    setOrgOrClient(orgOrClientData);
    setData({
      ...data,
      orgOrClientName: 'name' in orgOrClientData ? orgOrClientData.name : '',
    });
    setLoading(false);
  };

  useEffect(() => {
    getOrgOrClient();
  }, []);

  useEffect(() => {
    if (loading) return;
    const { type } = getLocalOrgData();
    history.replace(`?${type}=${get(orgOrClient, 'name')}`);
  }, [loading]);

  return (
    <SignupOnboardingIndex loading={loading}>
      <FlowForm
        script={joinScript}
        data={data}
        onDataChange={(v) => {
          setData(v);
        }}
        onStepChange={() => {
          if (data.hasAccountResponse === 'no') {
            return signOut('/sign-up');
          }
          if (data.hasAccountResponse === 'yes') {
            return signOut();
          }
          return Notification.create('Oops, something wrong!', 'error');
        }}
      />
    </SignupOnboardingIndex>
  );
}
