import { type InputOption } from './types';

export function getOptions(
  props: {
    options?: InputOption[] | Record<string, InputOption>;
    optionsVariable?: string;
  },
  data: Record<string, any>,
): InputOption[] | Record<string, InputOption> | string[] {
  if (props.options) return props.options;

  if (props.optionsVariable) return data[props.optionsVariable];

  throw new Error('Unable to find options');
}
