import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useQuery } from '@/lib/hooks/useQuery';
import { UserReferralRoot } from '@/pages/referrals';
import { RejectInvite } from '@/pages/reject-invite/reject';
import { InviteRoot } from '@/pages/sign-up/invite-root';

import 'Styles/app-full.scss';

// Personal invite magic link: /invite/join/:token/general
export const InviteRoutes = (): React.ReactElement => {
  const { path } = useRouteMatch();
  const { type } = useQuery<{ type: string }>();

  return (
    <Switch>
      <Route path={`${path}/join/:token/:campaign`} component={UserReferralRoot} />
      <Route path={`${path}/:token/reject`} component={RejectInvite} />
      <Route
        path={`${path}/:token`}
        component={type === 'org' ? RedirectToOrgSignUp : InviteRoot}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const RedirectToOrgSignUp = (): React.ReactElement => {
  const { token } = useParams<{ token: string }>();
  return <Redirect to={`/sign-up/organization/invite/${token}`} />;
};

export const OrgMagicLinkRoute = (): React.ReactElement => {
  const { isLoggedIn } = useMaybeUser();
  const { token } = useQuery<{ token: string }>();

  if (token) {
    return (
      <Redirect
        to={
          isLoggedIn
            ? `/sign-in/organization/join/${token}`
            : `/sign-up/organization/join/${token}`
        }
      />
    );
  }

  return <Redirect to="/" />;
};
