import emojiUnicode from 'emoji-unicode';

import { EMOJI_DIR, EMOJI_EXT } from 'Constants';

// Get the unicode string from the emoji
// In the same format that JoyPixels store their images
function getEmojiCode(emoji: string): string {
  return emojiUnicode(emoji)
    .split(' ')
    .map((code) => code.padStart(4, '0'))
    .filter((code) => code !== 'fe0f') // Remove default variation selector
    .filter((code) => code !== '200d') // Remove emoji joining selector
    .join('-');
}

// Get the image path for the unicode emoji
export function getEmojiPath(emoji: string) {
  const unicode = getEmojiCode(emoji);
  return `${EMOJI_DIR}${unicode}${EMOJI_EXT}`;
}
