import { z, type Schema } from 'zod';

type ObjectName = 'user';

// TODO: Flesh out these event schemas
const userSchemas = {
  user_created: z.object({
    createdAt: z.date(),
    signupMethod: z.string().optional(),
  }),
  user_loaded: z.object({}),
  user_updated: z.object({}),
  user_deleted: z.object({}),
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { userSchemas };
