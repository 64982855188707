import clsx from 'clsx';
import { type ReactElement } from 'react';

import { getTypographyStyles } from '../typography';
import { type TypographyProps } from '../typography.props';

import styles from './paragraph.module.scss';

export const Paragraph = ({
  children,
  asDiv = false,
  ...typographyProps
}: TypographyProps & { asDiv?: boolean }): ReactElement => {
  if (asDiv) {
    return (
      <div className={clsx(styles.paragraph, getTypographyStyles(typographyProps))}>
        {children}
      </div>
    );
  }
  return (
    <p className={clsx(styles.paragraph, getTypographyStyles(typographyProps))}>
      {children}
    </p>
  );
};
