import { Switch } from 'react-router-dom';

import { FormFlowRoute } from './form-flow';

function InviteSignUpRoot(): React.ReactElement {
  return (
    <Switch>
      <FormFlowRoute />
    </Switch>
  );
}

export { InviteSignUpRoot };
