import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as segment from './segment';

export function PageViewLogger() {
  const location = useLocation();
  useEffect(() => {
    segment.pageView(undefined);
  }, [location.pathname]);
  return null;
}
