import { Messages } from './general.messages';
import { ReferralPageTemplate } from './referral-template';

export const General = ({ referrer }) => (
  <ReferralPageTemplate>
    <h2>{Messages.invitationTitle(referrer?.name)}</h2>
    <p>{Messages.invitationInstruction()}</p>
    <ul>
      <li>{Messages.coachingAccess()}</li>
      <li>{Messages.assessmentAccess()}</li>
      <li>{Messages.connectionAccess(referrer?.name)}</li>
      <li>{Messages.teamBuildingToolsAccess()}</li>
      <li>{Messages.additionalAccess()}</li>
    </ul>
  </ReferralPageTemplate>
);
