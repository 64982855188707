import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FeatureFlags } from '@f4s/types';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useConnectionsInvalidation } from '@/api-queries/connections';
import { useGetMagicLinkByToken } from '@/api-queries/magic-links';
import {
  useAcceptInvitation,
  useDeclineInvitation,
  useGetInvitation,
  useGetReducedOrganizationByMagicLinkToken,
  useJoinOrganization,
  useUpdateMemberStatus,
  useVerifyToken,
} from '@/api-queries/organizations';
import { sendEvent } from '@/apis/analytics';
import { Button } from '@/components/common/button/button';
import { Loader } from '@/components/common/loader/loader';
import { Typography } from '@/components/common/typography/typography';
import { ReturnToContext, useAppContext } from '@/contexts';
import { ClosedLockIcon, EyeIcon } from '@/icons';
import { redirectAsync } from '@/lib/underscore';

import { type JoinStepType } from '../types';
import { Messages } from './join-org-step.messages';

import styles from '../style-sign-up-by-invites.module.scss';

export const JoinOrg = ({
  nextStep,
  magicLink = false,
}: JoinStepType): React.ReactElement => {
  const params = useParams<{ token: string }>();
  const { user } = useMaybeUser();
  const history = useHistory();
  const isItJustWorksFlagged = useFlag(FeatureFlags.itJustWorks);
  const { setReturnURL } = useAppContext(ReturnToContext);
  const { mutateAsync: acceptInvitation, isLoading: isAccepting } = useAcceptInvitation(
    params.token,
  );
  const refetchConnections = useConnectionsInvalidation();
  const {
    refetch: getInvitation,
    data: inviteData,
    isLoading: inviteLoading,
  } = useGetInvitation({
    token: params.token,
    enabled: false,
  });
  const { mutateAsync: declineInvitation, isLoading: declineLoading } =
    useDeclineInvitation(params.token);
  const {
    refetch: verifyToken,
    data: tokenData,
    isLoading: verifyLoading,
    isError: tokenError,
  } = useVerifyToken({
    token: params.token,
    enabled: false,
  });
  const { mutateAsync: joinOrg, isLoading: joinOrgLoading } = useJoinOrganization();
  const { mutateAsync: memberStatus, isLoading: memberStatusLoading } =
    useUpdateMemberStatus();
  const { isLoading: isLoadingMagicLinkData, data: magicLinkData } =
    useGetMagicLinkByToken(params.token, isItJustWorksFlagged);
  const { isLoading: isLoadingOrgData, data: orgData } =
    useGetReducedOrganizationByMagicLinkToken(magicLinkData?.token);

  const trackInviteEvent = (eventName: 'invite_accepted' | 'invite_rejected') => {
    sendEvent(eventName, {
      inviteType: 'workspace',
      inviteTargetUserType: user?.id ? 'existing' : 'new',
      inviteTargetUserId: user?.id ?? undefined,
      inviteTargetEmail: user?.emailAddress ?? undefined,
    });
  };

  const acceptInvitationHandler = async () => {
    trackInviteEvent('invite_accepted');
    if (magicLink) {
      // This api adds user with pending status
      await joinOrg({
        id: (isItJustWorksFlagged ? orgData : tokenData).id,
      });
      await memberStatus({
        id: (isItJustWorksFlagged ? orgData : tokenData)?.id,
        status: 'approved',
        from: 'joinLink',
      });

      refetchConnections();
      setReturnURL(
        `/teams/insights?organizationId=${
          (isItJustWorksFlagged ? orgData : tokenData).id
        }`,
      );

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isItJustWorksFlagged
        ? nextStep(1)
        : nextStep(tokenData?.permission?.autoApproveJoinRequest ? 2 : 1);
    } else {
      await acceptInvitation(params.token);

      if (inviteData?.organization.id) {
        refetchConnections();
        setReturnURL(`/teams/insights?organizationId=${inviteData?.organization.id}`);
      }
      nextStep();
    }
  };

  const rejectInvitationHandler = async () => {
    trackInviteEvent('invite_rejected');
    // magic link doesn't have to 'reject' as it wasn't sent an invitation
    if (magicLink && user) {
      if (!user.isQuestionnaireCompleted) {
        nextStep(2);
      } else {
        history.push('/me');
      }
    } else {
      await declineInvitation(params.token);
      nextStep();
    }
  };

  const orgName = inviteData ? inviteData?.organization?.name : tokenData?.name;
  const orgType = inviteData ? inviteData?.organization?.type : tokenData?.type;
  const orgTypeName = orgType === 'space' ? 'workspace' : 'organization';
  const buttonText =
    orgType === 'space'
      ? Messages.joinWorkspaceButton()
      : Messages.joinOrganizationButton();

  useEffect(() => {
    if (magicLink) {
      const getVerification = async () => {
        const { error, data }: { error: any; data: any } = await verifyToken();
        if (tokenError && error?.response?.status === 404) {
          await redirectAsync(`/sign-up?redir=${window.location.pathname}`);
        } else if (user && user?.organizations?.find((org) => org.id === data?.id)) {
          // if login user is member of organization already then don't show join-org screen
          history.push('/');
        }
      };

      const getVerificationV2 = async () => {
        if (!isLoadingMagicLinkData) {
          if (magicLinkData) {
            // if login user is member of organization already then don't show join-org screen
            if (
              user &&
              user?.organizations?.find((org) => org.id === magicLinkData.organizationId)
            ) {
              // TODO Add notification `User is already part of org with id ${magicLinkData.organizationId}`
              history.push('/');
            }
          } else {
            await redirectAsync(`/sign-up?redir=${window.location.pathname}`);
          }
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isItJustWorksFlagged ? getVerificationV2() : getVerification();
    } else {
      // User comes from an org invite, not a magic link
      const getInvite = async () => {
        const { error }: { error: any } = await getInvitation();

        if (tokenError && error?.response?.status === 404) {
          await redirectAsync(`/sign-up?redir=${window.location.pathname}`);
        }
      };
      getInvite();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInvitation, magicLink, params.token, verifyToken, user, orgData]);

  const orgPermission = [
    {
      id: 1,
      canSee: true,
      list: [Messages.insightsPermission()],
    },
    {
      id: 2,
      canSee: false,
      list: [
        Messages.coachingConversationPermission(),
        Messages.personalConnectionPermission(),
        Messages.privateNotesPermission(),
      ],
    },
  ];

  return (
    <>
      {(isItJustWorksFlagged
        ? magicLink
          ? !isLoadingOrgData || !isLoadingMagicLinkData
          : !inviteLoading
        : inviteData !== undefined || tokenData !== undefined) && (
        <div>
          <div className={styles.signUpStepsHeadingBox}>
            <div className={styles.signUpStepsHeadingWithImgWrapper}>
              <h2 className={styles.signUpStepsHeading}>
                {Messages.joinOrgTitle(
                  `${magicLink && isItJustWorksFlagged ? orgData?.name : orgName}`,
                )}
              </h2>
              <div className={styles.signUpStepHeadingImgWrapper}>
                <img
                  className={styles.signUpStepHeadingImg}
                  src={inviteData ? inviteData?.organization?.logo : tokenData?.logo}
                  alt="icon"
                />
              </div>
            </div>
            <p className={styles.signUpHeadingDesc}>
              <Typography color="gray-500">
                {Messages.joinOrgDescription(
                  `${magicLink && isItJustWorksFlagged ? orgData?.name : orgName} ${
                    magicLink && isItJustWorksFlagged
                      ? orgType === 'space'
                        ? 'workspace'
                        : 'organization'
                      : orgTypeName
                  }.`,
                )}
              </Typography>
            </p>
          </div>

          {/* With Loop Markup  */}
          <div className={styles.orgPermissionBox}>
            {orgPermission.map((permissionType) => (
              <div
                className={
                  permissionType.canSee ? styles.orgCanSeeBox : styles.orgCanNotSeeBox
                }
                key={permissionType.id}
              >
                <p className={styles.orgPermissionHead}>
                  {magicLink && isItJustWorksFlagged ? orgData?.name : orgName}{' '}
                  <Typography color="gray-500">
                    {permissionType.canSee
                      ? Messages.allowedDescription()
                      : Messages.notAllowedDescription()}
                  </Typography>
                </p>
                <ul className={styles.orgPermissionList}>
                  {permissionType.canSee
                    ? permissionType.list.map((itemText) => (
                        <li className={styles.orgPermissionListItem} key={itemText}>
                          <div className={styles.orgPermissionListItemImgBox}>
                            <EyeIcon />
                          </div>
                          <Typography color="gray-500">{itemText}</Typography>
                        </li>
                      ))
                    : permissionType.list.map((itemText) => (
                        <li className={styles.orgPermissionListItem} key={itemText}>
                          <div className={styles.orgPermissionListItemImgBox}>
                            <ClosedLockIcon />
                          </div>
                          <Typography color="gray-500">{itemText}</Typography>
                        </li>
                      ))}
                </ul>
              </div>
            ))}
          </div>
          <div className={styles.signUpButtonsBox}>
            <div className={clsx(styles.signupBtnWrapper, styles.skipBtnWrapper)}>
              <Button
                variant="white"
                text={Messages.rejectInviteButton()}
                size="lg"
                onClick={() => rejectInvitationHandler()}
                isLoading={declineLoading}
                isFullWidth
              />
            </div>
            <div className={styles.signupBtnWrapper}>
              <Button
                variant="primary"
                text={buttonText}
                size="lg"
                isLoading={isAccepting || joinOrgLoading || memberStatusLoading}
                onClick={() => acceptInvitationHandler()}
                isFullWidth
              />
            </div>
          </div>
        </div>
      )}

      {!isItJustWorksFlagged && inviteLoading && verifyLoading && <Loader size="lg" />}
      {isItJustWorksFlagged &&
        (magicLink ? isLoadingOrgData || isLoadingMagicLinkData : inviteLoading) && (
          <Loader size="lg" />
        )}
    </>
  );
};
