import { type ReactElement } from 'react';

import { type User } from '@f4s/types/common/user';

import { DEFAULT_AVATAR } from 'Constants';
import { head } from 'Underscore';

import { Profile } from './profile';

type RequiredOrgData =
  // TODO: Fix organization with 2 possible shapes
  | { id: number; logo?: string | null; name: string }
  | { organizationId: number; avatarUrl: string; name: string };

type ProfileUserData = Pick<User, 'firstName'> &
  Partial<Pick<User, 'lastName'>> & {
    orgs?: RequiredOrgData[] | null;
    organizations?: RequiredOrgData[] | null;
  } & Partial<{ id: number; userId: number }> & {
    avatarUrl?: string | null;
    profileImageUrl?: string | null;
  };

export type UserProfileProps = {
  user: ProfileUserData;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  badge?: boolean;
  organization?: RequiredOrgData;
};

export const UserProfile = ({
  user,
  size,
  badge,
  organization,
}: UserProfileProps): ReactElement => {
  // TODO: Remove variations in prop naming from User type
  // why is there avatarUrl and profileImageUrl?
  // why is there orgs, organizations, and organization?
  // why is there id and userId?
  const { firstName, lastName, avatarUrl, profileImageUrl } = user;

  // eslint-disable-next-line unicorn/consistent-destructuring
  const userId = user.userId || user.id;

  // eslint-disable-next-line unicorn/consistent-destructuring
  let orgs = 'orgs' in user ? user.orgs : user.organizations;

  if (!orgs && organization && 'avatarUrl' in organization) {
    orgs = [
      {
        id: organization.organizationId,
        logo: organization.avatarUrl,
        name: organization.name,
      },
    ];
  }

  if (!orgs && organization && 'logo' in organization) {
    orgs = [organization];
  }

  const userImgUrl = avatarUrl || profileImageUrl;
  const notF4SUserYet = !userId;

  const orgBadges = badge
    ? orgs?.map((org) => ({
        icon: ('avatarUrl' in org ? org.avatarUrl : org.logo) || '',
        name: org.name,
        id: 'id' in org ? org.id : org.organizationId,
      }))
    : null;

  const altText = `Profile of ${firstName} ${lastName || ''}`.trim();
  const profileCommonProps = {
    size,
    altText,
    orgBadges,
  };

  // image takes priority for profile
  if (userImgUrl && userImgUrl !== DEFAULT_AVATAR.USER) {
    // TODO - Use another placeholder image service
    const imageUrl = userImgUrl.startsWith('https://xsgames.co/')
      ? `${userImgUrl}&id=${userId}` // Add unique id to prevent duplicate images
      : userImgUrl;

    return <Profile imageUrl={imageUrl} {...profileCommonProps} id={userId} />;
  }

  // otherwise we use text
  const initials =
    size === 'xxs'
      ? head(firstName) || head(lastName)
      : `${head(firstName) || ''}${head(lastName) || ''}`;

  if (notF4SUserYet || !initials) {
    return <Profile imageUrl={DEFAULT_AVATAR.USER} {...profileCommonProps} />;
  }

  return <Profile size={size} text={initials} id={userId} orgBadges={orgBadges} />;
};
