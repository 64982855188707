import clsx from 'clsx';
import { useEffect, useState, type KeyboardEventHandler } from 'react';

import { Popover } from '@/components/common/popover/popover';

import { processTemplateString } from '../utils';
import { getOptions } from './utils';

type SearchableDropdownProps = {
  store: string;
  data: { [key: string]: any };
  onChange: (...args: any) => void;
  className: string;
  placeholder: string;
  autoFocus: boolean;
  defaultOption: string;
  onOptionSelect: (option: string) => void;
  optionsVariable?: string;
};

function getLoopedIndex(index: number, totalItems: number, maxItems: number) {
  const limit = Math.min(maxItems, totalItems);
  return Math.abs((index + 100 * limit) % limit);
}

/**
 * @deprecated As much as possible, we want to reduce the use of these flow-form components, and if we want to use something like this in the future, it's probably
 * easier to just plan and build a new set of components. We also abuse the type any quite a bit here, which makes things perilous to continue interating upon
 */
export function SearchableDropdown({
  store,
  data,
  onChange,
  className = '',
  autoFocus = false,
  onOptionSelect,
  ...props
}: SearchableDropdownProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<string[]>([]);
  const [focusOffset, setFocusOffset] = useState<number>(0);

  const rawOptions = getOptions(props, data);
  const options: string[] = Array.isArray(rawOptions)
    ? // eslint-disable-next-line unicorn/no-useless-spread
      [...new Set(rawOptions.map((option) => Object.values(option)[0]) as string[])]
    : [];

  useEffect(() => {
    if (query.length > 3) {
      setResults(options);
      if (results.length === 0) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    } else setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.length, results.length]);

  const placeholder = props.placeholder
    ? processTemplateString(props.placeholder, data)
    : null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    onChange({ ...data, [store]: e.target.value });
  };

  const MAX_RESULTS = 8;
  const focusIndex = getLoopedIndex(focusOffset, results.length, MAX_RESULTS);

  const handleOptionSelect = (option: string) => {
    setIsOpen(false);
    setQuery('');
    data[store] = option;
    onOptionSelect?.(option);
    setFocusOffset(0);
  };

  const handleInputSubmit = () => {
    if (results.length > 0 && !Number.isNaN(focusIndex))
      handleOptionSelect(results[focusIndex]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusOffset((value) => value + 1);
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusOffset((value) => value - 1);
    }

    if (event.key === 'Tab') {
      event.preventDefault();
      if (event.shiftKey) setFocusOffset((value) => value - 1);
      else setFocusOffset((value) => value + 1);
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      handleInputSubmit();
    }
  };

  return (
    <div className="input-sizer" data-value={data[store] || placeholder}>
      <input
        className={`input text ${className}`}
        type="text"
        onKeyDown={(event) => handleKeyDown(event)}
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={data[store] || ''}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <Popover
        open={isOpen}
        autoFocus={false}
        fullWidth
        offset={-15}
        align="start"
        onFocusOutside={() => {
          setIsOpen(false);
        }}
        trigger=""
      >
        {isOpen && (
          <SearchResultDropdown
            results={results}
            onOptionSelect={handleOptionSelect}
            focusIndex={focusIndex}
            setFocusIndex={setFocusOffset}
          />
        )}
      </Popover>
    </div>
  );
}
SearchableDropdown.requiresNext = true;

function SearchResultDropdown({
  results,
  onOptionSelect,
  focusIndex,
  setFocusIndex,
}: {
  results: string[];
  onOptionSelect: (option: string) => void;
  focusIndex: number;
  setFocusIndex: (index: number) => void;
}) {
  const renderResult = (result: string) => {
    return <span>{result}</span>;
  };

  return (
    <div className="absolute z-[101] mt-1 w-auto min-w-[200px] rounded-lg border bg-white p-1 shadow-lg">
      {results.map((result, index) => (
        <button
          key={result}
          type="button"
          onClick={() => onOptionSelect(result)}
          onMouseEnter={() => setFocusIndex(index)}
          className={clsx(
            'mt-2 flex w-full !min-w-[80px] items-center rounded p-2 text-left outline-none',
            { 'bg-gray-100': focusIndex === index },
          )}
        >
          {renderResult(result)}
        </button>
      ))}
    </div>
  );
}
