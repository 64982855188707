import clsx from 'clsx';
import { type PropsWithChildren, type ReactElement } from 'react';

import { type FlexProps } from '../layout.props';

import styles from '../layout.module.scss';

export const Row = ({
  wrap,
  gap,
  justify,
  align,
  height,
  width,
  compact,
  children,
  padding,
  mobilePadding,
  equalWidths = false,
  columnDirectionOnMobile = false,
}: PropsWithChildren<FlexProps> & { equalWidths?: boolean }): ReactElement => {
  const justifyContent = justify ? styles[`${justify}Justify`] : '';
  const alignContent = align ? styles[`${align}Align`] : '';
  const wrapContent = wrap ? styles.wrap : '';
  const gapContent = gap ? styles[`${gap}Gap`] : '';
  const paddingContent = padding ? styles[`${padding}Padding`] : '';
  const mobilePaddingContent = mobilePadding
    ? styles[`${mobilePadding}PaddingMobile`]
    : '';

  const inline: React.CSSProperties = {};
  if (width === 'auto') inline.width = 'auto';
  else if (width === 'full') inline.width = 'full';
  else if (width) inline.width = `${width}px`;
  if (height) inline.height = height === 'full' ? 'full' : `${height}px`;

  return (
    <div
      className={clsx(
        styles.rows,
        alignContent,
        gapContent,
        justifyContent,
        wrapContent,
        paddingContent,
        mobilePaddingContent,
        compact && styles.compactRow,
        equalWidths && styles.equalWidths,
        columnDirectionOnMobile && styles.columnDirectionOnMobile,
      )}
      style={inline}
    >
      {children}
    </div>
  );
};
