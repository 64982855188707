import clsx from 'clsx';

import { sendEvent } from '@/apis/analytics';
import { Button } from '@/components/common/button/button';
import { Typography } from '@/components/common/typography/typography';

import { type AddMoreGoalsStepType } from '../types';
import { Messages } from './add-more-goal-step.messages';

import styles from '../style-signup.module.scss';

export const AddMoreGoalsStep = ({
  nextStep,
  addMoreGoals,
}: AddMoreGoalsStepType): React.ReactElement => (
  <>
    <div className={styles.signUpStepsHeadingBox}>
      <h2 className={styles.signUpStepsHeading}>{Messages.addMoreGoalTitle()}</h2>
      <Typography color="gray-500">{Messages.addMoreGoalDescription()}</Typography>
    </div>
    <div className={clsx(styles.signupBtnWrapper, styles.skipBtnWrapper, styles.mb16Px)}>
      <Button
        variant="white"
        text={Messages.skipButton()}
        size="lg"
        onClick={() => {
          sendEvent('signUp_completed', {});
          nextStep();
        }}
        isFullWidth
      />
    </div>
    <div className={styles.signupBtnWrapper}>
      <Button
        variant="primary"
        text={Messages.addMGoalButton()}
        size="lg"
        onClick={() => addMoreGoals()}
        isFullWidth
      />
    </div>
  </>
);
