import zoomSdk, { type VirtualForegroundOptions } from '@zoom/appssdk';

export const isZoomClient =
  navigator.userAgent.includes('ZoomApps') || navigator.userAgent.includes('ZoomWebKit');

export const configureApp = async () => {
  if (!isZoomClient) return null;

  return zoomSdk.config({
    capabilities: [
      'shareApp',
      'showAppInvitationDialog',
      'getRunningContext',
      'getMeetingUUID',
      'setVirtualForeground',
      'removeVirtualForeground',
      'onMyMediaChange',
    ],
  });
};

export const checkForMeeting = async () => {
  try {
    const { context } = await zoomSdk.getRunningContext();
    return {
      inCollaborate: context === 'inCollaborate',
      inMeeting: context === 'inMeeting',
    };
  } catch {
    return {
      inCollaborate: false,
      inMeeting: false,
    };
  }
};

export const openUrl = async (url: string) => {
  await zoomSdk.openUrl({ url });
};

export const getMeetingId = async () => {
  const { meetingUUID } = await zoomSdk.getMeetingUUID();
  return meetingUUID;
};

export const setVirtualForeground = async (options: VirtualForegroundOptions) => {
  await zoomSdk.setVirtualForeground(options);
};
export const removeVirtualForeground = async () => {
  await zoomSdk.removeVirtualForeground();
};

export const enableAppInvitationToAllParticipants = async () => {
  // When user clicks "Don't Allow" then it will throw an error
  try {
    await zoomSdk.sendAppInvitationToAllParticipants();
    return true;
  } catch {
    return false;
  }
};
