import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUpdateProfileMutation } from '@/api-queries/users';
import { sendEvent } from '@/apis/analytics';
import { Column } from '@/components/common/layouts/column/column';
import { Header } from '@/components/invitation-modal/common/header';
import { CreateWorkspaceCommon } from '@/components/invitation-modal/create-workspace-common';
import { useQuery } from '@/lib/hooks/useQuery';

export const CreateWorkspaceStep = () => {
  const history = useHistory();
  const { mutate: setUserProperties } = useUpdateProfileMutation();
  const { redir } = useQuery<{ redir: string }>();
  const [usersAdded, setUsersAdded] = useState(0);
  const [usersRemoved, setUsersRemoved] = useState(0);

  return (
    <div>
      <Column gap="sm">
        <div className="mb-2">
          <Header title="Create workspace" />
        </div>
        <CreateWorkspaceCommon
          hideClose
          onMemberAdded={() => setUsersAdded(usersAdded + 1)}
          onMemberRemoved={() => setUsersRemoved(usersRemoved + 1)}
          onSuccess={(organization) => {
            setUserProperties({
              properties: {
                defaultOrg: organization.id,
                showActivationOrg: organization.id,
              },
            });
            sendEvent('signUp_added', {
              signUpAddMember: usersAdded,
              signUpRemoveMember: usersRemoved,
            });
            sendEvent('signUp_completed', {});

            history.push(redir ?? '/teams');
          }}
        />
      </Column>
    </div>
  );
};
