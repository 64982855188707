import type { Organization, User } from '@f4s/types';

import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';
import {
  DEFAULT_CLIENT_DOMAIN,
  INVITE_LOCAL_STORAGE_KEY,
  ORG_LOCAL_STORAGE_KEY,
  SESSION_STORAGE_REFERRAL_CODE,
} from 'Constants';
import { errorHandler } from 'Utils/error-handler';
import { BrowserStorage } from 'Utils/local-storage';

import { getLocalOrgData, presetOnboardingEmail, setLocalInvitationData } from '../utils';

const storage = BrowserStorage();
const sessionStorage = BrowserStorage('session');

function clearStorageAfter() {
  storage.setItem(ORG_LOCAL_STORAGE_KEY, '{}');
  sessionStorage.setItem(INVITE_LOCAL_STORAGE_KEY, '{}');
  sessionStorage.setItem(SESSION_STORAGE_REFERRAL_CODE, '');
}

function updateLocalOrgData(data) {
  const updates = {
    ...getLocalOrgData(),
    orgName: data.name,
    orgId: data.id,
    clientIDP: data.idp,
  };
  storage.setItem(ORG_LOCAL_STORAGE_KEY, JSON.stringify(updates));
}

async function verifyOrgInvitationByToken(token) {
  const { data } = await API.organizations.getInvitation(token);

  presetOnboardingEmail(data.emailAddress);
  setLocalInvitationData({
    type: 'organizations',
    token: 'orgInviteToken',
    inviter: { emailAddress: data.emailAddress as string },
  });
  return data.organization;
}

async function verifyOrgByToken(token: string) {
  const { data: org } = await API.organizations.verifyToken(token);
  return org;
}

async function verifyClientByDomain(domain: string) {
  const { data: client } = await API.clients.get(domain);
  return client;
}

async function verifyOrgOrClient(): Promise<Organization | User> {
  const { type, clientDomain, orgToken, orgInviteToken, orgJoinFrom } = getLocalOrgData();

  let orgOrClientData: Organization | User | undefined;

  try {
    if (type === 'client') {
      orgOrClientData = await verifyClientByDomain(clientDomain || DEFAULT_CLIENT_DOMAIN);
    } else if (type === 'org') {
      orgOrClientData =
        orgJoinFrom === 'invite'
          ? await verifyOrgInvitationByToken(orgInviteToken)
          : await verifyOrgByToken(orgToken);
    }
    updateLocalOrgData(orgOrClientData);
    if (!orgOrClientData) {
      throw new Error('Could not verify org or client');
    }
    return orgOrClientData;
  } catch (error) {
    errorHandler(error, Notification);
    clearStorageAfter();
    throw error;
  }
}

async function joinOrganization({ user }) {
  const { organizations } = user;
  const { type, orgId, orgInviteToken } = getLocalOrgData();
  const { currentMemberStatus } = organizations.find((org) => org.id === orgId) || {};

  if (currentMemberStatus === 'approved') {
    clearStorageAfter();
    return false;
  }

  if (type !== 'org' || !orgId) return true;

  try {
    await API.organizations.join(orgId, { token: orgInviteToken });
  } catch (error) {
    errorHandler(error, Notification);
    clearStorageAfter();
    return false;
  }

  return true;
}

const handleOrgStatus = (status, orgId, orgJoinFrom, orgInviteToken) => {
  if (orgJoinFrom !== 'invite')
    return API.organizations.memberStatus(orgId, {
      status,
      from: orgJoinFrom,
    });

  if (status === 'declined') {
    return API.organizations.declineInvite(orgInviteToken);
  }

  return API.organizations.acceptInvite(orgInviteToken);
};

async function updateJoinStatus({ status }) {
  const { type, orgId, orgInviteToken, orgJoinFrom, referralCode } = getLocalOrgData();

  let updatedUser = {};

  // approve/decline partner permission
  if (type === 'partner') {
    const { data } = await API.user.updatePermissions({
      referralCode,
      status,
      action: status === 'approved' ? 'gave' : 'declined',
    });
    updatedUser = data.user;
  }

  // join for old client
  if (type === 'client') {
    const { data } = await API.clients.updateStatus({
      clientId: orgId,
      attrs: { status },
    });
    updatedUser = data;
  }

  // join for org invitation
  if (type === 'org') {
    updatedUser = await handleOrgStatus(status, orgId, orgJoinFrom, orgInviteToken);
  }

  clearStorageAfter();
  return updatedUser;
}

function getPermissions() {
  const { type, orgName } = JSON.parse(storage.getItem(ORG_LOCAL_STORAGE_KEY) ?? '{}');
  return API.utils.getPermissions(type, orgName);
}

export {
  clearStorageAfter,
  verifyOrgOrClient,
  getPermissions,
  joinOrganization,
  updateJoinStatus,
};
