import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  SUBSCRIPTIONS_PAYMENT_CYCLE,
  type EstimateRequest,
  type SubscriptionCreationDocument,
} from '@f4s/types';

import { Notification } from 'Components/view/snackbar';
import { errorHandler } from 'Utils/error-handler';

import * as API from '../apis';

/**
 * Fetches the history of invoices and current estimate for next one
 * @param type org or undefined
 * @param id organization id
 */
export function useBillingInvoices(type: string | undefined, id?: number) {
  return useQuery(['billing.invoice', id], () =>
    API.subscriptions.invoices(id, type).then((response) => response.data),
  );
}

/**
 * Fetches a user or orgs payment card details
 * @param type 'org' or 'user'
 * @param id if the org selected
 */
export function useBillingPaymentCard(type: 'user' | 'org', id?: number) {
  return useQuery(['billing.paymentCard', id], () =>
    API.subscriptions.fetchPayment(type, id).then((response) => response.data),
  );
}

/**
 * Grabs the active subscription plan (replaces the old user.plan attribute)
 * @param options.type 'org' or undefined
 * @param options.id organization id
 * @param enabled to disable or enable the query
 * @returns
 */
export function useSubscriptionPlan(
  options?: { type?: string; id?: number },
  enabled: boolean = true,
) {
  return useQuery(
    ['subscriptions.getSubscriptionStatus', options?.id],
    () =>
      API.subscriptions
        .getSubscriptionStatus(options?.type, options?.id)
        .then((response) => response.data),
    { enabled },
  );
}

type PaymentUpdate = { cardToken: string; id?: number };

/**
 * Updates the card details, on success it fetches the new payment details.
 */
export function useMutateUpdatePaymentCard() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: PaymentUpdate) =>
      API.subscriptions.updateCard({
        cardToken: data.cardToken,
        organizationId: data.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['billing.paymentCard']);
        Notification.create('Payment updated successfully', 'success');
      },
      onError: (error) => {
        errorHandler(error, Notification);
      },
    },
  );
}

// TODO: See if this can be renamed to be more generic
/**
 * Clears the cache of payment details, invoices and subscription plan when a user cancels their pro account
 */
export function useInvalidateProBilling() {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['billing.paymentCard']);
    queryClient.invalidateQueries(['billing.invoice']);
    queryClient.invalidateQueries(['subscriptions.getSubscriptionStatus']);
    queryClient.invalidateQueries(['subscriptions.getEntitlement']);
  };
}

/** Fetches available plans and their prices */
export function useAvailablePlans() {
  return useQuery(['subscriptions.fetchAvailablePlans'], () =>
    API.subscriptions.fetchAvailablePlans().then((response) => response.data),
  );
}

/** Creates a new subscription using a chargebee Card Token for payment */
export function useSubscriptionCreate() {
  return useMutation(
    (data: SubscriptionCreationDocument) =>
      API.subscriptions.create(data).then((response) => response.data),
    {
      onError: () =>
        Notification.create(
          'Error creating subscription. You have not been charged',
          'error',
        ),
    },
  );
}

const fetchEstimate = (estimateRequest: EstimateRequest) =>
  API.subscriptions.fetchEstimate(estimateRequest).then((response) => response.data);

/** Fetches estimated price for a chosen subscription plan */
export function useEstimate(estimateRequest: EstimateRequest) {
  return useQuery(
    ['subscriptions.fetchEstimate', estimateRequest],
    () => fetchEstimate(estimateRequest),
    { retry: false }, // Don't want to retry bad coupons 3 times before failing
  );
}

/** Prefetches estimated price for a chosen subscription plan */
export function usePrefetchEstimate(
  estimateRequest: Omit<Omit<EstimateRequest, 'paymentCycle'>, 'coupons'>,
) {
  const queryClient = useQueryClient();
  const annualEstimateRequest = {
    ...estimateRequest,
    paymentCycle: SUBSCRIPTIONS_PAYMENT_CYCLE.annual,
  };
  const monthlyEstimateRequest = {
    ...estimateRequest,
    paymentCycle: SUBSCRIPTIONS_PAYMENT_CYCLE.monthly,
  };
  return async () =>
    Promise.all([
      queryClient.prefetchQuery(
        ['subscriptions.fetchEstimate', annualEstimateRequest],
        () => fetchEstimate(annualEstimateRequest),
      ),
      queryClient.prefetchQuery(
        ['subscriptions.fetchEstimate', monthlyEstimateRequest],
        () => fetchEstimate(monthlyEstimateRequest),
      ),
    ]);
}

export function useCancelProSubscription() {
  return useMutation(() =>
    API.subscriptions.cancelProSubscription().then((response) => response.data),
  );
}

export const useReassessmentInfo = () =>
  useQuery(['questionnaires.reassessmentInfo'], () =>
    API.subscriptions.getReassessmentInfo().then((response) => response.data),
  );
