import { z, type Schema } from 'zod';

import { MotivationSlug } from '../common/motivations';

type ObjectName = 'report';

const reportTypeSchema = z.enum(['culture', 'affinities', 'differences', 'xfactors']);

export type reportType = z.infer<typeof reportTypeSchema>;

const reportBaseSchema = z.object({
  reportType: z.enum(['culture', 'affinities', 'differences', 'xfactors']),
});

const reportDetailedSchema = reportBaseSchema.extend({
  detailedReport: z.string().optional(),
  modelView: z.enum(['benchmarking', 'ranking']).optional(),
  modelName: z.string().optional(),
  groupSize: z.number(),
});

const reportMotivationSchema = reportDetailedSchema.extend({
  motivation: MotivationSlug.or(z.string()).optional(),
  motivationScore: z.number().optional(),
});

const reportAddedSchema = reportMotivationSchema.extend({
  coachingRecommendations: z.string(),
});

const newReportSchema = z.object({
  status: z.enum(['ready', 'pending', 'processing', 'done']),
  fromTemplate: z.boolean().optional(),
  pendingReason: z.enum(['motivation', 'invite', 'credit', 'moderation']).nullish(),
  sectionCount: z.number().optional(),
});

const reportSchemas = {
  report_clicked: reportBaseSchema,
  report_viewed: reportDetailedSchema,
  report_explored: reportMotivationSchema,
  report_added: reportAddedSchema,
  // New Reports
  report_created: newReportSchema,
  report_updated: newReportSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { reportSchemas };
