import queryString from 'query-string';

import type {
  ConvincerInputResultsData,
  InsightShareData,
} from '@f4s/types/common/insights';

import { httpAgent as http } from './agents/http';

export type InsightOpts = {
  organizationId?: number[] | number;
  groupId?: number[] | number;
  userId?: number[] | number;
  questionnaireId?: number[] | number;
};

export const getShare = (insightId: string, shareData: InsightShareData) =>
  http().post(`/api/v2/insights/${insightId}/share`, shareData);

export const updateShare = (
  insightId: string,
  data: { shareCode: string; enabled: boolean },
) => http().put(`/api/v2/insights/${insightId}/share`, data);

export const getPublicShare = (insightId: string, shareCode: string) =>
  http().get(`/api/v2/insights/${insightId}/share/${shareCode}`);

export const getConvincerInputs = (userIds: number[]) =>
  http().get<ConvincerInputResultsData>(
    `/api/v2/insights/convincer-inputs?${queryString.stringify({ userIds })}`,
  );
