import ReactMarkdown from 'react-markdown';

import { EmojiPlugin } from 'Lib/emoji';

import { processTemplateString } from '../utils';

type TextOutputProps = {
  children: string[] | string;
  data?: Record<string, any>;
  extraProps?: any;
  className?: string;
};
export function TextOutput(props: TextOutputProps) {
  const { children, data, className, ...extraProps } = props;
  let text = Array.isArray(children) ? children.join('') : children;
  text = processTemplateString(text, data);
  if (text === '') return null;
  return (
    <div className={`chat-text-output ${className || ''}`}>
      <ReactMarkdown
        components={{
          // Open links in a new tab
          a: ({ node: _node, children: linkContent, ...linkProps }) => (
            <a {...linkProps} target="_blank">
              {linkContent}
            </a>
          ),
        }}
        rehypePlugins={[EmojiPlugin]}
        {...extraProps}
      >
        {text}
      </ReactMarkdown>
    </div>
  );
}
