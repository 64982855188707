import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';

import { useSignUpMutation } from '@/api-queries/users';
import { SocialButtonsV2, socialLink } from '@/components/buttons/social-buttons-v2';
import { Button } from '@/components/common/button/button';
import { Input } from '@/components/form_elements';
import { Divider } from '@/components/view/divider';
import { Validations } from '@/lib/utils/validations';
import { getLocalReferralCode } from '@/pages/sign-up/utils';

import { type CreateAccountStepType } from '../types';

import styles from '../style-signup.module.scss';

export const InsightShareCreateAccount = ({
  signUpDetails,
  handleInputChange,
  ssoRedir,
  signupFlow,
  banner,
}: CreateAccountStepType): React.ReactElement => {
  const [blankFields, setBlankFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showHint, setShowHint] = useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const { email = '', firstName = '', lastName = '' } = signUpDetails;
  const referralCode = getLocalReferralCode();
  const history = useHistory();
  const params: any = useParams();
  const { mutateAsync: signUpCall, isLoading: loaderState } = useSignUpMutation();

  useEffect(() => {
    if (!showError) return;

    if (!email || !firstName || !lastName) {
      const blankFieldsNames: string[] = [];
      if (!firstName) blankFieldsNames.push('First name');

      if (!lastName) blankFieldsNames.push('Last name');

      if (!email) blankFieldsNames.push('Email');

      const blankFieldsString = blankFieldsNames.join(', ');
      setErrorMessage(
        `${blankFieldsString} ${blankFieldsNames.length > 1 ? 'are' : 'is'} required`,
      );
    } else if (email && !Validations.email(email)) {
      setErrorMessage('Please enter a valid email');
    } else setErrorMessage('');
  }, [email, firstName, lastName, showError]);

  const submitHandler = async (userEmail: string | undefined) => {
    if (!email || !firstName || !lastName) {
      setBlankFields({ firstName: !firstName, lastName: !lastName, email: !email });
      setShowError(true);
    } else if (userEmail && !Validations.email(userEmail)) {
      setBlankFields({ ...blankFields, email: true });
      setShowError(true);
    } else {
      setErrorMessage('');

      await signUpCall({
        emailAddress: email,
        firstName,
        lastName,
        signupFlow,
      });
      setTimeout(() => {
        history.push('/questions/welcome');
      }, 2000);
    }
  };

  const socialParams = {
    referralCode,
    redir: ssoRedir || params.redir,
    signupFlow,
  };

  return (
    <>
      <div className={styles.signUpStepsHeadingBox}>
        <h2 className={styles.signUpStepsHeading}>Sign up for free</h2>
      </div>
      {banner}
      <div className={styles.socialLinksContainer}>
        <div className={styles.socialButtonsV3}>
          <SocialButtonsV2
            links={{
              google: socialLink('google', socialParams),
              facebook: socialLink('facebook', socialParams),
              linkedin: socialLink('linkedin', socialParams),
            }}
          />
        </div>
      </div>
      <div className={styles.signupDivider}>
        <Divider text="Or" />
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={clsx(styles.aboutSection)}>
          <div className={clsx(styles.aboutSectionInputWrapper)}>
            <Input
              placeholder="First name"
              type="text"
              defaultValue={firstName}
              onchange={(value: string) => {
                setBlankFields({ ...blankFields, firstName: !value });

                handleInputChange('firstName', value);
              }}
              autoFocused
              forceFocused
              required
              classes={clsx(styles.nameInput, {
                [styles.invalidInput]: blankFields.firstName,
              })}
            />
          </div>
          <div className={clsx(styles.aboutSectionInputWrapper)}>
            <Input
              placeholder="Last name"
              type="text"
              defaultValue={lastName}
              onchange={(value: string) => {
                setBlankFields({ ...blankFields, lastName: !value });

                handleInputChange('lastName', value);
              }}
              forceFocused
              required
              classes={clsx(styles.nameInput, {
                [styles.invalidInput]: blankFields.lastName,
              })}
            />
          </div>
        </div>
        <Input
          type="email"
          defaultValue={email}
          onchange={(value: string) => {
            setBlankFields({
              ...blankFields,
              email: !value || (showError && !Validations.email(value)),
            });

            handleInputChange('email', value);
          }}
          autoFocused={false}
          forceFocused
          required
          placeholder="Email"
          classes={clsx(styles.emailInput, {
            [styles.invalidInput]: blankFields.email,
          })}
          onfocus={() => setShowHint(true)}
        />
        {errorMessage && (
          <div className="mb-3">
            <span className={styles.errorText}>{errorMessage}</span>
          </div>
        )}
        {showHint && (
          <div className={styles.infoMessage}>
            <span className={styles.messageBox}>
              Tip: <strong>use your work email</strong> to make it easy for teammates to
              connect with you in F4S!
            </span>
          </div>
        )}
        <div className={styles.signupBtnWrapper}>
          <Button
            isSubmitBtn
            variant="primary"
            text="Get started"
            size="lg"
            isLoading={loaderState}
            onClick={() => submitHandler(email)}
            isFullWidth
          />
        </div>
      </form>

      <div className={styles.textForPrivacyTerms}>
        {AppText.sign_up}
        <Link
          className={styles.linkPolicy}
          target="_blank"
          to={{ pathname: 'https://www.fingerprintforsuccess.com/privacy-policy' }}
        >
          Privacy Policy
        </Link>
        and
        <Link
          className={styles.linkTerms}
          target="_blank"
          to={{ pathname: 'https://www.fingerprintforsuccess.com/terms-and-conditions' }}
        >
          Terms and Conditions
        </Link>
      </div>
    </>
  );
};
