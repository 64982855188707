import { z, type Schema } from 'zod';

type ObjectName = 'workspace';

const workspaceBaseSchema = z
  .object({
    workspaceId: z.number(),
    workspaceType: z.string(),
    workspaceDisplay: z.string(),
    workspaceSource: z.string(),
    workspaceSize: z.number(),
    workspaceAction: z.enum(['created', 'edited', 'explored']),
    workspaceImageUploaded: z.boolean(),
    workspaceSettings: z.string(),
  })
  .partial();

// These events aren't as clean as they could be, but the old website is already using them
// The schemas have been consolidated into a single partial shape
const workspaceSchemas = {
  workspace_created: workspaceBaseSchema,
  workspace_edited: workspaceBaseSchema,
  workspace_explored: workspaceBaseSchema,
  workspace_deleted: workspaceBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { workspaceSchemas };
