import clsx from 'clsx';
import { useState } from 'react';

import { JobAreas, JobTitlesByArea } from '@f4s/shared';

import { useUpdateProfileMutation } from '@/api-queries/users';
import { Button } from '@/components/common/button/button';
import { Paragraph } from '@/components/common/typography/paragraph/paragraph';
import { Input } from '@/components/form_elements';
import { Label } from '@/components/ui/label';
import countryList from '@/lib/countries.json';
import StatesCA from '@/lib/country-states-lists/CA.json';
import StatesUS from '@/lib/country-states-lists/US.json';

import { type OnboardingStepProps } from '../types';
import { Messages } from './about-you-step.messages';

import styles from '../style-signup.module.scss';

export const AboutYouStep = ({
  signUpDetails,
  handleInputChange,
  nextStep,
}: OnboardingStepProps): React.ReactElement => {
  const { firstName, lastName, countryCode, stateCode, jobArea, jobTitle } =
    signUpDetails;
  const [error, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    countryCode: '',
    stateCode: '',
    jobArea: '',
    jobTitle: '',
  });
  const { mutateAsync: updateProfileCall, isLoading: loaderState } =
    useUpdateProfileMutation();

  const countryOptions = countryList.map((item) => ({
    value: item.code,
    display: item.country,
  }));
  const stateOptions = getStateOptionsForCountry(countryCode);

  const submitHandler = async () => {
    let hasError = false;
    const errorMessages = {
      firstName: '',
      lastName: '',
      countryCode: '',
      stateCode: '',
      jobArea: '',
      jobTitle: '',
    };

    if (!firstName) {
      errorMessages.firstName = 'Required';
      hasError = true;
    }
    if (!lastName) {
      errorMessages.lastName = 'Required';
      hasError = true;
    }
    if (!countryCode) {
      errorMessages.countryCode = 'Required';
      hasError = true;
    }
    // State is not required if there's no options for state
    if (stateOptions && !stateCode) {
      errorMessages.stateCode = 'Required';
      hasError = true;
    }

    if (hasError) return setErrorMessage(errorMessages);
    await updateProfileCall({
      firstName,
      lastName,
      onboardingCompleted: true,
      countryCode,
      stateCode,
      properties: { jobArea, jobTitle },
    });
    nextStep();
  };

  return (
    <div>
      <div className={clsx(styles.signUpStepsHeadingBox)}>
        <h2 className={clsx(styles.signUpStepsHeading)}>{Messages.aboutYouTitle()}</h2>
      </div>
      <div className={clsx(styles.aboutSection1)}>
        <div className={clsx(styles.aboutSectionInputWrapper)}>
          <Input
            separateLabel
            label={Messages.firstNameLabel()}
            type="text"
            defaultValue={firstName}
            onchange={(value: string) => {
              if (value !== '') {
                setErrorMessage({ ...error, firstName: '' });
              } else {
                setErrorMessage({ ...error, firstName: 'Required' });
              }
              handleInputChange?.('firstName', value);
            }}
            autoFocused
            forceFocused
            required
            classes={clsx(
              styles.step2Input,
              `${error.firstName && clsx(styles.invalidInput)}`,
            )}
          />

          {error.firstName && (
            <span className={clsx(styles.errorText)}>{error.firstName}</span>
          )}
        </div>
        <div className={clsx(styles.aboutSectionInputWrapper)}>
          <Input
            separateLabel
            label={Messages.lastNameLabel()}
            type="text"
            defaultValue={lastName}
            onchange={(value: string) => {
              if (value !== '') {
                setErrorMessage({ ...error, lastName: '' });
              } else {
                setErrorMessage({ ...error, lastName: 'Required' });
              }
              handleInputChange?.('lastName', value);
            }}
            forceFocused
            required
            classes={clsx(
              styles.step2Input,
              `${error.lastName && clsx(styles.invalidInput)}`,
            )}
          />
          {error.lastName && (
            <span className={clsx(styles.errorText)}>{error.lastName}</span>
          )}
        </div>
      </div>
      <div className={clsx(styles.aboutSectionInputWrapper, 'flex', 'flex-col')}>
        <Label>{Messages.countryLabel()}</Label>
        <Input
          type="select"
          data={countryOptions}
          defaultValue={
            countryCode &&
            countryOptions.find(({ value }) => value === countryCode)?.display
          }
          onchange={({ value }) => {
            if (countryCode === value) return;
            handleInputChange?.('countryCode', value);
            handleInputChange?.('state', '');
          }}
          required
        />
        {error.countryCode && (
          <span className={clsx(styles.errorText)}>{error.countryCode}</span>
        )}
        {/*
          <Combobox
           name="country"
           placeholder="Country"
           searchPlaceholder="Type to search"
           selections={countryList.map((item) => ({
             value: item.code,
             label: item.country,
           }))}
           onValueChange={(value) => handleInputChange('country', value)}
          /> */}
      </div>
      {stateOptions && (
        <div className={clsx(styles.aboutSectionInputWrapper, 'flex', 'flex-col')}>
          <Label>{Messages.stateOrProvinceLabel()}</Label>
          <Input
            type="select"
            data={stateOptions}
            defaultValue={
              stateCode && stateOptions.find(({ value }) => value === stateCode)?.display
            }
            onchange={({ value }) => handleInputChange?.('stateCode', value)}
            required
          />
          {error.stateCode && (
            <span className={clsx(styles.errorText)}>{error.stateCode}</span>
          )}
        </div>
      )}
      <div className={clsx(styles.aboutSectionInputWrapper, 'flex', 'flex-col')}>
        <div className={styles.label}>{Messages.occupationLabel()}</div>
        <Paragraph size="sm" color="gray-500">
          {Messages.occupationDescription()}
        </Paragraph>
        <Input
          defaultValue={jobArea}
          onchange={(opt: { value: string }) => {
            handleInputChange?.('jobArea', opt.value);
            handleInputChange?.('jobTitle', '');
          }}
          type="select"
          data={JobAreas.map((o) => ({ value: o, display: o }))}
          classes={clsx(styles.step2Input)}
        />
      </div>
      {jobArea && (
        <div className={clsx(styles.aboutSectionInputWrapper, 'flex', 'flex-col')}>
          <Input
            label={Messages.jobTitleLabel()}
            placeholder={Messages.jobTitlePlaceholder()}
            separateLabel
            defaultValue={jobTitle}
            onchange={(opt: { value: string }) => {
              handleInputChange?.('jobTitle', opt.value);
            }}
            type="select"
            data={JobTitlesByArea[jobArea].map((o) => ({
              value: o,
              display: o,
            }))}
            allowFreeText
            classes={clsx(styles.step2Input)}
          />
        </div>
      )}
      <div className={clsx(styles.signupBtnWrapper)}>
        <Button
          variant="primary"
          text={Messages.stepNextButton()}
          size="lg"
          isLoading={loaderState}
          onClick={submitHandler}
          isFullWidth
        />
      </div>
    </div>
  );
};

function getStateOptionsForCountry(countryCode) {
  if (countryCode === 'US') {
    return mapISOStatesToOptionData(StatesUS);
  }
  if (countryCode === 'CA') {
    return mapISOStatesToOptionData(StatesCA);
  }
  return undefined;
}
function mapISOStatesToOptionData(statesList: Array<{ code: string; name: string }>) {
  return statesList.map(({ name, code }) => ({ value: code, display: name }));
}
