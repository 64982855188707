import { useState } from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';
import { CobrandedRightContent } from '@/components/ui/cobranded-banner';
import { ConnectionsContextWrapper } from '@/contexts/connections';
import { AuthDashboardIndexV2 } from '@/layouts';
import { useHistorySteps } from '@/lib/hooks/useHistorySteps';
import { usePageView } from '@/lib/hooks/usePageView';
import { getStoredInvitationData } from '@/pages/sign-up/utils';

import { type SignUpDetailTypes } from '../types';
import { AboutYouStep } from './about-you-step';
import { AddMoreGoalsStep } from './add-more-goal-step';
import { AssessmentPromptStep } from './assessment-prompt-step';
import { CreateAccountStep } from './create-account-step';
import { CreateWorkspaceStep } from './create-workspace-step';
import { GoalStep } from './goal-step';
import { GoalTypeStep } from './goal-type-step';
import { WhyAreYouHereStep } from './why-are-you-here-step';

import styles from '../style-signup.module.scss';

const AllStepNames = [
  'create-account',
  'why-are-you-here',
  'about-you',
  'create-team',
  'goal-type',
  'goal',
  'extra-goal-type',
  'extra-goal',
  'add-more-goals',
  'assessment-prompt',
] as const;

type StepName = (typeof AllStepNames)[number];
type StepOptions = {
  displayStep: number | false;
  displayLogin?: boolean;
  displayCompleted?: boolean;
};
type StepMap = Partial<Record<StepName, StepOptions>>;

const personalSteps: StepMap = {
  'about-you': { displayStep: 1 },
  'goal-type': { displayStep: 2 },
  'goal': { displayStep: 3 }, // Next step redirects to add-more-goals
  'extra-goal-type': { displayStep: 4 },
  'extra-goal': { displayStep: 4 },
  'add-more-goals': { displayStep: 4 }, // Optional redirect to extra-goal-type
  'assessment-prompt': { displayStep: false, displayCompleted: true },
} as const;

const teamSteps: StepMap = {
  'about-you': { displayStep: 1 },
  'create-team': { displayStep: 2 },
} as const;

function getSteps(onboardingWhyAreYouHere?: string, isGroupInvite?: boolean): StepMap {
  let type = onboardingWhyAreYouHere;

  if (isGroupInvite) type = 'personal';
  return {
    'create-account': { displayStep: false, displayLogin: true },
    'why-are-you-here': { displayStep: false },
    ...(type === 'team' && teamSteps),
    ...(type === 'personal' && personalSteps),
  };
}

export function FormFlowRoute() {
  const { path } = useRouteMatch();
  return <Route path={`${path}/:step?`} component={FormFlow} />;
}

const useExistingUserData = (): SignUpDetailTypes => {
  const { user } = useMaybeUser();

  if (!user) {
    return {
      email: '',
      firstName: '',
      lastName: '',
      countryCode: '',
      stateCode: '',
      jobTitle: '',
      jobArea: '',
      goal: 0,
      goalType: [],
      onboardingCompleted: false,
    };
  }

  return {
    email: user.emailAddress,
    firstName: user.firstName,
    lastName: user.lastName,
    countryCode: user.countryCode,
    stateCode: user.stateCode,
    jobArea: user.properties?.jobArea || '',
    jobTitle: user.properties?.jobTitle || '',
    goal: 0,
    goalType: [],
    onboardingCompleted: user.onboardingCompleted || false,
  };
};

function FormFlow(): React.ReactElement {
  const [step, setStep] = useHistorySteps<StepName>('create-account', 'step', false);
  const userData = useExistingUserData();
  const [signUpDetails, setSignUpDetails] = useState<SignUpDetailTypes>(userData);
  const [selectedGoalType, setSelectedGoalType] = useState<any>({});

  /**
   * Note (Search 91c34bdb for related notes)
   * Decision was made for redir to override CreateAccountStep's default ssoRedir(/sign-up/get-started/why-are-you-here) if it exists
   * This is because whenever an unauthed user enters the site, they will be redirected to sign-up/sign-in with a redir param of their expected landing page
   * They should enter the expected landing page(redir) post-auth, whether they're existing users or not, instead of the onboarding flow
   * The priority goes to localStorage's redir as the user should end up where the site initially wanted to redirect them
   */
  const redir: string | undefined = localStorage.getItem('redir') || undefined;

  // if user has a groupInvite, we will send them down the personal path for now.
  const invite = getStoredInvitationData();
  const isGroupInvite = invite?.inviteType === 'groups';

  usePageView(`Sign up - ${step}`);
  const stepList: StepMap = getSteps(
    signUpDetails.onboardingWhyAreYouHere,
    isGroupInvite,
  );
  // Using `as` because Object.keys has a stupid type
  const stepNames = Object.keys(stepList) as Array<keyof typeof stepList>;

  const stepIdx = stepNames.indexOf(step);

  const nextStep = () => {
    const next = stepNames[stepIdx + 1];
    setStep(next);
  };

  const addMoreGoals = () => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      goal: 0,
      goalType: [],
    }));
    setStep('extra-goal-type');
  };

  const handleInputChange = (name: string, value: string | number) => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMultiSelectValue = (name: string, value: string) => {
    const newValue: any = signUpDetails[name as keyof typeof signUpDetails];
    if (!newValue.includes(value)) {
      newValue.push(value);
    } else {
      const index = newValue.indexOf(value);
      if (index > -1) {
        newValue.splice(index, 1);
      }
    }

    setSignUpDetails((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSelectedGoalType = (goals: any) => {
    setSelectedGoalType(goals);
  };

  const stepContainer = () => {
    switch (step) {
      case 'create-account': {
        if (userData.email && !userData.onboardingCompleted) {
          nextStep();
        }
        if (userData.onboardingCompleted) {
          return <Redirect to={redir || '/me'} />;
        }
        return (
          <CreateAccountStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
            signupFlow="2022-default"
            ssoRedir={redir || ''} // Moved to social-home
          />
        );
      }
      case 'why-are-you-here': {
        if (!isGroupInvite) {
          return (
            <WhyAreYouHereStep
              signUpDetails={signUpDetails}
              handleInputChange={handleInputChange}
              nextStep={nextStep}
            />
          );
        }
        nextStep();
        return <Redirect to="/sign-up/get-started/about-you" />;
      }
      case 'about-you': {
        return (
          <AboutYouStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
          />
        );
      }
      case 'create-team': {
        return (
          <ConnectionsContextWrapper>
            <CreateWorkspaceStep />
          </ConnectionsContextWrapper>
        );
      }
      case 'extra-goal-type':
      case 'goal-type': {
        return (
          <GoalTypeStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
            handleSelectedGoal={handleSelectedGoalType}
          />
        );
      }
      case 'extra-goal':
      case 'goal': {
        return (
          <GoalStep
            signUpDetails={signUpDetails}
            handleMultiSelectValue={handleMultiSelectValue}
            nextStep={() => {
              setStep('add-more-goals');
            }}
            selectedGoalType={selectedGoalType}
            isExtraGoal={step === 'extra-goal'}
          />
        );
      }
      case 'add-more-goals': {
        return (
          <AddMoreGoalsStep
            nextStep={nextStep}
            addMoreGoals={addMoreGoals}
            signUpDetails={signUpDetails}
          />
        );
      }
      case 'assessment-prompt': {
        return <AssessmentPromptStep />;
      }
    }
    return <Redirect to="/" />;
  };

  const {
    displayStep,
    displayCompleted = false,
    displayLogin = false,
  } = stepList[step] || {};
  const totalSteps = Math.max(...Object.values(stepList).map((s) => s.displayStep || 0));

  const rightContentContainer =
    // step === 'create-account' ? <SignUpTestimonialCard /> : null;
    step === 'create-account' ? <CobrandedRightContent /> : null;

  return (
    <AuthDashboardIndexV2
      displayStep={displayStep}
      totalSteps={totalSteps}
      displayCompleted={displayCompleted}
      displayLogin={displayLogin}
      rightContent={rightContentContainer}
    >
      <div className={styles.loginFormRoot}>{stepContainer()}</div>
    </AuthDashboardIndexV2>
  );
}
