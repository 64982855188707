import { httpAgent as http } from './agents/http';

export const resetRequest = (data: {
  emailAddress: string;
  pendingActions?: { type: string; data: any }[];
}) => http().post('/auth/v3/token/send', { emailAddress: data.emailAddress });

export const verifyResetToken = (data: { token: string }) =>
  http().post('/auth/v3/token', data);

export const updatePassword = (data: { token: string; password: string }) =>
  http().patch('/api/v4/users/me', { password: data.password });

export const getToken = () =>
  http()
    .get<{ token: string }>('/auth/v3/token')
    .then((res) => res.data);
