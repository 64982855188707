import { type AxiosResponse } from 'axios';

import {
  type Estimate,
  type EstimateRequest,
  type Organization,
  type User,
} from '@f4s/types';
import type {
  BillingInvoices,
  PlanData,
  SubscriptionCreationDocument,
  SubscriptionResponse,
} from '@f4s/types/common/payments';

import { httpAgent as http } from './agents/http';

const subscriptionsBase = '/api/v2/payments/subscriptions';

export const create = (data: SubscriptionCreationDocument) =>
  http().post<Organization | User>(`${subscriptionsBase}`, data);

export const cancelProSubscription = () => http().delete(`${subscriptionsBase}/pro`);

export const getSubscriptionStatus = (
  type?: string,
  id?: string | number,
): Promise<AxiosResponse<SubscriptionResponse>> => {
  const idParam = id ? `?id=${id}` : '';
  // type being undefined returns our pro subscription data
  return http().get(`${subscriptionsBase}/${type}${idParam}`);
};

export const updateCard = (data: { cardToken: string; organizationId?: number }) =>
  http().put(`${subscriptionsBase}/payment-card`, data);

export const invoices = (
  id?: string | number,
  type?: string,
): Promise<AxiosResponse<BillingInvoices>> => {
  const idParam = id ? `?id=${id}` : '';
  // type being undefined returns our pro subscription data
  return http().get(`${subscriptionsBase}/invoices/${type}${idParam}`);
};

export const fetchPayment = (type: string, id?: number) => {
  const idParam = id ? `?id=${id}` : '';
  return http().get(`${subscriptionsBase}/payment-card/${type}${idParam}`);
};

export const fetchAvailablePlans = () => http().get<PlanData[]>('/api/v2/payments/plans');

export const fetchEstimate = (estimateRequest: EstimateRequest) =>
  http().post<Estimate>(`/api/v2/payments/estimate`, estimateRequest);

export const getReassessmentInfo = () => http().get('/api/v2/payments/reassessment-info');

export const getEntitlement = (entitlementId: string, organizationId?: number) =>
  http().get<string | number | boolean | string[]>(
    `/api/v3/entitlements/${entitlementId}`,
    {
      params: {
        organizationId,
      },
    },
  );
