import { useFlag } from '@unleash/proxy-client-react';
import { useEffect, type PropsWithChildren } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FeatureFlags } from '@f4s/types';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useGetMagicLinkByToken } from '@/api-queries/magic-links';
import {
  useGetReducedOrganizationByMagicLinkToken,
  useVerifyToken,
} from '@/api-queries/organizations';
import { Loader } from '@/components/common/loader/loader';
import { RequestSubmitted } from '@/components/request-submitted';
import { FeatureFlagsContext, useAppContext } from '@/contexts';
import { AuthDashboardIndexV2 } from '@/layouts';
import { useHistorySteps } from '@/lib/hooks/useHistorySteps';
import { logError } from '@/lib/log-error';
import { redirectAsync } from '@/lib/underscore';
import { SigninForm } from '@/pages/sign-in/sign-in-form';
import { AssessmentPrompt } from '@/pages/sign-up/sign-up-by-invites/form-flow/assessment-prompt';
import { JoinOrg } from '@/pages/sign-up/sign-up-by-invites/form-flow/join-org-step';
import { OrgInviteBanner } from '@/pages/sign-up/sign-up-v2/components/org-invite-banner';
import styles from '@/pages/sign-up/sign-up-v2/style-signup.module.scss';

const StepNames = [
  'login',
  'join-org',
  'request-submitted',
  'assessment-prompt',
] as const;

type StepParams = {
  displayStep: number | false;
  displayLogin?: boolean;
  displayCompleted?: boolean;
};

const STEPS: Record<(typeof StepNames)[number], StepParams> = {
  'login': { displayStep: false, displayLogin: true },
  'join-org': { displayStep: 2 },
  'request-submitted': { displayStep: 3 },
  'assessment-prompt': { displayStep: false, displayCompleted: true },
} as const;

type StepName = (typeof StepNames)[number];

// DEVELOP-474 fix login solution till we refactor
type MagicTemplateProps = {
  step: StepParams;
  totalSteps: number;
};

const MagicLinkTemplate = ({
  step,
  totalSteps,
  children,
}: PropsWithChildren<MagicTemplateProps>): JSX.Element => (
  <AuthDashboardIndexV2
    displayStep={step.displayStep}
    totalSteps={totalSteps}
    displayCompleted={step.displayCompleted}
    displayLogin={step.displayLogin}
  >
    <div className={styles.loginFormRoot}>{children}</div>
  </AuthDashboardIndexV2>
);

export function FormFlow(): React.ReactElement {
  const { user, isLoggedIn } = useMaybeUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const { featureFlags } = useAppContext(FeatureFlagsContext);

  const [step, setStep] = useHistorySteps<StepName>('login', 'step', false);
  const isItJustWorksFlagged = useFlag(FeatureFlags.itJustWorks);
  const stepIdx = StepNames.indexOf(step);
  const nextStep = (inc = 1) => {
    const next = StepNames[stepIdx + inc];
    setStep(next);
  };
  const params: any = useParams();
  const {
    refetch: verifyToken,
    isSuccess: tokenSuccess,
    isLoading: verifyLoading,
    data: tokenData,
  } = useVerifyToken({
    token: params.token,
    enabled: false,
  });
  const { isLoading: isMagicLinkDataLoading, data: magicLinkData } =
    useGetMagicLinkByToken(params.token, isItJustWorksFlagged);
  const { data: orgData } = useGetReducedOrganizationByMagicLinkToken(
    magicLinkData?.token,
  );

  const totalSteps = Math.max(...Object.values(STEPS).map((s) => s.displayStep || 0));

  const stepContainer = () => {
    switch (step) {
      case 'login': {
        return (
          <SigninForm
            onSuccess={nextStep}
            ssoRedir={pathname}
            signupFlow="2022-org-link"
            banner={
              <OrgInviteBanner
                org={isItJustWorksFlagged ? orgData : tokenData}
                token={params.token}
                from="sign-in"
              />
            }
          />
        );
      }
      case 'join-org': {
        return (
          <MagicLinkTemplate step={STEPS[step]} totalSteps={totalSteps}>
            <JoinOrg nextStep={nextStep} magicLink />
          </MagicLinkTemplate>
        );
      }
      case 'request-submitted': {
        return (
          <MagicLinkTemplate step={STEPS[step]} totalSteps={totalSteps}>
            <RequestSubmitted nextStep={nextStep} orgName={tokenData?.name} />
          </MagicLinkTemplate>
        );
      }
      case 'assessment-prompt': {
        return (
          <MagicLinkTemplate step={STEPS[step]} totalSteps={totalSteps}>
            <AssessmentPrompt />
          </MagicLinkTemplate>
        );
      }
      default: {
        return <div className="App" />;
      }
    }
  };

  useEffect(() => {
    if (!isItJustWorksFlagged) {
      const getVerification = async () => {
        const { error, isError, data }: { error: any; isError: boolean; data: any } =
          await verifyToken(params.token);
        if (isError && error?.response?.status === 404) {
          logError(error, { tag: 'org-magic-link', token: params.token });
          await redirectAsync(`/404`);
        } else if (user?.organizations?.find((org) => org.id === data?.id)) {
          // if login user member of organization then don't show join-org screen
          history.push('/');
        }
      };

      getVerification();
    }
  }, [verifyToken, params.token, isLoggedIn, history, magicLinkData]);

  useEffect(() => {
    if (isLoggedIn && step === 'login') {
      setStep('join-org');
    }
  }, [isLoggedIn, step, setStep]);

  return (
    <>
      {featureFlags[FeatureFlags.itJustWorks] &&
        !isMagicLinkDataLoading &&
        magicLinkData &&
        stepContainer()}

      {!featureFlags[FeatureFlags.itJustWorks] && tokenSuccess && stepContainer()}
      {(!featureFlags[FeatureFlags.itJustWorks] && verifyLoading) ||
        (featureFlags[FeatureFlags.itJustWorks] && isMagicLinkDataLoading && (
          <Loader size="lg" />
        ))}
    </>
  );
}
