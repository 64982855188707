import { ChevronDownIcon } from '@heroicons/react/solid';

import { ButtonCVA } from '@/components/common/button/button-cva';
import { Popover } from '@/components/common/popover/popover';

import { type CreatableTeamMember, type MemberRole } from '../../types';
import { handleMemberChange } from '../../utils';
import { RoleTypeToggle } from './role-type-toggle';

const RoleSelect = ({
  member,
  onRoleChange,
  isDisabled = false,
}: {
  member: { role: MemberRole };
  onRoleChange: (role: 'admin' | 'viewer' | 'member', enabled: boolean) => void;
  isDisabled?: boolean;
}) => (
  <Popover
    align="end"
    disabled={isDisabled}
    trigger={
      <ButtonCVA
        color="black"
        variant="subtle"
        text={getRoleString(member.role)}
        size="xs"
        isSolidIcon
        trailingIcon={<ChevronDownIcon />}
        disabled={isDisabled}
      />
    }
  >
    <div className="flex w-[280px] flex-col gap-2.5 rounded border bg-white p-2 shadow-lg">
      <div className="flex flex-col gap-2">
        <RoleTypeToggle
          label="Admin"
          isChecked={member.role.isAdmin}
          onChange={() => {}}
          description="Has admin permissions and can view results of the team"
          isDisabled
        />
        <RoleTypeToggle
          label="Member"
          isChecked={member.role.isMember}
          onChange={(checked) => onRoleChange('member', checked)}
          description="Results included in team"
        />
        <RoleTypeToggle
          label="Viewer"
          isChecked={member.role.isViewer}
          onChange={(checked) => onRoleChange('viewer', checked)}
          description="Can view the results of the team"
          isDisabled={member.role.isAdmin}
        />
      </div>
    </div>
  </Popover>
);

export const MemberRoleControls = ({
  members,
  member,
  updateMembers,
}: {
  members: CreatableTeamMember[];
  member: CreatableTeamMember;
  updateMembers: (members: CreatableTeamMember[]) => void;
}) => {
  const handleRoleChange = (role: 'admin' | 'viewer' | 'member', enabled: boolean) => {
    const updatedMember = { ...member };

    // Disable updating admin role
    if (role === 'admin') return;

    const isNotOnlyViewer =
      !member.role.isViewer || member.role.isMember || member.role.isAdmin;

    if (role === 'viewer' && isNotOnlyViewer) {
      updatedMember.role = {
        ...updatedMember.role,
        isViewer: enabled,
      };
    }

    const isNotOnlyMember =
      member.role.isViewer || !member.role.isMember || member.role.isAdmin;

    if (role === 'member' && isNotOnlyMember) {
      updatedMember.role = {
        ...updatedMember.role,
        isMember: enabled,
      };
    }

    handleMemberChange(updateMembers, members, updatedMember);
  };
  if (member.type === 'meOverTime') return null;
  return <RoleSelect member={member} onRoleChange={handleRoleChange} />;
};

function getRoleString(role: MemberRole): string {
  if (role.isAdmin && role.isMember) {
    return 'Admin, Member';
  }

  if (role.isAdmin) {
    return 'Admin';
  }

  if (role.isMember && role.isViewer) {
    return 'Member, Viewer';
  }

  if (role.isViewer) return 'Viewer';

  return 'Member';
}
