import { z } from 'zod';

import { siteSchema } from '../common/sites';
import { askMarleeSchemas } from './ask-marlee';
import { assessmentSchemas } from './assessment';
import { brainSchemas } from './brain';
import { coachingSchemas } from './coaching';
import { communicationSchemas } from './communication';
import { comparisonSchemas } from './comparison';
import { creditSchemas } from './credits';
import { dashboardSchemas } from './dashboard';
import { debriefSchemas } from './debrief';
import { experimentSchemas } from './experiment';
import { goalSchemas } from './goal';
import { insightSchemas } from './insight';
import { inviteSchemas } from './invite';
import { journalSchemas } from './journal';
import { modelSchemas } from './model';
import { motivationSchemas } from './motivation';
import { motivationsSchemas } from './motivations';
import { paymentSchemas } from './payment';
import { paymentsSchemas } from './payments';
import { reportSchemas } from './report';
import { setupGuideSchemas } from './setup-guide';
import { shareSchemas } from './share';
import { signUpSchemas } from './sign-up';
import { sortSchemas } from './sort';
import { surveySchemas } from './survey';
import { talentPoolSchemas } from './talent-pool';
import { teamSchemas } from './team';
import { userSchemas } from './user';
import { waitlistSchemas } from './waitlist';
import { workspaceSchemas } from './workspace';
import { zoomSchemas } from './zoom';

export const schemaMap = {
  ...askMarleeSchemas,
  ...assessmentSchemas,
  ...motivationsSchemas,
  ...coachingSchemas,
  ...dashboardSchemas,
  ...debriefSchemas,
  ...goalSchemas,
  ...insightSchemas,
  ...inviteSchemas,
  ...journalSchemas,
  ...modelSchemas,
  ...motivationSchemas,
  ...reportSchemas,
  ...shareSchemas,
  ...signUpSchemas,
  ...sortSchemas,
  ...surveySchemas,
  ...talentPoolSchemas,
  ...teamSchemas,
  ...userSchemas,
  ...workspaceSchemas,
  ...communicationSchemas,
  ...zoomSchemas,
  ...setupGuideSchemas,
  ...paymentsSchemas,
  ...paymentSchemas,
  ...comparisonSchemas,
  ...waitlistSchemas,
  ...creditSchemas,
  ...experimentSchemas,
  ...brainSchemas,
};

export * from './assessment';
export * from './sort';
export * from './invite';
export * from './report';
export * from './ask-marlee';
export * from './sign-up';
export { brainSchemas, type BrainEventKeys } from './brain';
export type { CreditDialogSource } from './credits';

export const baseSchema = z.object({
  // Sometimes we want to be able to override the userId the event is attributed to
  // If not set, the tracking service will use the user set on the request context.
  userId: z.number().optional(),
  context: siteSchema.optional(),
});

export type EventName = keyof typeof schemaMap;
export type AnalyticsSchema<K extends EventName> = z.input<(typeof schemaMap)[K]> &
  z.input<typeof baseSchema>;
