import { z, type Schema } from 'zod';

import { MotivationGroupSlug, MotivationSlug } from '../common/motivations';

type ObjectName = 'motivation';

const motivationBaseSchema = z.object({
  motivationGroups: MotivationGroupSlug,
  motivationName: MotivationSlug,
  motivationScore: z.number().optional(), // Mini assessment has optional motivation scores
  groupSize: z.number(),
});

const motivationDetailSchema = z.enum([
  'results',
  'examples',
  'impact',
  'people',
  'communication',
  'video',
]);

const motivationExploredSchema = motivationBaseSchema.extend({
  motivationDetail: motivationDetailSchema,
});

const motivationCoachingAddedSchema = motivationExploredSchema.extend({
  coachingRecommendations: z.string(),
});

const motivationSchemas = {
  motivation_hovered: motivationBaseSchema,
  motivation_clicked: motivationBaseSchema,
  motivation_explored: motivationExploredSchema,
  motivation_videoPlayed: motivationBaseSchema,
  motivation_added: motivationCoachingAddedSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { motivationSchemas };
