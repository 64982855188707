import { type MouseEventHandler, type ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../button/button';
import type { ButtonColor, ButtonSize, ButtonVariant } from '../button/types';

type Props = {
  to: string;
  variant?: ButtonVariant;
  color?: ButtonColor;
  text?: string;
  frontIcon?: ReactNode;
  trailingIcon?: ReactNode;
  size?: ButtonSize;
  isFullWidth?: boolean;
  isSubmitBtn?: boolean;
  isSolidIcon?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export function LinkButton({
  to,
  variant = 'white',
  color = 'brand',
  text,
  frontIcon,
  trailingIcon,
  size = 'base',
  isFullWidth = false,
  isSubmitBtn = false,
  isSolidIcon = false,
  onClick,
}: Props) {
  return (
    <div>
      <Link to={to} onClick={onClick}>
        <Button
          variant={variant}
          color={color}
          size={size}
          text={text}
          frontIcon={frontIcon}
          trailingIcon={trailingIcon}
          isFullWidth={isFullWidth}
          isSubmitBtn={isSubmitBtn}
          isIconOnly={!text}
          isSolidIcon={isSolidIcon}
        />
      </Link>
    </div>
  );
}
