import CheckCircleIcon from '@heroicons/react/solid/CheckCircleIcon';
import { type ReactNode } from 'react';

import { ButtonCVA } from '@/components/common/button/button-cva';

import { Messages } from './action-buttons.messages';

type Props = {
  isDisabled: boolean;
  hideClose?: boolean;
  isSubmitting?: boolean;
  submitBtnText: string;
  onClose?: () => void;
  onSubmit: () => void;
  submitBtnHasIcon?: boolean;
  extraButtons?: ReactNode;
};

export const ActionButtons = ({
  isDisabled,
  isSubmitting = false,
  hideClose = false,
  submitBtnText,
  submitBtnHasIcon,
  onClose,
  onSubmit,
  extraButtons,
}: Props) => (
  <div className="flex justify-between gap-3">
    <div className="flex gap-3">{extraButtons}</div>
    <div className="flex gap-3">
      {!hideClose && (
        <ButtonCVA
          variant="subtle"
          color="black"
          text={Messages.actionCancelButton()}
          onClick={onClose}
          isSolidIcon
          disabled={isSubmitting}
        />
      )}
      <ButtonCVA
        variant="primary"
        text={submitBtnText}
        frontIcon={submitBtnHasIcon ? <CheckCircleIcon /> : null}
        isSolidIcon
        onClick={onSubmit}
        disabled={isDisabled}
        isLoading={isSubmitting}
      />
    </div>
  </div>
);
