// Disabling for the entire file as we have checked over this issue and updated to match what we need.

/* eslint-disable react-hooks/exhaustive-deps */
import { useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react';
import { createContext, useEffect, useMemo, useState } from 'react';

import type { FeatureFlagMap } from '@f4s/types';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useSubscriptionPlan } from '@/api-queries/billing';

import type { ContextValue } from './types';
import { contextValueFactory } from './util';

type FeatureFlagsContextValue = { featureFlags: FeatureFlagMap };

const { initialState, wrapState } =
  contextValueFactory<FeatureFlagsContextValue>('FeatureFlagsContext');

export const FeatureFlagsContext =
  createContext<ContextValue<FeatureFlagsContextValue>>(initialState);

export const FeatureFlagsContextWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  // Unleash logic for feature flags
  const unleashClient = useUnleashClient();
  const updateUnleashContext = useUnleashContext();

  const { isLoggedIn, user } = useMaybeUser();
  const shouldGetSubscription = isLoggedIn;
  const subscription = useSubscriptionPlan({}, shouldGetSubscription);
  const [unleashFlags, setUnleashFlags] = useState(() => unleashClient.getAllToggles());

  const contextValue = useMemo((): ContextValue<FeatureFlagsContextValue> => {
    // Merge the Unleash feature flags into our manual feature flags so they can all be accessed through this one context.
    const flatUnleashFlags = Object.fromEntries(
      unleashFlags.map((v) => [v.name, v.enabled]),
    );

    return wrapState({
      featureFlags: flatUnleashFlags as FeatureFlagMap,
    });
  }, [JSON.stringify(unleashFlags)]);

  useEffect(() => {
    if (isLoggedIn && user) {
      const previousContext = unleashClient.getContext();
      const { id, created, emailAddress, organizations } = user;
      const userId = id?.toString();
      const organizationNames = organizations
        ? organizations.map((o) => o.name).join(',')
        : '';

      const ctx = {
        ...previousContext,
        userId,
        properties: {
          createdAt: created,
          emailAddress,
          organizationNames,
        },
      };

      updateUnleashContext(ctx);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const previousContext = unleashClient.getContext();
    if (subscription.isSuccess) {
      const subscriptionPlan = subscription.data?.product;
      const ctx = { ...previousContext, subscriptionPlan };
      updateUnleashContext(ctx);
    }
  }, [subscription.isSuccess, subscription.data?.product]);

  // Listening to any changes on Unleash to get up to date feature flags
  // We are manually doing this as Unleash SDK doesn't provide us a way to get updated values when fetching all of the feature flags
  useEffect(() => {
    if (!unleashClient) return;

    const updateHandler = () => {
      setUnleashFlags(unleashClient.getAllToggles());
    };

    unleashClient.on('update', updateHandler);
    unleashClient.on('ready', updateHandler);

    return () => {
      unleashClient.off('update', updateHandler);
      unleashClient.off('ready', updateHandler);
    };
  }, [unleashClient]);

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
