import type { FallbackRender } from '@sentry/react';
import { type ComponentProps } from 'react';

import { Button } from '@/components/common/button/button';

type ErrorPageProps = { sentryProps: ComponentProps<FallbackRender> };

export const ErrorPage = (errorData: ErrorPageProps) => {
  const isProduction = window.location.href.startsWith('https://app.');
  const showDevInfo = !isProduction && !!errorData;

  if (!showDevInfo) {
    setTimeout(() => {
      window.location.href = '/';
    }, 3000);
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <p>Something went Wrong!</p>
      {showDevInfo && <SentryError {...errorData.sentryProps} />}
      {!showDevInfo && <a href="/">Back to home</a>}
    </div>
  );
};

function SentryError({
  error,
  componentStack,
  resetError,
}: ComponentProps<FallbackRender>) {
  return (
    <div className="flex basis-full flex-col items-center gap-4 overflow-y-scroll py-4">
      <p>{error?.toString()}</p>
      <pre>{componentStack}</pre>
      <Button variant="primary" text="Click here to reset!" onClick={resetError} />
    </div>
  );
}
