import clsx from 'clsx';
import { type ReactElement } from 'react';

import { getTypographyStyles } from '../typography';
import { type HeadingProps } from './headings.props';

import styles from './headings.module.scss';

export const Heading = ({
  headingTag: tag,
  level = 1,
  width = 'auto',
  children,
  size: _size, // Pull out and ignore size typography prop - we over-ride it
  ...typographyProps
}: HeadingProps): ReactElement => {
  const HeadingElement = tag ?? `h${level}`;
  const headingClass = `heading${level}`;

  if (!typographyProps.color && level < 3) {
    typographyProps.color = 'gray-900';
  }
  return (
    <HeadingElement
      className={clsx(styles[headingClass], getTypographyStyles(typographyProps))}
      style={{ width }}
    >
      {children}
    </HeadingElement>
  );
};
