import { useEffect, useState } from 'react';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useGetInvitation } from '@/api-queries/organizations';
import { Loader } from '@/components/common/loader/loader';
import { AuthDashboardIndexV2 } from '@/layouts';
import { useHistorySteps } from '@/lib/hooks/useHistorySteps';
import { redirectAsync } from '@/lib/underscore';
import { OrgInviteBanner } from '@/pages/sign-up/sign-up-v2/components/org-invite-banner';
import { AboutYouStep } from '@/pages/sign-up/sign-up-v2/form-flow/about-you-step';
import styles from '@/pages/sign-up/sign-up-v2/style-signup.module.scss';
import { type SignUpDetailTypes } from '@/pages/sign-up/sign-up-v2/types';

import { AssessmentPrompt } from './assessment-prompt';
import { JoinOrg } from './join-org-step';
import { WelcomeStep } from './welcome-step';

const StepNames = ['welcome', 'about-you', 'join-org', 'assessment-prompt'] as const;
const STEPS: Record<
  (typeof StepNames)[number],
  { displayStep: number | false; displayLogin?: boolean; displayCompleted?: boolean }
> = {
  'welcome': { displayStep: false, displayLogin: true },
  'about-you': { displayStep: 1 },
  'join-org': { displayStep: 2 },
  'assessment-prompt': { displayStep: 3 },
} as const;

type StepName = (typeof StepNames)[number];

export function FormFlowRoute() {
  const { path } = useRouteMatch();
  return <Route path={`${path}/:step?`} component={FormFlow} />;
}

function FormFlow(): React.ReactElement {
  const { user, isLoggedIn } = useMaybeUser();
  let firstStep: StepName = 'welcome';
  if (isLoggedIn) firstStep = 'about-you';
  if (user?.onboardingCompleted) firstStep = 'join-org';

  const [step, setStep] = useHistorySteps<StepName>(firstStep, 'step', false);
  const stepIdx = StepNames.indexOf(step);
  const params: any = useParams();
  const history = useHistory();
  const {
    refetch: getInvitation,
    isSuccess: inviteSuccess,
    data: inviteData,
    isLoading: inviteLoading,
  } = useGetInvitation({
    token: params.token,
    enabled: false,
  });

  const [signUpDetails, setSignUpDetails] = useState<SignUpDetailTypes>(() => {
    const email = user?.emailAddress || '';
    const firstName = user?.firstName || '';
    const lastName = user?.lastName || '';
    const countryCode = user?.countryCode || '';
    const jobTitle = user?.properties?.jobTitle || '';
    const jobArea = user?.properties?.jobArea || '';

    return {
      email,
      firstName,
      lastName,
      countryCode,
      jobArea,
      jobTitle,
      goal: 0,
      goalType: [],
      onboardingCompleted: user?.onboardingCompleted || false,
    };
  });

  useEffect(() => {
    const getInvite = async () => {
      const {
        data,
        error,
        isError,
        status,
      }: { data: any; error: any; isError: boolean; status: string } =
        await getInvitation(params.token);
      if (status === 'success' && data.userId) {
        history.push(`/sign-in/organization/invite/${params?.token}`);
      } else if (isError && error?.response?.status === 404) {
        if (isLoggedIn) {
          return history.push('/me');
        }
        await redirectAsync(`/sign-up?redir=${window.location.pathname}`);
      }
    };
    getInvite();
  }, [getInvitation, history, params.token]);
  const nextStep = () => {
    const next = StepNames[stepIdx + 1];
    setStep(next);
  };
  const handleInputChange = (name: string, value: string | number) => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const stepContainer = () => {
    switch (step) {
      case 'welcome': {
        return (
          <WelcomeStep
            nextStep={nextStep}
            orgName={inviteData?.organization.name}
            emailAddress={inviteData?.emailAddress}
            signupFlow="2022-org-invite"
            banner={
              <OrgInviteBanner
                org={inviteData.organization}
                token={params.token}
                from="sign-up"
              />
            }
          />
        );
      }
      case 'about-you': {
        return (
          <AboutYouStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
          />
        );
      }
      case 'join-org': {
        return <JoinOrg nextStep={nextStep} />;
      }
      case 'assessment-prompt': {
        return <AssessmentPrompt />;
      }
      default: {
        return <div className="App" />;
      }
    }
  };
  const { displayStep, displayCompleted = false, displayLogin = false } = STEPS[step];
  const totalSteps = Math.max(...Object.values(STEPS).map((s) => s.displayStep || 0));
  return (
    <>
      {inviteSuccess && (
        <AuthDashboardIndexV2
          displayStep={displayStep}
          totalSteps={totalSteps}
          displayLogin={displayLogin}
          displayCompleted={displayCompleted}
        >
          <div className={styles.loginFormRoot}>{stepContainer()}</div>
        </AuthDashboardIndexV2>
      )}
      {inviteLoading && <Loader size="lg" />}
    </>
  );
}
