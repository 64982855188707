import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';
import React from 'react';

import { FeatureFlags } from '@f4s/types';

import { useFetchAppSettings, useMaybeUser } from '@/api-queries/app-settings';
import { Button } from '@/components/common/button/button';
import { LocaleSelector } from '@/components/i18n/locale-selector';
import { MessageBubbleIcon } from '@/icons';
import { showIntercom } from 'Lib/utils/intercom';

import { Messages } from './auth-dashboard-footer.messages';

import styles from './auth-dashboard-footer.module.scss';

export const AuthDashboardFooter = (): React.ReactElement => {
  const { data: settings } = useFetchAppSettings();
  const { user } = useMaybeUser();

  const liveSupport = () =>
    showIntercom({ intercomAppId: settings?.intercomAppId, user });

  const isLocaleSelectorEnabled = useFlag(FeatureFlags.i18nLocaleSelector);

  return (
    <div className={clsx(styles.authDashboardFooter)}>
      {isLocaleSelectorEnabled && <LocaleSelector />}
      <span className={clsx(styles.authDashboardFooterText)}>
        {Messages.copyrightCompany()}
      </span>
      <Button
        variant="link"
        frontIcon={<MessageBubbleIcon />}
        text={Messages.liveSupportButton()}
        onClick={liveSupport}
      />
    </div>
  );
};
