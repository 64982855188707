import queryString from 'query-string';

import type { Benchmarks } from '@f4s/types';

import { httpAgent as http } from './agents/http';

export const get = () => http().get<Benchmarks>('/api/v3/modeling/benchmarks');

export const getRanking = (opts: {
  modelId?: string;
  orderedQuery?: string;
  organizationId?: number;
  groupId?: number;
  userId?: number | number[];
  id?: number | number[];
  page?: number;
  pageSize?: number;
  scope?: number;
}) =>
  http().get<{
    results: {
      rank: number;
      score: number;
      questionnaireId: number;
      userId: number;
      firstName: string;
      lastName: string;
      avatarUrl: string | null;
    }[];
    isLimited: boolean;
    isTotalLimited: boolean;
    totalRankings: number;
    limitQuantity: number;
  }>(`/api/v3/modeling/ranking?${queryString.stringify(opts)}`);
