import { boolean, number, object, string, z } from 'zod';

export const userInfoSchema = object({
  firstName: string().nonempty('Please provide your first name'),
  lastName: string().nonempty('Please provide your last name'),
  emailAddress: string()
    .toLowerCase()
    .email({ message: 'Please provide your email address' }),
  marketingOptIn: boolean().default(false),
  tocAcceptance: boolean()
    .default(false)
    .refine(
      (value) => {
        return value !== false;
      },
      {
        message: 'Please read and accept the terms and conditions to proceed',
      },
    ),
});

export type UserInfoFormType = z.infer<typeof userInfoSchema>;

const basicsSchema = userInfoSchema.extend({
  countryCode: string(),
});

const candidateSchema = object({
  linkedInProfileURL: string().optional(),
  openToOtherRoles: boolean().default(false).optional(),
  candidateExperienceLevel: string().optional(),
  candidateManagementExperience: string().optional(),
  candidateJobSearchTiming: string().optional(),
  candidateJobSearchGoal: string().optional(),
  candidateWorkCountry: string().optional(),
  candidateWorkCity: string().optional(),
  candidateAllowedToWorkInWorkCountry: boolean().refine(
    (value) => {
      // Value is not the value of the boolean, it's checking that the field has a value
      return value === true;
    },
    {
      message: 'Please confirm your status to work in that country',
    },
  ),
  phoneCountry: string().optional(),
  phoneNumber: string().optional(),
});

const interestedSchema = object({
  interestedOfficePolicy: string(),
  interestedDesiredPay: z.coerce.number(),
  interestedCurrency: string(),
  interestedCompanySize: string(),
  interestedCompanyCommitments: string(),
});

// the extra things needed in Prisma
const applicationExtraDataSchema = object({
  userId: number(),
  uploadedCVURL: string().nullable(),
  uploadedCVName: string().nullable(),
});

const properties = object({
  properties: z
    .record(z.string(), z.union([z.string(), z.number(), z.boolean()]))
    .optional(),
});

export const tagsDataSchema = object({
  jobRole: string().optional(),
});

export const formSchema = basicsSchema
  .merge(candidateSchema)
  .merge(interestedSchema)
  .merge(tagsDataSchema)
  .merge(properties);
export type TalentPoolForm = z.infer<typeof formSchema>;
export const applicationDataOnlySchema = candidateSchema
  .merge(interestedSchema)
  .merge(applicationExtraDataSchema);
// talentSettingsDataOnlySchema is the front-end schema for updateTalentSettingsSchema in the backend (Used from user's talent hub settings page)
export const talentSettingsDataOnlySchema = candidateSchema.merge(interestedSchema);
export const updateTalentSettingsSchema = basicsSchema
  .partial()
  .merge(candidateSchema)
  .merge(interestedSchema)
  .merge(applicationExtraDataSchema.partial())
  .merge(tagsDataSchema)
  .merge(properties);
export type TalentPoolUpdate = z.infer<typeof updateTalentSettingsSchema>;

export const formSchemaWithCv = formSchema.extend({
  applicantCv: z.instanceof(Blob).optional(),
});
export type FormType = z.infer<typeof formSchemaWithCv>;

export const userInfoWithPropertiesSchema = userInfoSchema
  .merge(properties)
  .merge(tagsDataSchema);

export type UserInfoWithProperties = z.infer<typeof userInfoWithPropertiesSchema>;

export const talentPoolLandingPageSchema = userInfoWithPropertiesSchema.merge(
  object({
    isLandingPage: z.boolean(),
  }),
);

export type TalentPoolLandingPageUpdate = z.infer<typeof talentPoolLandingPageSchema>;
