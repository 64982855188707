import { useEffect, useState } from 'react';

import { Button } from '@/components/common/button/button';
import { cloneDeep } from 'Underscore';

import { processTemplateString } from '../utils';
import { type InputOption } from './types';
import { getOptions } from './utils';

import styles from './select.module.scss';

// Normalize options into an array with readable ids and names
const normalizeOptions = (options: InputOption[] | Record<string, InputOption>) =>
  Array.isArray(options)
    ? options
    : Object.entries(options).map(([key, value]) =>
        typeof value === 'string'
          ? { id: key, name: value }
          : // @ts-expect-error TODO: Reduce type complexity of options
            { id: key, ...value },
      );

type SelectInputProps = {
  store: string;
  data: { [key: string]: any };
  onChange: (...args: any) => void;
  next: (...args: any) => void;
  defaultOption: string;
  optionsVariable: string;
  className: string;
};

/**
 * @deprecated As much as possible, we want to reduce the use of these flow-form components, and if we want to use something like this in the future, it's probably
 * easier to just plan and build a new set of components. We also abuse the type any quite a bit here, which makes things perilous to continue interating upon
 */
export function SelectInput({
  store,
  data,
  onChange,
  next,
  defaultOption,
  ...props
}: SelectInputProps) {
  const [saved, setSaved] = useState(0);
  const options = normalizeOptions(getOptions(props, data));

  // Because it takes a render cycle to update data, we push next until the data
  // has saved
  useEffect(() => {
    if (saved) next();
  }, [saved]);

  return (
    <div className={styles.select}>
      {options.map((option) => {
        const opt = cloneDeep(option);
        // @ts-expect-error TODO: Reduce type complexity of options
        const id = opt.id || opt;
        // @ts-expect-error TODO: Reduce type complexity of options
        const name = processTemplateString(opt.description || opt.name || opt, data);

        return (
          <Button
            key={id}
            variant={id === defaultOption ? 'primary' : 'white'}
            text={name}
            onClick={() => {
              onChange({ ...data, [store]: id });
              setSaved(saved + 1);
            }}
          />
        );
      })}
    </div>
  );
}

SelectInput.requiresNext = false;
