import { useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';
import { AuthDashboardIndexV2 } from '@/layouts';
import { useHistorySteps } from '@/lib/hooks/useHistorySteps';
import { usePageView } from '@/lib/hooks/usePageView';
import { SignUpTestimonialCard } from '@/pages/sign-up/sign-up-v2/components/sign-up-testimonial-card';
import { AboutYouStep } from '@/pages/sign-up/sign-up-v2/form-flow/about-you-step';
import { CreateAccountStep } from '@/pages/sign-up/sign-up-v2/form-flow/create-account-step';
import { type SignUpDetailTypes } from '@/pages/sign-up/sign-up-v2/types';

import styles from '../../sign-up-v2/style-signup.module.scss';

const StepNames = ['create-account', 'about-you'] as const;

const STEPS: Record<
  (typeof StepNames)[number],
  { displayStep: number | false; displayLogin?: boolean; displayCompleted?: boolean }
> = {
  'about-you': { displayStep: false },
  'create-account': { displayStep: false, displayLogin: true },
} as const;

type StepName = (typeof StepNames)[number];

export function FormFlowRoute() {
  const { path } = useRouteMatch();
  return <Route path={`${path}/:step?`} component={FormFlow} />;
}

const useExistingUserData = (): SignUpDetailTypes => {
  const { user } = useMaybeUser();

  if (!user) {
    return {
      email: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      jobArea: '',
      goal: 0,
      goalType: [],
      countryCode: '',
      onboardingCompleted: false,
    };
  }

  return {
    email: user.emailAddress,
    firstName: user.firstName,
    lastName: user.lastName,
    jobArea: user.properties?.jobArea || '',
    jobTitle: user.properties?.jobTitle || '',
    goal: 0,
    goalType: [],
    countryCode: user.countryCode,
    onboardingCompleted: user.onboardingCompleted || false,
  };
};

const FormFlow = () => {
  const [step, setStep] = useHistorySteps<StepName>('create-account', 'step', false);
  usePageView(`Sign up - ${step}`);

  const stepIdx = StepNames.indexOf(step);

  const userData = useExistingUserData();

  const history = useHistory();

  const [signUpDetails, setSignUpDetails] = useState<SignUpDetailTypes>(userData);

  const nextStep = () => {
    const next = StepNames[stepIdx + 1];
    setStep(next);
  };

  const handleInputChange = (name: string, value: string | number) => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const stepContainer = () => {
    switch (step) {
      case 'about-you': {
        return (
          <AboutYouStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={() => history.push('/assessment/convincer-input/welcome')}
          />
        );
      }
      case 'create-account': {
        return (
          <CreateAccountStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
            signupFlow="embedded-zoom"
            ssoRedir="/sign-up/zoom/about-you"
            isZoom
          />
        );
      }

      default: {
        return <div className="App" />;
      }
    }
  };

  const { displayStep, displayCompleted = false, displayLogin = false } = STEPS[step];
  const totalSteps = Math.max(...Object.values(STEPS).map((s) => s.displayStep || 0));

  const rightContentContainer =
    step === 'create-account' ? <SignUpTestimonialCard /> : null;

  return (
    <AuthDashboardIndexV2
      displayStep={displayStep}
      totalSteps={totalSteps}
      displayCompleted={displayCompleted}
      displayLogin={displayLogin}
      rightContent={rightContentContainer}
    >
      <div className={styles.loginFormRoot}>{stepContainer()}</div>
    </AuthDashboardIndexV2>
  );
};
