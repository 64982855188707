import { z } from 'zod';

export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

/**
 *  Schema utility to convert optionally null values to undefined
 *  Allows undefined | null input values → resulting value excludes null
 * */
export const undefy = <T>(schema: z.Schema<T>) =>
  schema.nullable().transform<NonNullable<T> | undefined>((value) => value ?? undefined);

export const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
export type Literal = z.infer<typeof literalSchema>;
export type Json = Literal | { [key: string]: Json } | Json[];
export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);
export const json = () => jsonSchema;

export type JsonObject = { [key: string]: Json };
export const jsonObjectSchema: z.ZodType<JsonObject> = z.record(jsonSchema);

export type JsonArray = Json[];
export const jsonArraySchema: z.ZodType<JsonArray> = z.array(jsonSchema);

export * from './guards';
export * from './errors';
export * from './types';
