import { createContext, useCallback, useMemo, useState, type ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import type { ContextValue } from './types';
import { contextValueFactory } from './util';

type ReturnToContextValue = {
  returnURL?: string;
  setReturnURL: (url: string | undefined) => void;
  setReturnURLHere: (
    newParams?: Record<string, string | number>,
    resetAllParams?: boolean,
  ) => void;
  goToReturnURL: () => void;
};

const { initialState, wrapState } =
  contextValueFactory<ReturnToContextValue>('ReturnToContext');

export const ReturnToContext =
  createContext<ContextValue<ReturnToContextValue>>(initialState);

export const ReturnToContextWrapper: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [returnURL, setReturnURL] = useState<string>();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const goToReturnURL = useCallback(() => {
    if (returnURL !== undefined) {
      history.push(returnURL);
      setReturnURL(undefined);
    }
  }, [returnURL]);

  const setReturnURLHere = useCallback<ReturnToContextValue['setReturnURLHere']>(
    (newParams, resetAllParams) => {
      const params = new URLSearchParams(resetAllParams ? '' : search);

      if (newParams) {
        Object.entries(newParams).forEach(([key, value]) => {
          params.set(key, value.toString());
        });
      }

      setReturnURL(`${pathname}?${params}`);
    },
    [pathname, search, setReturnURL],
  );

  const contextValue = useMemo(
    (): ContextValue<ReturnToContextValue> =>
      wrapState({
        returnURL,
        setReturnURL,
        goToReturnURL,
        setReturnURLHere,
      }),
    [returnURL, goToReturnURL, setReturnURLHere],
  );

  return (
    <ReturnToContext.Provider value={contextValue}>{children}</ReturnToContext.Provider>
  );
};
