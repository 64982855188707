import { z, type Schema } from 'zod';

type ObjectName = 'debrief';

const debriefBaseSchema = z.object({
  slidePosition: z.number(),
  totalSlides: z.number(),
});

const debriefCompletedSchema = debriefBaseSchema.extend({
  askMarleePostDebrief: z.boolean().optional(),
  followingFeature: z.enum(['askMarlee', 'teamMotivation', 'myResult']),
  demoUsersSelected: z.string().array().optional(),
});

const debriefSchemas = {
  debrief_started: debriefBaseSchema,
  debrief_next: debriefBaseSchema,
  debrief_completed: debriefCompletedSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { debriefSchemas };
