import { XIcon } from '@heroicons/react/solid';

import { ButtonCVA } from '@/components/common/button/button-cva';

import { type SelectableTeamMember } from '../../types';
import { handleMemberChange, useIsMe } from '../../utils';

const RemoveButton = ({
  onRemove,
  isDisabled = false,
}: {
  onRemove: () => void;
  isDisabled?: boolean;
}) => (
  <ButtonCVA
    size="base"
    variant="subtle"
    color="black"
    isIconOnly
    isSolidIcon
    disabled={isDisabled}
    frontIcon={<XIcon />}
    onClick={onRemove}
  />
);

export const MemberRemoveControls = <T extends SelectableTeamMember>({
  members,
  member,
  updateMembers,
  onMemberRemoved,
}: {
  members: T[];
  member: T;
  updateMembers: (members: T[]) => void;
  onMemberRemoved?: (member: T) => void;
}) => {
  const { isMe, isMeOverTime } = useIsMe(member);

  return (
    <div className="flex w-8">
      <RemoveButton
        isDisabled={isMe && !isMeOverTime}
        onRemove={() => {
          handleMemberChange(updateMembers, members, member, true);
          onMemberRemoved?.(member);
        }}
      />
    </div>
  );
};
