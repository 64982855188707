import { useState } from 'react';

import { useMaybeUser } from '@/api-queries/app-settings';
import { CobrandedRightContent } from '@/components/ui/cobranded-banner';
import { AuthDashboardIndexV2 } from '@/layouts';
import { usePageView } from '@/lib/hooks/usePageView';

import { InsightShareCreateAccount } from './components/insight-share-create-account';
import { type SignUpDetailTypes } from './types';

import styles from './style-signup.module.scss';

const useExistingUserData = (): SignUpDetailTypes => {
  const { user } = useMaybeUser();

  if (!user) {
    return {
      email: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      jobArea: '',
      goal: 0,
      goalType: [],
    };
  }

  return {
    email: user.emailAddress,
    firstName: user.firstName,
    lastName: user.lastName,
    jobArea: user.properties?.jobArea || '',
    jobTitle: user.properties?.jobTitle || '',
    goal: 0,
    goalType: [],
  };
};

export function SharedSignUpRoot(): React.ReactElement {
  usePageView(`Sign up - Create account`);

  const userData = useExistingUserData();

  const [signUpDetails, setSignUpDetails] = useState<SignUpDetailTypes>(userData);

  const handleInputChange = (name: string, value: string | number) => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <AuthDashboardIndexV2 displayLogin rightContent={<CobrandedRightContent />}>
      <div className={styles.loginFormRoot}>
        <InsightShareCreateAccount
          signUpDetails={signUpDetails}
          handleInputChange={handleInputChange}
          signupFlow="2022-default"
          ssoRedir="/questions/welcome"
        />
      </div>
    </AuthDashboardIndexV2>
  );
}
