import styles from './component-theme.module.scss';

type Props = React.PropsWithChildren<{
  color?: 'accent' | 'motivation' | 'plain' | 'plain-inverted';
}>;

export const ComponentTheme = ({ color = 'accent', children }: Props) => (
  <div data-theme={color} className={styles.provider}>
    {children}
  </div>
);

export const ComponentThemeClass = styles.provider;
