import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { ORG_LOCAL_STORAGE_KEY } from '@/constants';
import { isEmpty } from '@/lib/underscore';
import { BrowserStorage, getStorageItem } from 'Utils/local-storage';

import { MemberEmailVerification } from './email-verification';
import { MemberPermissionAgreement } from './permission';
import { MemberWelcome } from './welcome';

const storage = BrowserStorage('local');

export function MemberJoinRoot({ isLoggedIn }: { isLoggedIn: boolean }) {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const page = pathname.split('/')[3];
  const localOrgStorage = getStorageItem({
    storage,
    key: ORG_LOCAL_STORAGE_KEY,
  });

  if (isEmpty(localOrgStorage) || (!isLoggedIn && page !== 'welcome')) {
    return <Redirect to="/sign-in" />;
  }

  return (
    <Switch>
      <Route path={`${path}/:type/welcome`} component={MemberWelcome} />
      <Route path={`${path}/:type/permission`} component={MemberPermissionAgreement} />
      <Route
        path={`${path}/client/email-verification`}
        component={MemberEmailVerification}
      />
    </Switch>
  );
}
