import { useFlagsStatus } from '@unleash/proxy-client-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useConnectionsInvalidation } from '@/api-queries/connections';
import { useAcceptInvite } from '@/api-queries/invitations';
import { Loader } from '@/components/common/loader/loader';
import { AuthDashboardIndex } from '@/layouts/auth-dashboard-index';
import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';
import { errorHandler } from 'Utils/error-handler';

import { acceptInvitation, presetOnboardingEmail, setLocalInvitationData } from './utils';

// still needed for now
const INVITE_MAPPING = {
  connection: 'user',
  group: 'groups',
} as const;

export function InviteRoot() {
  const { isLoggedIn } = useMaybeUser();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { pathname, search } = useLocation();
  const invalidateConnections = useConnectionsInvalidation();
  const { flagsReady } = useFlagsStatus();
  const { mutateAsync: acceptInvite } = useAcceptInvite();
  const params = queryString.parse(search);
  const { user } = useMaybeUser();

  /** only used for groups now. */
  const getInvitation = async () => {
    /**
     * route-validate will handle redirect
     */
    if (params.type === 'org') return;

    try {
      if (params.type !== 'group') {
        throw new Error('Invite type is invalid');
      }

      const { data: inviter } = await API.groups.getInvitation(token);

      if (!inviter) {
        throw new Error('Could not find the Inviter');
      }
      setLocalInvitationData({
        redirect: `${pathname}${search}`,
        type: INVITE_MAPPING[params.type],
        token,
        inviter,
      });

      if (inviter.userId) {
        // [PAN931] if existing user regardless of being loggedin, it should accept the invitation 18/3/22
        const invitationType = params.type;
        let redirect = '/sign-in';

        if (isLoggedIn) {
          // if logged in then we can accept
          if (invitationType === 'group') {
            redirect = await acceptInvitation();
          }
          invalidateConnections();
          history.push(redirect);
        } else {
          const redir = `?redir=${pathname}${search}`;
          history.push(`/sign-in${redir}`);
        }
      } else {
        // if user doesn't exist, then get them to sign-up
        presetOnboardingEmail(inviter.emailAddress);
        history.push('/sign-up/get-started');
      }
    } catch (error) {
      errorHandler(error, Notification);
      history.push('/');
    }
  };

  const handleInvite = async () => {
    let inviteData;

    try {
      inviteData = await acceptInvite(token);
      if (inviteData && inviteData.type === 'connection') {
        if (inviteData.status === 'pending') {
          // Svc fn only returns pending invite if user is not logged in and invited user doesn't exist
          // Go to sign up page with pre-populated email
          setLocalInvitationData({
            redirect: `${pathname}${search}`,
            type: INVITE_MAPPING[inviteData.type],
            token,
            inviter: {
              emailAddress: inviteData.sentToEmailAddress,
              inviterId: inviteData.sentByUserId,
            },
          });

          // if a logged in user tries to accept this invite (and invited user doesn't exist, then throw the error and redir to me page)
          if (user) {
            Notification.create(
              'Unable to use this invite as you are not the intended recipient',
              'error',
            );
            return history.push('/me');
          }

          Notification.create('Please sign up to use this invite', 'error');
          history.push(`/sign-up/get-started?redir=${pathname}${search}`);
        } else if (inviteData.status === 'accepted') {
          if (isLoggedIn) {
            history.push(`/teams/insights?userId=${inviteData.sentByUserId}`);
          } else {
            // user is not logged in but has account, send them to sign-in page
            history.push(`/sign-in?redir=${pathname}${search}`);
          }

          Notification.create(AppText.user_invite_notification_accepted, 'success');
        }
      }
    } catch {
      history.push('/');
    }
  };

  useEffect(() => {
    if (flagsReady) {
      const invitationTypeFromParam = params.type;
      if (invitationTypeFromParam === 'group') {
        // TODO: As part of IJWP2 group invitations will be removed. (you join a group when connected via user or org invite)
        getInvitation();
      } else {
        // previously IJWP1 route. Old invite logic removed
        handleInvite();
      }
    }
  }, [flagsReady]);

  return (
    <AuthDashboardIndex pageTitle="Verify Invitation">
      <div className="login-form__loading text-center">
        <Loader size="md" />
      </div>
    </AuthDashboardIndex>
  );
}
