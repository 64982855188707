import qs from 'query-string';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { type LanguageCode, LanguageSchema } from '@/constants/languages';
import { setLanguage as i18nSetLanguage } from '@/lib/i18n';
import { type Callback } from '@/types';

import type { ContextValue } from './types';
import { contextValueFactory } from './util';

type LanguageContextValue = {
  language: LanguageCode;
  setLanguage: Callback<LanguageCode>;
};

const { initialState, wrapState } =
  contextValueFactory<LanguageContextValue>('LanguageContext');

export const LanguageContext =
  createContext<ContextValue<LanguageContextValue>>(initialState);

const syncLanguageCode = (): LanguageCode => {
  const { search } = window.location;
  const queryLanguage = qs.parse(search).lang;

  if (window.self === window.top) {
    const sessionLanguage = window.sessionStorage.getItem('language');
    const language = LanguageSchema.parse(queryLanguage ?? sessionLanguage);
    window.sessionStorage.setItem('language', language);
    return language;
  }

  return LanguageSchema.parse(queryLanguage);
};

export const LanguageContextWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [language, setLanguageState] = useState<LanguageCode>(syncLanguageCode);

  const setLanguage = useCallback((newLanguage: LanguageCode) => {
    setLanguageState(LanguageSchema.parse(newLanguage));
  }, [setLanguageState]);

  useEffect(() => {
    if (window.self === window.top) {
      window.sessionStorage.setItem('language', language);
    }
  }, [language]);

  i18nSetLanguage(language);
  document.documentElement.lang = language;

  const contextValue = useMemo(() => wrapState({ language, setLanguage }), [language, setLanguage]);

  return (
    <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>
  );
};
