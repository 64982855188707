import { type ReactNode } from 'react';

type Props = {
  title: string;
  subTitle?: string | ReactNode;
  icon?: ReactNode;
};

export const Header = ({ title, subTitle, icon }: Props) => (
  <div className="flex flex-col gap-2">
    <div className="flex items-center justify-between">
      <div className="text-lg font-semibold text-gray-900">{title}</div>
      {icon}
    </div>
    {typeof subTitle === 'string' && (
      <div className="text-sm text-gray-500">{subTitle}</div>
    )}
    {typeof subTitle === 'object' && subTitle}
  </div>
);
