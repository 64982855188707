import clsx from 'clsx';
import { type ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';

import { EmojiPlugin } from 'Lib/emoji';

import styles from './text.module.scss';

type RichTextProps = React.ComponentProps<typeof ReactMarkdown> & {
  webflow?: boolean;
  redesign?: boolean;
};

/** A wrapper component for ReactMarkdown that assigns a set of default plugins. */
export const RichText = (props: RichTextProps): ReactElement => (
  <ReactMarkdown
    components={{
      // Open links in a new tab
      a: ({ node: _node, children, ...linkProps }) => (
        <a {...linkProps} target="_blank">
          {children}
        </a>
      ),
    }}
    className={clsx({
      [styles.webflow]: props.webflow,
      [styles.redesign]: props.redesign,
    })}
    rehypePlugins={[EmojiPlugin]}
    {...props}
  />
);
