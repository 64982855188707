import * as PopoverPrimitive from '@radix-ui/react-popover';
import clsx from 'clsx';
import { type ComponentProps, type ReactNode } from 'react';

type Props = {
  trigger?: ReactNode;
  children: ReactNode;
  side?: ComponentProps<typeof PopoverPrimitive.Content>['side'];
  align?: ComponentProps<typeof PopoverPrimitive.Content>['align'];
  arrow?: boolean;
  offset?: number;
  open?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  onFocusOutside?: ComponentProps<typeof PopoverPrimitive.Content>['onFocusOutside'];
  onPointerDownOutside?: ComponentProps<
    typeof PopoverPrimitive.Content
  >['onPointerDownOutside'];
  disabled?: boolean;
};

export const Popover = ({
  trigger,
  children,
  side = 'bottom',
  arrow,
  offset = 5,
  align = 'center',
  open,
  autoFocus = true,
  fullWidth = false,
  onPointerDownOutside,
  onFocusOutside,
  disabled = false,
}: Props) => (
  <PopoverPrimitive.Root open={open}>
    {trigger !== undefined && (
      <PopoverPrimitive.Trigger
        disabled={disabled}
        asChild
        className={clsx({ 'w-full': fullWidth, 'pointer-events-none': disabled })}
      >
        <div>{trigger}</div>
      </PopoverPrimitive.Trigger>
    )}
    {trigger === undefined && (
      <PopoverPrimitive.Trigger className={clsx({ 'w-full': fullWidth })} />
    )}
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        onOpenAutoFocus={(event) => {
          if (!autoFocus) event.preventDefault();
        }}
        onCloseAutoFocus={(event) => {
          if (!autoFocus) event.preventDefault();
        }}
        onFocusOutside={onFocusOutside}
        onPointerDownOutside={onPointerDownOutside}
        className={clsx([
          'data-[state=open]:data-[side=top]:animate-slideDownAndFade',
          'data-[state=open]:data-[side=right]:animate-slideLeftAndFade',
          'data-[state=open]:data-[side=bottom]:animate-slideUpAndFade',
          'data-[state=open]:data-[side=left]:animate-slideRightAndFade',
          'z-[110]',
          'w-fit min-w-[var(--radix-popover-trigger-width)]',
          '!pointer-events-auto',
        ])}
        sideOffset={offset}
        align={align}
        side={side}
      >
        {children}
        {arrow && <PopoverPrimitive.Arrow className="fill-white" />}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  </PopoverPrimitive.Root>
);
