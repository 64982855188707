import confetti from 'canvas-confetti';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useConnectionsInvalidation } from '@/api-queries/connections';
import { Button } from '@/components/common/button/button';
import { LinkButton } from '@/components/common/link-button/link-button';
import { user as userApi } from 'APIs';
import { SocialButtonsV2, socialLink } from 'Components/buttons/social-buttons-v2';

import {
  getCampaignCode,
  getReferrerToken,
  setCampaignCode,
  setReferrerToken,
} from '../../sign-up/utils';
import { Messages } from './referral-template.messages';

import styles from './referral-template.module.scss';

export const ReferralPageTemplate = ({ useConfetti = true, children }) => {
  const canvas = useRef<HTMLCanvasElement & { confetti: any }>(null);
  const lastRun = useRef<number>(performance.now());
  const { user, isLoggedIn } = useMaybeUser();
  const history = useHistory();
  const location = useLocation();
  const { matches: reducedMotion } = window.matchMedia(
    '(prefers-reduced-motion: reduce)',
  );
  const invalidateConnections = useConnectionsInvalidation();
  const referrer = getReferrerToken();

  const acceptReferral = async () => {
    const campaign = getCampaignCode();
    if (referrer && campaign && user?.id) {
      const { data: referral } = await userApi.registerAffiliate(
        referrer,
        campaign,
        'sign-up',
      );
      invalidateConnections();
      if (referral.completed) {
        setReferrerToken();
        setCampaignCode();
      }
    }
  };

  useEffect(() => {
    if (!useConfetti || !canvas.current || reducedMotion) return;

    const runConfetti =
      canvas?.current?.confetti || confetti.create(canvas.current, { resize: true });

    function frame(timeStamp: number = performance.now()) {
      if (timeStamp - lastRun.current < 10) {
        lastRun.current = timeStamp;
        return;
      }
      lastRun.current = timeStamp;
      runConfetti({
        particleCount: 300,
        spread: 120,
        origin: {
          y: 0,
        },
      });
    }
    frame();
    const interval = setInterval(() => requestAnimationFrame(frame), 1000);
    return () => clearInterval(interval);
  }, [useConfetti, canvas, reducedMotion]);

  const socialParams = {
    referralCode: referrer,
    redir: location.pathname,
    signupFlow: '2022-default',
  };

  return (
    <div className={styles.referralTemplate}>
      <div className={styles.left}>
        <canvas ref={canvas} />
        <img
          className={styles.logo}
          src="/assets/f4s_logo.svg"
          alt="Fingerprint For Success"
        />

        <img
          className={styles.logoMobile}
          src="/assets/f4s_mark.svg"
          alt="Fingerprint For Success"
        />
        {children}
        {!isLoggedIn ? (
          <>
            <SocialButtonsV2
              links={{
                google: socialLink('google', socialParams),
                facebook: socialLink('facebook', socialParams),
                linkedin: socialLink('linkedin', socialParams),
              }}
            />
            <div className="divider__root">
              <hr className="divider force-show" />
              <div className="divider__text">{Messages.dividerText()}</div>
            </div>
            <LinkButton
              to="/sign-up/get-started"
              text={Messages.signUpButton()}
              variant="primary"
              isFullWidth
            />
          </>
        ) : (
          <Button
            onClick={async () => {
              acceptReferral();
              history.push('/teams/');
            }}
            variant="primary"
            text={Messages.connectButton()}
          />
        )}
      </div>
      <div className={styles.right} />
    </div>
  );
};
