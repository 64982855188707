import { httpAgent as http } from './agents/http';

const magicLinksPath = '/api/v3/magic-links';
const magicLinksPublicPath = '/api/v3/public/magic-links';

export const enableOrCreateMagicLink = (type: string, id: number) =>
  http().post(magicLinksPath, { type, id });

export const getMagicLinkById = (type: string, id: number) =>
  http().get<{
    id: number;
    createdAt: Date;
    updatedAt: Date;
    token: string;
    type: 'connection' | 'group' | 'organization' | 'org_and_group';
    isActive: boolean;
    groupId: number | null;
    organizationId: number | null;
    userId: number | null;
  } | null>(`${magicLinksPublicPath}/${type}/${id}`);

export const getMagicLinkByToken = (token: string) =>
  http().get(`${magicLinksPublicPath}/${token}`);

export const disableMagicLink = (token: string) =>
  http().post(`${magicLinksPath}/${token}/disable`);

export const acceptMagicLink = (token: string) =>
  http().post(`${magicLinksPath}/${token}/accept`);
