import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';

import { useUser } from '@/api-queries/app-settings';
import { Loader } from '@/components/common/loader/loader';
import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';
import { useQuery } from 'Hooks/useQuery';
import * as segment from 'Lib/segment';

import {
  clearSignupGoal,
  clearSignupProgram,
  getLocalReferralCode,
  updateIntegrationCandidate,
} from '../sign-up/utils';

export function SigninToken(): React.ReactElement {
  const history = useHistory();
  const { redir, token } = useQuery<{ redir: string; token: string }>();
  const { updateProfile } = useUser();

  const onTokenFailed = () => {
    Notification.create(AppText.notification_modal_sign_in_token_failed, 'error');
    history.push('/sign-in');
  };

  const verifySigninToken = async () => {
    if (!token) {
      onTokenFailed();
      return;
    }

    try {
      const referralCode = getLocalReferralCode();
      const { data: signInUser } = await API.user.tokenSignIn({
        token,
        referralCode,
      });

      clearSignupGoal();
      clearSignupProgram();
      // TODO: Extract post-marlee-signup specific code so this component is a
      // more general-purpose login page
      await API.user.setOnboardingCompleted();
      updateProfile({ ...signInUser, onboardingCompleted: true });

      updateIntegrationCandidate({ status: 'signup' });
      const redirectUrl = '/';

      history.push(redir || redirectUrl);
    } catch (error) {
      console.error('Error using sign-in token', error);
      onTokenFailed();
    }
  };

  useEffect(() => {
    segment.pageView('Sign In', { method: 'Token' });
    verifySigninToken();
  }, []);

  return (
    <div className="login-form__loading text-center">
      <Loader size="md" />
    </div>
  );
}
