import type { Element, Root } from 'hast';
import { findAndReplace } from 'hast-util-find-and-replace';

// import type { Node } from 'unist';
import 'Styles/emoji.scss';

import { getEmojiPath } from './parse';
import { emojiRegexRGI } from './RGI_Emoji';

// Generate the regex for finding emojis
const emojiRegex = emojiRegexRGI();

// Convert an emoji into ast syntax for an image
const renderEmoji = (emoji: string): Element => ({
  type: 'element',
  tagName: 'img',
  properties: {
    draggable: false,
    decoding: 'async',
    alt: emoji,
    src: getEmojiPath(emoji),
  },
  children: [],
});

export const EmojiPlugin = () => {
  return (tree: Root) => {
    findAndReplace(tree, [emojiRegex, renderEmoji]);
  };
};
