import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type MagicLinkType } from '@f4s/types';

import * as API from '../apis';

export function useGetMagicLink(type: MagicLinkType, id: number, autoEnable = false) {
  return useQuery({
    queryKey: ['magicLinks.getMagicLinkById', type, id],
    queryFn: async () => {
      const responseData = await API.magicLinks
        .getMagicLinkById(type, id)
        .then((response) => response.data);

      // If no token returned, create / enable the token and refetch
      if (autoEnable && !responseData?.token) {
        await API.magicLinks.enableOrCreateMagicLink(type, id);
        return await API.magicLinks
          .getMagicLinkById(type, id)
          .then((response) => response.data);
      }

      return responseData;
    },
  });
}

export function useGetMagicLinkByToken(token: string, enabled: boolean = false) {
  return useQuery({
    queryKey: ['magicLinks.getMagicLinkByToken', token],
    queryFn: () =>
      API.magicLinks.getMagicLinkByToken(token).then((response) => response.data),
    enabled,
  });
}

/** Creates a new magic link */
export function useEnableOrCreateMagicLink() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { type: MagicLinkType; id: number }) =>
      API.magicLinks
        .enableOrCreateMagicLink(data.type, data.id)
        .then((response) => response.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['magicLinks.getMagicLinkById']);
      },
    },
  );
}

export const useDisableMagicLink = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (token: string) =>
      API.magicLinks.disableMagicLink(token).then((response) => response.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['magicLinks.getMagicLinkById']);
      },
    },
  );
};

export function useAcceptMagicLink() {
  return useMutation((token: string) => {
    return API.magicLinks.acceptMagicLink(token).then((response) => response.data);
  });
}

export function useInvalidateMagicLink() {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['magicLinks.getMagicLinkById']);
  };
}
