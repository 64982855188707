import React, {
  useEffect,
  useState,
  type ChangeEventHandler,
  type KeyboardEventHandler,
} from 'react';

import { useClickOutside } from 'Hooks/useClickOutside';
import { isNotEmpty, uniqBy, uniqueId } from 'Underscore';

import './input.scss';

type InputProps<TSelectData> = {
  label?: string;
  defaultValue?: string;
  overrideValue?: string;
  autoFocused?: boolean;
  forceFocused?: boolean;
  onfocus?: (isFocused: boolean) => void;
  onkeypress?: KeyboardEventHandler<HTMLInputElement>;
  classes?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  allowFreeText?: boolean;
  separateLabel?: boolean;
} & (
  | {
      type?: 'text' | 'password' | 'email';
      data?: undefined;
      onchange?: (value: string) => void;
    }
  | {
      type: 'select';
      data: TSelectData[];
      onchange?: (option: TSelectData) => void;
    }
);

type BaseSelectData<TValue> = { display: string; value?: TValue; key?: React.Key };

/** @deprecated - Use new UI components */
export function Input<TValue, TSelectData extends BaseSelectData<TValue>>({
  label,
  defaultValue = '',
  overrideValue = '',
  classes = '',
  autoFocused = false,
  forceFocused = false,
  onchange = () => {},
  data,
  type = 'text',
  placeholder = '',
  disabled = false,
  required = false,
  separateLabel = false,
  onfocus = () => {},
  onkeypress = () => {},
  allowFreeText = false,
}: InputProps<TSelectData>) {
  const [focused, setFocused] = useState(
    forceFocused || autoFocused || Boolean(defaultValue),
  );
  const [text, setText] = useState(defaultValue);
  const [openOption, setOpenOption] = useState(false);
  const [optionData, setOptionData] = useState<(TSelectData | BaseSelectData<TValue>)[]>(
    [],
  );
  const [inputType, setInputType] = useState(type);
  const [labelId] = useState(() => uniqueId('input'));

  const onSelectChange = (option) => {
    setOpenOption(false);
    onchange(option.key ?? option);
    setText(option.display);
    setFocused(true);
  };

  const inputContainerRef = useClickOutside(
    {
      onOutside: () => {
        // With allowfreeText automatically save the first option
        // This feature can be enabled on all isSelect, currently limiting scope for hotfix
        if (isSelect && allowFreeText && openOption) {
          onSelectChange(optionData[0]);
        }
        setOpenOption(false);
      },
    },
    [optionData, openOption],
  );

  useEffect(() => {
    if (overrideValue) {
      setText(overrideValue);
      setFocused(true);
    }
  }, [overrideValue]);

  const isSelect = type === 'select' && isNotEmpty(data);
  const toggleShowPassword = () =>
    setInputType(inputType === 'password' ? 'text' : 'password');

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    let { value = '' } = event.target;
    setText(value);

    if (isSelect) {
      // Remove extra whitespace to match option display
      value = value.trim().replaceAll(/\s+/g, ' ');
      const searchResults = data.filter((option) =>
        option.display.toLowerCase().includes(value.toLowerCase()),
      );

      if (allowFreeText && searchResults.length !== 1 && value) {
        // TODO: Change value prop to key, after fixing requirement to not have key to work with landing pages
        setOptionData(
          uniqBy([{ display: value, value: value as TValue }, ...searchResults], 'value'),
        );
        return;
      }

      setOptionData(searchResults);
      return;
    }

    // @ts-expect-error TODO: Remove this component & avoid mixing complex requirements
    onchange(value as string);
  };

  const renderOptions = () => (
    <div className="form_input_options">
      {optionData.map((option) => (
        <button
          type="button"
          // TODO: Restrict option type to always include key
          key={option.key || String(option.value)}
          className="form_input_option"
          onClick={() => onSelectChange(option)}
        >
          {option.display}
        </button>
      ))}
    </div>
  );

  useEffect(() => {
    if (type === 'select') {
      setOptionData(data as TSelectData[]);
    }
    setText(defaultValue);
  }, [JSON.stringify(data)]);

  const element = (
    <div
      className={`form_element_root ${classes}`}
      ref={inputContainerRef}
      onFocus={() => setFocused(true)}
      onBlur={forceFocused ? undefined : () => setFocused(Boolean(text))}
    >
      <input
        className={`form_input focus:ring-0
        ${label && !separateLabel ? 'with-label' : 'without-label'}`}
        id={labelId}
        type={inputType}
        value={text || ''}
        onChange={onInputChange}
        placeholder={placeholder}
        onFocus={() => {
          onfocus(true);
          setOpenOption(isSelect && !disabled);
        }}
        autoFocus={autoFocused}
        disabled={disabled}
        required={required}
        onBlur={(e) => {
          if (e.target.value === '') {
            onfocus(false);
          }
        }}
        onKeyPress={onkeypress}
      />
      {type === 'password' && !!text && (
        <button type="button" className="toggle__password" onClick={toggleShowPassword}>
          {inputType === 'password' ? 'Show' : 'Hide'}
        </button>
      )}
      {openOption && renderOptions()}
      {label && !separateLabel && (
        <label htmlFor={labelId} className={`form_label ${focused ? 'focused' : ''}`}>
          {label}
        </label>
      )}
    </div>
  );

  if (separateLabel)
    return (
      <div className="form_element_wrapper">
        <label htmlFor={labelId}>{label}</label>
        {element}
      </div>
    );

  return element;
}
