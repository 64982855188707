import { number, z } from 'zod';

import { MotivationPatternSchema } from './motivations';

export const CoachingQueueStateSchema = z.enum([
  'queued',
  'started',
  'completed',
  'cancelled',
]);
export type CoachingQueueState = z.infer<typeof CoachingQueueStateSchema>;

export const CoachingQueueItemSchema = z.object({
  id: z.number(),
  programId: z.number(),
  order: z.number(),
  status: CoachingQueueStateSchema,
});
/**
 * Represents an item in the coaching queue
 */
export type CoachingQueueItem = z.infer<typeof CoachingQueueItemSchema>;

export const CreateCoachingQueueItemSchema = CoachingQueueItemSchema.pick({
  programId: true,
}).extend({
  addToTop: z.boolean().default(false),
});
/**
 * Request to add an item to the coaching queue
 */
export type CreateCoachingQueueItemRequest = z.infer<
  typeof CreateCoachingQueueItemSchema
>;

export const UpdateCoachingQueueOrderSchema = CoachingQueueItemSchema.pick({
  id: true,
  order: true,
});
/**
 * Request to update an item's order in the coaching queue
 */
export type UpdateCoachingQueueOrderRequest = z.infer<
  typeof UpdateCoachingQueueOrderSchema
>;

export const CoachingQueueIdSchema = CoachingQueueItemSchema.pick({
  id: true,
});
/**
 * Request to remove an item from the coaching queue
 */
export type RemoveProgramRequest = z.infer<typeof CoachingQueueIdSchema>;

export const CoachingSessionStateSchema = z.enum([
  'future',
  'scheduled',
  'ready',
  'started',
  'complete',
]);
export type CoachingSessionState = z.infer<typeof CoachingSessionStateSchema>;
export type CoachingSessionAvailableState = Extract<
  CoachingSessionState,
  'ready' | 'started'
>;

const CoachingProgramWeeklySessionInfoSchema = z.object({
  week: z.number(),
  session1State: CoachingSessionStateSchema,
  session2State: CoachingSessionStateSchema,
  session1ReadyIn: z.string().optional(),
  session2ReadyIn: z.string().optional(),
});

export type CoachingProgramWeeklySessionInfo = z.infer<
  typeof CoachingProgramWeeklySessionInfoSchema
>;

export const CoachingProgramSessionInfoSchema = z.object({
  programId: z.number(),
  weeks: z.array(CoachingProgramWeeklySessionInfoSchema),
});
/**
 * Represents user-specific session state for a coaching program
 */
export type CoachingProgramSessionInfo = z.infer<typeof CoachingProgramSessionInfoSchema>;

export const CoachingProgramSchema = z.object({
  id: z.number(),
  slug: z.string(),
  name: z.string(),
  motivationCode: MotivationPatternSchema.optional(),
  isEnabled: z.boolean().optional(),
  isNew: z.boolean().optional(),
  colourCode: z.string().optional(),
  type: z.enum(['standard', 'on-demand']),
  numberOfWeeks: z.number(),
  numberOfSessionsPerWeek: z.number(),
  developmentAreas: z.array(
    z.object({
      week: z.number(),
      title: z.string(),
    }),
  ),
  rating: z.number().optional(),
  description: z.string(),
  reviews: z.array(
    z.object({ name: z.string(), avatar: z.string(), comment: z.string() }),
  ),
  outcomes: z.array(z.string()),
  defaultProgramDesigner: z.object({
    title: z.string(),
    fullname: z.string(),
    occupation: z.string(),
    avatar: z.string(),
    introduction: z.string(),
  }),
  whatIsProgramThinking: z.string(),
  whatIsProgramThinkingTitle: z.string(),
});
/**
 * Represents a program configuration
 */
export type CoachingProgram = z.infer<typeof CoachingProgramSchema>;

export const CoachingProgramRecommendationsSchema = z.object({
  mostRecommendedProgramIds: z.array(z.number()),
  otherRecommendedProgramIds: z.array(z.number()),
});

export type CoachingProgramRecommendations = z.infer<
  typeof CoachingProgramRecommendationsSchema
>;

export const CoachingStatusActiveSessionSchema = z.object({
  id: number(),
  week: number(),
  session: number(),
  availableFrom: z.date().optional(),
  status: CoachingSessionStateSchema,
});

export type CoachingStatusActiveSession = z.infer<
  typeof CoachingStatusActiveSessionSchema
>;

export const CoachingStatusActiveProgramSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.string(),
  slug: z.string(),
  status: CoachingQueueStateSchema,
});

export type CoachingStatusActiveProgram = z.infer<
  typeof CoachingStatusActiveProgramSchema
>;

export const CoachingStatusSchema = z.object({
  activeProgram: CoachingStatusActiveProgramSchema,
  activeSession: CoachingStatusActiveSessionSchema,
});
/**
 * Represents coaching program status
 */
export type CoachingStatus = z.infer<typeof CoachingStatusSchema>;

/**
 * Request to set program status in the coaching queue
 */
export const SetProgramStatusSchema = CoachingQueueItemSchema.pick({
  status: true,
});

export const StartProgramSchema = z.object({
  id: z.number(),
});

export type StartProgram = z.infer<typeof StartProgramSchema>;

export type CoachingProgramMap = {
  byId: Record<string, CoachingProgram>;
  allIds: string[];
};

const CoachingNoteTypeSchema = z.enum([
  'action',
  'insight',
  'rating',
  'comment',
  'resource',
]);

export type CoachingNoteType = z.infer<typeof CoachingNoteTypeSchema>;

export const CoachingNoteSchema = z.object({
  id: z.number(),
  type: CoachingNoteTypeSchema,
  data: z.record(z.any()),
  sessionId: z.number(),
});

export type CoachingNote = z.infer<typeof CoachingNoteSchema>;

export type SessionResource = {
  colourCode: string;
  programName: string;
  week: number;
  session: number;
  resource: {
    image: string;
    subtitle: string;
    title: string;
    url: string;
    action: string;
  };
};

export type UserResourcesWithCount = {
  resources: SessionResource[];
  total: number;
};
