import AppText from '@f4s/i18n/en.json';

import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';
import {
  DEFAULT_TEAMS_INSIGHTS_PAGE,
  INVITE_LOCAL_STORAGE_KEY,
  ONBOARDING_LOCAL_STORAGE_KEY,
  ORG_LOCAL_STORAGE_KEY,
  SESSION_STORAGE_CAMPAIGN_CODE,
  SESSION_STORAGE_INTEGRATION_CANDIDATE_TOKEN,
  SESSION_STORAGE_REFERRAL_CODE,
  SESSION_STORAGE_REFERRER_TOKEN,
  SESSION_STORAGE_SIGNUP_GOAL,
  SESSION_STORAGE_SIGNUP_PROGRAM,
} from 'Constants';
import { isEmpty } from 'Underscore';
import { errorHandler } from 'Utils/error-handler';
import { BrowserStorage, getStorageItem } from 'Utils/local-storage';

const storage = BrowserStorage();
const sessionStorage = BrowserStorage('session');

function setIntegrationCandidateToken(token) {
  sessionStorage.setItem(SESSION_STORAGE_INTEGRATION_CANDIDATE_TOKEN, token);
}

function getIntegrationCandidateToken() {
  return sessionStorage.getItem(SESSION_STORAGE_INTEGRATION_CANDIDATE_TOKEN);
}

function clearIntegrationCandidateToken() {
  return sessionStorage.removeItem(SESSION_STORAGE_INTEGRATION_CANDIDATE_TOKEN);
}

function setSignupGoal(goal) {
  sessionStorage.setItem(SESSION_STORAGE_SIGNUP_GOAL, goal);
}

function getSignupGoal() {
  return sessionStorage.getItem(SESSION_STORAGE_SIGNUP_GOAL);
}

function clearSignupGoal() {
  sessionStorage.removeItem(SESSION_STORAGE_SIGNUP_GOAL);
}

function setSignupProgram(program) {
  sessionStorage.setItem(SESSION_STORAGE_SIGNUP_PROGRAM, program);
}

function getSignupProgram() {
  return sessionStorage.getItem(SESSION_STORAGE_SIGNUP_PROGRAM);
}

function clearSignupProgram() {
  sessionStorage.removeItem(SESSION_STORAGE_SIGNUP_PROGRAM);
}

function getLocalReferralCode() {
  return sessionStorage.getItem(SESSION_STORAGE_REFERRAL_CODE);
}

function setCampaignCode(code?: string) {
  if (!code) {
    sessionStorage.removeItem(SESSION_STORAGE_CAMPAIGN_CODE);
    return;
  }
  sessionStorage.setItem(SESSION_STORAGE_CAMPAIGN_CODE, code);
}

function getCampaignCode() {
  return sessionStorage.getItem(SESSION_STORAGE_CAMPAIGN_CODE);
}

function setReferrerToken(token?: string) {
  if (!token) {
    sessionStorage.removeItem(SESSION_STORAGE_REFERRER_TOKEN);
    return;
  }
  sessionStorage.setItem(SESSION_STORAGE_REFERRER_TOKEN, token);
}

function getReferrerToken() {
  return sessionStorage.getItem(SESSION_STORAGE_REFERRER_TOKEN);
}

function getLocalOrgData() {
  return getStorageItem({
    storage,
    key: ORG_LOCAL_STORAGE_KEY,
  });
}

function getStoredOnboardingData() {
  const passedGoal = getSignupGoal();
  const storageValue = getStorageItem({
    storage,
    key: ONBOARDING_LOCAL_STORAGE_KEY,
  }) as { data: { data: any } } | null;
  return {
    ...storageValue?.data?.data,
    passedGoal,
  };
}

function setStoredOnboardingData(data) {
  const dataString = JSON.stringify({ data });
  return storage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, dataString);
}

function clearStoredOnboardingData() {
  storage.removeItem(ONBOARDING_LOCAL_STORAGE_KEY);
}

function getStoredInvitationData() {
  return getStorageItem({
    storage: sessionStorage,
    key: INVITE_LOCAL_STORAGE_KEY,
  }) as Partial<{
    inviteType: string;
    inviteToken: string;
    inviterId: string;
    inviteEmail: string;
  }> | null;
}

function presetOnboardingEmail(email) {
  const onboardingData = getStoredOnboardingData();
  setStoredOnboardingData({
    data: {
      ...onboardingData,
      email,
    },
  });
}

function setLocalInvitationData({
  redirect,
  type,
  token,
  inviter,
}: {
  redirect?: string;
  type: 'organizations' | 'user' | 'groups';
  token: string;
  inviter: { emailAddress: string; inviterId?: number };
}) {
  const data = {
    redirect,
    inviteType: type,
    inviteToken: token,
    inviteEmail: inviter.emailAddress,
    inviterId: inviter.inviterId,
  };
  return sessionStorage.setItem(INVITE_LOCAL_STORAGE_KEY, JSON.stringify(data));
}

/** Accept a group or user invitation */
async function acceptInvitation() {
  const storageValue = getStoredInvitationData();

  if (!storageValue?.inviteType || !storageValue?.inviteToken) return '';

  sessionStorage.removeItem(INVITE_LOCAL_STORAGE_KEY);
  try {
    const { inviteType, inviteToken, inviterId } = storageValue;
    const { data = {} } =
      inviteType === 'groups'
        ? await API.groups.acceptInvite(inviteToken)
        : await API.invitations.acceptInvite(inviteToken);

    const defaultPageRoute = DEFAULT_TEAMS_INSIGHTS_PAGE;

    Notification.create(AppText.user_invite_notification_accepted, 'success');

    return data.groupId
      ? `${defaultPageRoute}?groupId=${data.groupId}`
      : `${defaultPageRoute}?userId=${inviterId}`;
  } catch (error) {
    errorHandler(error, Notification);
    return '/';
  }
}

async function updateIntegrationCandidate(data: {
  token?: string;
  status: 'complete' | 'signup';
}) {
  const token = getIntegrationCandidateToken();

  if (isEmpty(data) || !token) {
    return false;
  }

  if (data.status === 'complete') {
    clearIntegrationCandidateToken();
  }

  return API.integrations.updateCandidate({ token, ...data });
}

const notOther = (x) => x !== 'other';
function makeArrayWithFirst(array, first) {
  if (!first) return array;
  // Filter out items that match first
  const filtered = array.filter(
    (item) => item !== first && (!item.text || item.text !== first.text),
  );
  return [first, ...filtered];
}

function postFocusAndGoals(data) {
  const { subareas = [], subareaFirst, goals = [], goalFirst } = data;
  // Clean 'other' out of subareas and goals
  const cleanSubareas = subareas.filter(notOther);
  const cleanGoals = goals.filter(notOther);
  return API.user
    .setGoals({
      subareas: makeArrayWithFirst(cleanSubareas, subareaFirst),
      goals: makeArrayWithFirst(cleanGoals, goalFirst),
    })
    .catch(errorHandler);
}

async function checkForPendingGoalsAndSave() {
  const storageValue = getStoredOnboardingData();
  if (!storageValue?.data) return;
  // Note: This isn't wrapped in a try-catch so it will end up as an uncaught
  // promise rejection and tracked by sentry
  // An error here will stop localstorage from being cleared so we can try again
  // next time.
  await postFocusAndGoals(storageValue?.data);
  clearStoredOnboardingData();
}

export {
  setIntegrationCandidateToken,
  getLocalReferralCode,
  getSignupProgram,
  getCampaignCode,
  setCampaignCode,
  getReferrerToken,
  setReferrerToken,
  getLocalOrgData,
  getStoredInvitationData,
  setLocalInvitationData,
  presetOnboardingEmail,
  acceptInvitation,
  setSignupProgram,
  clearSignupProgram,
  updateIntegrationCandidate,
  setSignupGoal,
  clearSignupGoal,
  checkForPendingGoalsAndSave,
};
