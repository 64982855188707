import { z, type Schema } from 'zod';

type ObjectName = 'journal';

const journalSchemas = {
  journal_added: z.object({
    setReminders: z.enum(['never', 'daily', 'weekly', 'monthly']),
  }),
  journal_completed: z.object({
    addedEntry: z.number(),
    type: z.enum(['program', 'user']),
  }),
  journal_downloaded: z.object({}),
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { journalSchemas };
