import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as API from 'APIs';

import { useMaybeUser, useUser } from './app-settings';
import { queryFactory } from './util';

export const useDemoGroupQuestionnaires = () =>
  useQuery(['questionnaires.demo.get'], () =>
    API.questionnaires.getDemo().then((res) => {
      return res.data.questionnaires;
    }),
  );

export const useComparisonQuestionnairesInvalidation = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(['comparison.questionnaires.get']);
};

export const useQuestionnairesInvalidation = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(['questionnaires']);
};

export const useMeQuestionnaires = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const { user } = useMaybeUser();

  return useQuery({
    queryKey: ['questionnaires', user?.id],
    queryFn: () =>
      API.questionnaires.getV3({}).then((res) => {
        const completed = res.data.questionnaires.filter((q) => q.isCompleted);
        if (completed.length > 0) return completed;
        return res.data.questionnaires;
      }),
    enabled: enabled && !!user,
  });
};

export const useCurrentMeQuestionnaire = () => {
  const { data, isLoading, isSuccess } = useMeQuestionnaires();
  return { data: data?.[0], isLoading, isSuccess };
};

export const useFetchQuestionnairesV3 = queryFactory(API.questionnaires.getV3, [
  'comparison',
  'comparison.questionnaires.get',
]);

export const useFetchAggregates = queryFactory(API.questionnaires.getAggregates, [
  'comparison',
  'comparison.questionnaires.get.aggregates',
]);

export const useFetchBenchmarks = queryFactory(API.benchmarks.get, [
  'comparison',
  'comparison.get.benchmarks',
]);

export const useShareQuestionnaire = (shareCode: string) => {
  return useQuery({
    queryKey: ['share-questionnaire', shareCode],
    queryFn: () =>
      API.questionnaires.getShareQuestionnaire(shareCode).then((res) => res.data),
  });
};

export const useGetTotalLockedMotivationsCount = () => {
  const { user } = useUser();
  return useQuery({
    queryKey: ['total-locked-motivations-count', user.questionnaireId],
    queryFn: () =>
      API.questionnaires.getTotalLockedMotivationsCount().then((res) => res.data),
  });
};
