import { omit } from 'lodash-es';
import { createContext, useMemo } from 'react';

import {
  type ConnectionByGroup,
  type ConnectionByOrgAndGroup,
  type ConnectionMember,
} from '@f4s/types';
import { type ConnectionByGroupAndOrgAsGroup } from '@f4s/types/common/user';

import { useUser } from '@/api-queries/app-settings';
import { useConnections } from '@/api-queries/connections';

import type { ContextValue } from './types';
import { contextValueFactory } from './util';

type ConnectionsContextValue = {
  connections: ConnectionByOrgAndGroup[];

  users: (ConnectionMember & { orgs: Partial<ConnectionByOrgAndGroup>[] })[];
  groups: ConnectionByGroupAndOrgAsGroup[];
  orgs: ConnectionByOrgAndGroup[];

  usersPersonal: ConnectionMember[];
  groupsPersonal: ConnectionByGroup[];

  groupsMemberOf: (ConnectionByGroup & {
    org: Partial<ConnectionByOrgAndGroup> | null;
  })[];
  groupsAdminOf: (ConnectionByGroup & {
    org: Partial<ConnectionByOrgAndGroup> | null;
  })[];
  orgsMemberOf: ConnectionByOrgAndGroup[];
  orgsAdminOf: ConnectionByOrgAndGroup[];
  canSeeRanking: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

const { initialState, wrapState } =
  contextValueFactory<ConnectionsContextValue>('ConnectionsContext');

export const ConnectionsContext =
  createContext<ContextValue<ConnectionsContextValue>>(initialState);

export const ConnectionsContextWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { user: me, isLoggedIn } = useUser();

  const {
    data: connections,
    isSuccess,
    isLoading,
    isFetching,
  } = useConnections({ enabled: isLoggedIn });

  const contextValue = useMemo(() => {
    if (!isSuccess || !me) {
      return {
        connections: [],
        users: [],
        groups: [],
        orgs: [],
        usersPersonal: [],
        groupsPersonal: [],
        groupsMemberOf: [],
        groupsAdminOf: [],
        orgsMemberOf: [],
        orgsAdminOf: [],
        canSeeRanking: true,
        isLoading,
        isSuccess,
      } as ConnectionsContextValue;
    }

    // Pulls all members out of orgs and groups, flattens it, dedupes it,
    // and ends up with an array of [user, orgConnections[]]
    const usersWithOrgsMap: Record<
      number,
      ConnectionMember & { orgs: Partial<ConnectionByOrgAndGroup>[] }
    > = {};

    const groupsPersonal: ConnectionByGroup[] = [];
    const usersPersonal: ConnectionMember[] = [];

    const groupsWithOrg: (ConnectionByGroup & {
      org: Partial<ConnectionByOrgAndGroup> | null;
    })[] = [];
    const usersWithOrgs: (ConnectionMember & {
      org: Partial<ConnectionByOrgAndGroup> | null;
    })[] = [];
    const orgs: ConnectionByOrgAndGroup[] = [];
    const orgsMemberOf: ConnectionByOrgAndGroup[] = [];
    const orgsAdminOf: ConnectionByOrgAndGroup[] = [];

    connections.forEach((o) => {
      const orgInfo = o.organizationId ? omit(o, 'groups') : null;
      if (o.organizationId) {
        const orgMembers = o.groups.find((g) => g.groupId === null)?.members;
        const meInOrg = orgMembers?.find((m) => m.userId === me.id);

        if (meInOrg) {
          orgsMemberOf.push(o);
          if (meInOrg.isOrgAdmin) {
            orgsAdminOf.push(o);
          }
        }

        orgs.push(o);
      }
      o.groups.forEach((g) => {
        if (!o.organizationId) {
          if (!g.groupId) {
            // me is not filtered at the the server due to connections API is used as permission filtering
            const filteredMembers = g.members.filter((member) => member.userId !== me.id);
            usersPersonal.push(...filteredMembers);
          } else {
            groupsPersonal.push(g);
          }
        }
        groupsWithOrg.push({ ...g, org: orgInfo });
        usersWithOrgs.push(...g.members.map((user) => ({ ...user, org: orgInfo })));
      });
    });

    // We can see ranking if we're not a member of any orgs, or one of the orgs we are a member of has it enabled
    const canSeeRanking =
      orgsMemberOf.length === 0 ||
      orgsMemberOf.some((o) => o.permission.canRankMembers) ||
      orgsAdminOf.some((o) => o.permission.canAdminRankMembers);

    usersWithOrgs.forEach((user) => {
      if (!user.userId) return;
      const orgList = usersWithOrgsMap[user.userId]?.orgs || [];
      if (user.org && !orgList.includes(user.org)) orgList.push(user.org);

      // De-duplicate users and build a list of orgs they are a member of
      usersWithOrgsMap[user.userId] = {
        ...user,
        orgs: orgList,
      };
    });

    const groupsMeAsMember = groupsWithOrg.filter((g) =>
      g.members.some((m) => m.userId === me.id && m.isGroupMember),
    );

    const groupsMemberOf = [
      ...groupsMeAsMember,
      ...groupsWithOrg.filter((g) => g.isDummy),
    ];
    return {
      connections,
      users: Object.values(usersWithOrgsMap),
      groups: groupsWithOrg,
      orgs,
      usersPersonal,
      groupsPersonal,
      groupsMemberOf,
      groupsAdminOf: groupsWithOrg.filter((g) =>
        g.members.some((m) => m.userId === me.id && m.isGroupAdmin),
      ),
      orgsMemberOf,
      orgsAdminOf,
      canSeeRanking,
      isLoading,
      isSuccess,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching, isLoading, Boolean(me)]);

  return (
    <ConnectionsContext.Provider value={wrapState(contextValue)}>
      {children}
    </ConnectionsContext.Provider>
  );
};
