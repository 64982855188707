import { httpAgent as http } from './agents/http';

export const getFocusAreas = (subareas: boolean, goals: boolean) => {
  const includes = [subareas && 'subareas', goals && 'goals'].filter(Boolean).join(',');
  return http().get('/api/v2/content/coach-focus-areas', { params: { includes } });
};

export const getGoals = (area: number, subarea: number) =>
  http().get(`/api/v2/content/coach-focus-areas/${area}/subareas/${subarea}/goals`);

export const getPopularGoals = () =>
  http().get<
    {
      focusSubareaId: number;
      goalId: string;
      id: number;
      linkedProgramIds: number[];
      name: string;
    }[]
  >(`/api/v2/content/goals?popular=10`);
export const getWebChatUrl = () => http().get('/api/v2/content/botpress/webchat-url');
