import { z, type Schema } from 'zod';

type ObjectName = 'payment';

const paymentBaseSchema = z.object({
  paymentValue: z.number(),
  paymentPlan: z.string(),
  userQty: z.number(),
});

const paymentStartedSchema = paymentBaseSchema.extend({
  firstInvoice: z.boolean(),
  billingFrequency: z.string(),
});

const paymentCompletedSchema = paymentBaseSchema.extend({
  monthsActive: z.number(),
  firstInvoice: z.boolean(),
  paymentStatus: z.literal('complete'),
  billingFrequency: z.string(),
});

const paymentRefundedSchema = paymentBaseSchema.extend({
  paymentStatus: z.literal('refunded'),
});

const paymentCancelledSchema = paymentBaseSchema.extend({
  paymentStatus: z.literal('cancelled'),
  monthsActive: z.number(),
});

const paymentSchemas = {
  payment_started: paymentStartedSchema,
  payment_completed: paymentCompletedSchema,
  payment_refunded: paymentRefundedSchema,
  payment_cancelled: paymentCancelledSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { paymentSchemas };
