import {
  createElement,
  forwardRef,
  lazy,
  type ComponentProps,
  type ForwardRefExoticComponent,
  type FunctionComponent,
} from 'react';

/**
 * Function wraps the `React.lazy()` API and adds the ability to preload the component
 * before it is rendered for the first time.
 *
 * @param factory - dynamic import function. ()=>import('./some/path')
 * @param {string} name - name of named export.
 *
 * @example
 * ```tsx
 * import { Suspense } from 'react';
 * import { lazyPreload } from '@/lib/lazy-preload';
 *
 * const LazyComponent = lazyPreload(() => import('./some/file'), 'namedExport');
 *
 * function SomeComponent() {
 *   // Script loading will start even if we are routed to /anotherPath
 *   LazyComponent.preload();
 *
 *   return (
 *     <Suspense fallback="Loading...">
 *        <Switch>
 *
 *          <Route path="/anotherPath">
 *            <AnotherComponent/>
 *          </Route>
 *
 *          <Route path="/lazyPath">
 *            <LazyComponent/>
 *          </Route>
 *
 *     </Suspense>
 *   );
 * }
 * ```
 */
export function lazyPreload<
  TComponent extends FunctionComponent,
  TExport extends { [K2 in TKey]: TComponent },
  TKey extends keyof TExport,
>(factory: () => Promise<TExport>, name: TKey) {
  const LazyComponent = lazy(() =>
    factory().then((module) => ({ default: module[name] })),
  );
  let LoadedComponent: TComponent | undefined;
  let factoryPromise: Promise<TComponent> | undefined;

  const Component = forwardRef<null, ComponentProps<TComponent>>((props, ref) => {
    const component = (LoadedComponent ?? LazyComponent) as FunctionComponent;
    return createElement(component, { ...props, ref } as any);
  }) as ForwardRefExoticComponent<{}> & { preload: () => Promise<TComponent> };

  Component.displayName = 'LazyPreload';

  Component.preload = () => {
    if (!factoryPromise) {
      factoryPromise = factory().then((module) => {
        LoadedComponent = module[name];
        if (!LoadedComponent) throw new Error(`Unable to import ${String(name)}`);
        return LoadedComponent;
      });
    }

    return factoryPromise;
  };

  return Component;
}
