import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as API from 'APIs';

export const useDisconnectUser = () => {
  const client = useQueryClient();
  return useMutation((connectionId: number) => API.user.deleteConnection(connectionId), {
    onSuccess: () => client.invalidateQueries(['connections']),
  });
};

/**
 * refetch a users connections, teams, and organizations
 */
export const useConnectionsInvalidation = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(['connections']);
};

export const useConnections = ({ enabled = false }: { enabled?: boolean } = {}) =>
  useQuery(['connections'], () => API.connections.getAll().then((res) => res.data), {
    enabled,
  });
