import { Label } from '@radix-ui/react-label';
import clsx from 'clsx';
import { type ChangeEvent } from 'react';

import { Checkbox } from '@/components/form_elements/checkbox';

export const RoleTypeToggle = ({
  isDisabled = false,
  label,
  description,
  onChange,
  isChecked,
  isHidden = false,
}: {
  isDisabled?: boolean;
  label: string;
  description: string;
  onChange: (checked: boolean) => void;
  isChecked: boolean;
  isHidden?: boolean;
}) => (
  <fieldset
    disabled={isDisabled}
    className={clsx(
      'rounded p-2',
      isDisabled && 'pointer-events-none bg-gray-100 grayscale',
      isHidden && 'hidden',
    )}
  >
    <Label className="flex justify-between">
      <span className="text-sm font-medium">{label}</span>
      <Checkbox
        darkVariant
        checked={isChecked}
        onchange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.checked)
        }
      />
    </Label>
    <span className="text-sm">{description}</span>
  </fieldset>
);
