import { z, type Schema } from 'zod';

type ObjectName = 'goal';

const goalBaseSchema = z.object({
  goalName: z.string(),
  goalType: z.enum(['preSelected', 'freeText']),
  coachingGoalEnabled: z.boolean().optional(),
});

const goalActionSchema = goalBaseSchema.extend({
  goalAction: z.enum(['added', 'edited', 'deleted']),
});

const goalSchemas = {
  goal_viewed: goalBaseSchema,
  goal_added: goalActionSchema.extend({
    fromSignUpFlow: z.boolean(),
    isMultiple: z.boolean(),
  }),
  goal_edited: goalActionSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { goalSchemas };
