import clsx from 'clsx';
import { type PropsWithChildren, type ReactElement } from 'react';

import { ShareIcon } from '@/icons';

import { Typography } from '../typography/typography';

import styles from './tile.module.scss';

export type TileProp = PropsWithChildren<{
  id?: string;
  color?: string;
  padding?: 'xxs' | 'xs' | 'sm' | 'xl' | 'none';
  mobilePadding?: 'xxs' | 'xs' | 'sm' | 'xl' | 'none';
  justify?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'center';
  variant?: 'left' | 'right' | 'bottom' | 'top' | 'square';
  onClick?: () => void;
  onShare?: () => void;
  footer?: React.ReactElement;
  shadow?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  minHeight?: number;
  height?: 'auto';
  overflow?: 'visible';
}>;

export const Tile = ({
  id,
  color = 'white',
  children,
  onClick,
  onShare,
  footer,
  padding,
  mobilePadding,
  justify,
  alignItems,
  minHeight,
  height,
  variant,
  shadow = 'lg',
  overflow,
}: TileProp): ReactElement => {
  const tileStyles: React.CSSProperties = {};
  // TODO: evaluate if needed. Currently looks like no as colorStyle is always passed as a prop below
  // if (color) tileStyles.backgroundColor = color;
  if (height) tileStyles.height = height;

  const paddingStyle = padding ? styles[`${padding}Padding`] : '';
  const mobilePaddingStyle = mobilePadding
    ? styles[`${mobilePadding}PaddingMobile`]
    : null;
  const marginStyle = padding ? styles[`${padding}Margin`] : 'margin';
  const justifyStyle = justify ? styles[`${justify}Justify`] : '';
  const alignItemsStyle = alignItems ? styles[`${alignItems}AlignItems`] : '';
  const variantStyle = variant ? styles[`${variant}Variant`] : '';
  const overflowStyle = overflow === 'visible' ? styles.overflow : '';
  const shadowStyle = `shadow-${shadow}`;
  const colorStyle = `bg-${color}`;

  const inline: React.CSSProperties = {};
  if (minHeight) inline.minHeight = `${minHeight}px`;

  const props = {
    id,
    className: clsx(styles.tile, variantStyle, shadowStyle, colorStyle, overflowStyle),
    style: tileStyles,
  };

  const bodyContent = (
    <>
      {onShare && (
        <div className={styles.share}>
          <Typography color="gray-500">
            <ShareIcon onClick={onShare} />
          </Typography>
        </div>
      )}
      <div
        className={clsx(
          styles.body,
          paddingStyle,
          mobilePaddingStyle,
          justifyStyle,
          alignItemsStyle,
        )}
        style={inline}
      >
        {children}
      </div>
    </>
  );
  const footerContent = (
    <div className={clsx(styles.footer, paddingStyle, mobilePaddingStyle, marginStyle)}>
      {footer}
    </div>
  );

  return onClick ? (
    <button {...props} onClick={onClick} type="button">
      {bodyContent}
      {footer && footerContent}
    </button>
  ) : (
    <section {...props}>
      {bodyContent}
      {footer && footerContent}
    </section>
  );
};
