import { ChevronDownIcon } from '@heroicons/react/solid';

import { getOptions } from './utils';

type DropdownInputProps = {
  store: string;
  data: { [key: string]: any };
  onChange: (...args: any) => void;
  className: string;
  placeholder: string;
  defaultOption: string;
  optionsVariable?: string;
};

/**
 * @deprecated As much as possible, we want to reduce the use of these flow-form components, and if we want to use something like this in the future, it's probably
 * easier to just plan and build a new set of components. We also abuse the type any quite a bit here, which makes things perilous to continue interating upon
 */
export function DropdownInput({
  store,
  data,
  onChange,
  defaultOption: _defaultOption,
  className = '',
  ...props
}: DropdownInputProps) {
  const rawOptions = getOptions(props, data);

  const options = Array.isArray(rawOptions)
    ? rawOptions.map((value) => [value, value])
    : Object.entries(rawOptions);

  const valueLabel = options.find((o) => o[0] === data[store])?.[1] || props.placeholder;
  return (
    <div
      className={`input dropdown select select-control input-sizer ${className}`}
      data-value={valueLabel}
    >
      <div className="select-icon w-5">
        <ChevronDownIcon className="text-gray-500" />
      </div>
      <select
        value={data[store] || 'blank'}
        onChange={(event) => {
          onChange({
            ...data,
            [store]: event.target.value,
          });
        }}
      >
        <option key="blank" value="blank" disabled>
          {props.placeholder}
        </option>
        {options.map(([opt, label]) => (
          <option key={opt} value={opt}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}
DropdownInput.requiresNext = true;
