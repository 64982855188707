import { z, type Schema } from 'zod';

type ObjectName = 'motivations';

const motivationsBaseSchema = z.object({
  motivationCount: z.number(),
  newMotivationCount: z.number(),
});

const motivationsSchemas = {
  motivations_updated: motivationsBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { motivationsSchemas };
