import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { type Organization, type OrgCreationSearchUser } from '@f4s/types';

import { useMaybeUser } from '@/api-queries/app-settings';
import * as API from '@/apis';
import { uniqBy, upperFirst } from '@/lib/underscore';
import { type Callback } from '@/types';

import type { ContextValue } from './types';
import { contextValueFactory } from './util';

type OrgType = 'space' | 'enterprise';

type OrgCreationContextValue = {
  members: OrgCreationSearchUser[];
  handleMemberChange: Callback<OrgCreationSearchUser[]>;

  imageURL?: string;
  handleImageURLChange: Callback<string>;

  orgName?: string;
  handleOrgNameChange: Callback<string>;

  runFieldVerification: () => Promise<{ valid: true } | { valid: false; error: string }>;
  areFieldsVerified: boolean;

  runSubmitNewOrg: () => Promise<Organization>;

  runSubmitNewWorkspace: () => Promise<Organization>;

  handleRoleChange: (
    newRole: { isAdmin: boolean; isMember: boolean },
    index: number,
  ) => void;

  clear: () => void;

  setOrgType: (type: OrgType) => void;
};

const { initialState, wrapState } =
  contextValueFactory<OrgCreationContextValue>('OrgCreationContext');

export const OrgCreationContext =
  createContext<ContextValue<OrgCreationContextValue>>(initialState);

export const OrgCreationContextWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { isLoggedIn, user } = useMaybeUser();

  const [members, setMembers] = useState<OrgCreationSearchUser[]>([]);

  const [imageURL, setImageURL] = useState<string>();
  const [orgName, setOrgName] = useState<string>();
  const [orgType, setOrgType] = useState<OrgType>();
  const [areFieldsVerified, setAreFieldsVerified] = useState<boolean>(false);

  const typeInEnglish = orgType === 'enterprise' ? 'organization' : 'workspace';

  useEffect(() => {
    if (isLoggedIn && user) {
      setMembers([{ ...user, isAdmin: true, isMember: true }]);
    }
  }, [isLoggedIn, user]);

  const handleRoleChange: OrgCreationContextValue['handleRoleChange'] = (
    newRole,
    index,
  ) => {
    setMembers((existingMembers) => {
      existingMembers[index].isAdmin = newRole.isAdmin;
      existingMembers[index].isMember = newRole.isMember;
      return existingMembers;
    });
  };

  const handleMemberChange: Callback<OrgCreationSearchUser[]> = (updatedMembers) => {
    setAreFieldsVerified(false);

    // Remove duplicate users by userId,
    const filteredMembers = uniqBy(updatedMembers, (member) =>
      !('userId' in member) ? member.emailAddress : member.userId,
    );
    setMembers(filteredMembers);
  };

  const handleImageURLChange: Callback<string> = (newImageURL) => {
    setAreFieldsVerified(false);
    setImageURL(newImageURL);
  };

  const handleOrgNameChange: Callback<string> = (newName) => {
    setAreFieldsVerified(false);
    setOrgName(newName);
  };

  const runFieldVerification = useCallback(async () => {
    const check = await verifyOrgFields(orgName, typeInEnglish);
    setAreFieldsVerified(check.valid);
    return check;
  }, [orgName, typeInEnglish]);

  const clear = useCallback(() => {
    if (isLoggedIn && user) setMembers([{ ...user, isAdmin: true, isMember: true }]);
    setOrgName('');
    setImageURL(undefined);
    setAreFieldsVerified(false);
  }, [isLoggedIn]);

  const runSubmitNewOrg = useCallback(() => {
    if (!orgName) throw new Error(`${upperFirst(typeInEnglish)} missing required fields`);
    return submitNewOrg(orgName, imageURL, members, orgType ?? 'enterprise');
  }, [imageURL, members, orgName, orgType, typeInEnglish]);

  const runSubmitNewWorkspace = useCallback(() => {
    // Differs from runSubmitNewOrg in that we allow no imageURL for 'team/space' type creation
    // TODO We can combine/remove these when all org types no longer require imageURL
    if (!orgName) throw new Error(`${upperFirst(typeInEnglish)} missing required fields`);
    return submitNewOrg(orgName, imageURL || '', members, orgType ?? 'space');
  }, [imageURL, members, orgName, orgType, typeInEnglish]);

  const contextValue = useMemo((): ContextValue<OrgCreationContextValue> => {
    if (!isLoggedIn) return initialState;

    return wrapState({
      members,
      handleMemberChange,
      imageURL,
      handleImageURLChange,
      orgName,
      handleOrgNameChange,
      handleRoleChange,
      runFieldVerification,
      areFieldsVerified,
      runSubmitNewOrg,
      runSubmitNewWorkspace,
      setOrgType,
      clear,
    });
  }, [
    isLoggedIn,
    members,
    imageURL,
    orgName,
    runFieldVerification,
    areFieldsVerified,
    runSubmitNewOrg,
    runSubmitNewWorkspace,
    clear,
  ]);

  return (
    <OrgCreationContext.Provider value={contextValue}>
      {children}
    </OrgCreationContext.Provider>
  );
};

async function verifyOrgFields(
  orgName: string | undefined,
  type: string,
): Promise<{ valid: true } | { valid: false; error: string }> {
  if (!orgName?.trim()) {
    return { valid: false, error: `Please enter the name of your ${type}!` };
  }

  return { valid: true };
}

async function submitNewOrg(
  name: string,
  imageURL: string | undefined,
  members: OrgCreationSearchUser[],
  type: OrgType,
): Promise<Organization> {
  const { data: org } = await API.organizations.create({
    name,
    members,
    logo: imageURL,
    billingEmail: undefined,
    type,
  });
  return org;
}
