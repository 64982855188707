import { z } from 'zod';

import { MotivationPattern } from './motivations';

export type InterpretationItem = {
  'business-builder': string;
  'entrepreneur': string;
  'examples': string | null;
  'meaning': string | null;
};

export type Interpretations = {
  [pattern: string]: InterpretationItem;
};

export type QuestionnaireResponse = {
  questionnaires: Questionnaire[];
  hasBeenLimited: boolean;
  limitQuantity: number;
};

export type AggregateResponse = {
  groupAverages: Motivation[];
  groupResult: {
    affinities: MotivationPattern[];
    cultures: MotivationPattern[];
    differences: [MotivationPattern, MotivationPattern][];
  };
  hasBeenLimited: boolean;
  limitQuantity: number;
  totalQuestionnaires: number;
};

export type Questionnaire = {
  id: number;
  isCompleted: boolean;
  isStarted: boolean;
  isPaid: boolean;
  jobEQUserId: string | null;
  userId: number;
  createdAt: string;
  updatedAt: string;
  completedAt: string;
  isActive: boolean | null;
  has_sponsored: boolean | null;
  sponsored_by: number | null;
  user: QuestionnaireUser;
  motivations: Motivation[];
};

export type QuestionnaireUser = {
  id: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  gender: string | null;
  avatarUrl: string | null;
};

export type JobEqQuestionAnswer = {
  number: number;
  order: string;
};

export const QuestionAnswerSchema = z.object({
  questionNumber: z.number(),
  answerOrder: z.array(z.number()),
});

export type QuestionAnswer = z.infer<typeof QuestionAnswerSchema>;

export type JobEQPerson = {
  email: string;
  firstName: string;
  lastName: string | null;
  gender: string | null;
  workCountry: string;
  isSameCountry: boolean;
  dateOfBirth: string | null;
  occupation: string;
};

export type Motivation = {
  pattern: string;
  score: number | null;
  questionnaireId?: number; // TODO: enforce this type later
  distribution?: [score: number, count: number][];
  relativeDistribution?: [score: number, count: number][];
  quartiles?: [number, number, number];
  relativeQuartiles?: number[];
  ks?: number;
  stddev?: number;
  stddevRatio?: number;
  relative?: number;
  meaning?: string;
  examples?: string;
  size?: number;
  percentile?: number;
  rating?: string;
  aliasName?: string | null;
};

export type MotivationScore = {
  pattern: string;
  score: number;
};

export type QuestionnaireUserMotivation = {
  id: number;
  isCompleted: boolean | null;
  isStarted: boolean | null;
  isPaid: boolean | null;
  jobEQUserId: string | null;
  userId: number;
  createdAt: Date | null;
  updatedAt: Date | null;
  isActive: boolean | null;
  has_sponsored: boolean | null;
  sponsored_by: number | null;
  user: {
    id: number;
    emailAddress: string;
    firstName: string | null;
    lastName: string | null;
    gender: string | null;
    avatarUrl: string | null;
  };
  motivations: Motivation[];
};
