import queryString from 'query-string';

import type { Group, GroupMember, OrgGroup } from '@f4s/types/common/groups';

import { httpAgent as http } from './agents/http';

export const get = (opts: {
  id?: number;
  page?: number;
  limit?: number;
  filter?: {};
}) => {
  const { page = 1, limit, id, filter } = opts;
  const query = queryString.stringify({ page, limit, id, ...filter });
  return http().get(`/api/v1/groups?${query}`);
};

export const getPrivateShareable = () =>
  http().get<OrgGroup[]>(`/api/v1/groups/private/shareable`);

/** Delete when It Just Works P2 Goes live */
export const getInvitation = (token: string) =>
  http().get(`/api/v1/groups/invites?token=${token}`);

/** Delete when It Just Works P2 Goes live */
export const acceptInvite = (token: string) =>
  http().put('/api/v1/groups/invites', {
    status: 'approved',
    token,
  });

export const leave = (userId: number, groupId: number) =>
  http().delete(`/api/v1/groups/${groupId}/${userId}`);

export const updateInvite = (data: { token: string; status: string }) =>
  http().put('/api/v1/groups/members/status', data);

export const shareGroupsInOrg = (data: {
  groups: any[];
  organizationId: number;
  membersInviteToOrg?: GroupMember[];
  isShare: boolean;
}) => http().put('/api/v1/groups/share', data);

export const v3Get = (groupId: number) =>
  http().get<{
    // TODO: Compare and revise with @f4s/types Group which has additional properties
    createdDate: string;
    updatedAt: string;
    deletedAt: string | null;
    description: string;
    id: number;
    imageUrl: string;
    isDummy: boolean;
    isShare: boolean;
    members: GroupMember[];
    name: string;
    // organization
    organizationId: number | null;
    userId: number;
  }>(`/api/v3/groups/${groupId}`);

// @see server/modules/groups/schema.ts
type TempUpdateGroupData = {
  name: string;
  description: string | null;
  members: any[]; // TODO: use type from GroupUpdateMemberSchema
  organizationId?: number;
  imageUrl: string | null;
  isShare: boolean;
};

export const v3Save = (data: TempUpdateGroupData) => http().post('/api/v3/groups', data);

export const v3Update = (data: TempUpdateGroupData, groupId: number) =>
  http().put(`/api/v3/groups/${groupId}`, data);

export const v3Delete = (groupId: number) => http().delete(`/api/v3/groups/${groupId}`);

export const useGetDemoGroupWithUsers = () =>
  http().get<
    Group &
      {
        groupUsers: GroupMember &
          {
            user: {
              id: number;
              firstName?: string;
              lastName?: string;
              avatarUrl?: string;
              emailAddress: string;
            };
          }[];
      }[]
  >('/api/v3/groups/demo-group-users');
