import { createContext, useMemo, useState } from 'react';

import type { ContextValue } from './types';
import { contextValueFactory } from './util';

export type ShareUser = {
  shareUserId: number | null;
  shareUserName: string | null;
  showInvite?: boolean;
};

type InsightShareContextValue = {
  shareUser: ShareUser;
  setShareUser: (data: ShareUser) => void;
};

const { initialState, wrapState } =
  contextValueFactory<InsightShareContextValue>('InsightShareContext');

export const InsightShareContext =
  createContext<ContextValue<InsightShareContextValue>>(initialState);

export const InsightShareContextWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [shareUser, setShareUser] = useState<ShareUser>({
    shareUserId: null,
    shareUserName: null,
    showInvite: false,
  });

  const contextValue = useMemo(
    (): ContextValue<InsightShareContextValue> =>
      wrapState({
        shareUser,
        setShareUser,
      }),
    [shareUser],
  );

  return (
    <InsightShareContext.Provider value={contextValue}>
      {children}
    </InsightShareContext.Provider>
  );
};
