import { useEffect, useState } from 'react';
import { Route, useParams, useRouteMatch } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useGetInvitation } from '@/api-queries/organizations';
import { Loader } from '@/components/common/loader/loader';
import { AuthDashboardIndexV2 } from '@/layouts';
import { useHistorySteps } from '@/lib/hooks/useHistorySteps';
import { SigninForm } from '@/pages/sign-in/sign-in-form';
import { AssessmentPrompt } from '@/pages/sign-up/sign-up-by-invites/form-flow/assessment-prompt';
import { JoinOrg } from '@/pages/sign-up/sign-up-by-invites/form-flow/join-org-step';
import { OrgInviteBanner } from '@/pages/sign-up/sign-up-v2/components/org-invite-banner';
import { AboutYouStep } from '@/pages/sign-up/sign-up-v2/form-flow/about-you-step';
import styles from '@/pages/sign-up/sign-up-v2/style-signup.module.scss';
import { type SignUpDetailTypes } from '@/pages/sign-up/sign-up-v2/types';

const StepNames = ['login', 'about-you', 'join-org', 'assessment-prompt'] as const;
const STEPS: Record<
  (typeof StepNames)[number],
  { displayStep: number | false; displayLogin?: boolean; displayCompleted?: boolean }
> = {
  'login': { displayStep: false, displayLogin: true },
  'about-you': { displayStep: 1 },
  'join-org': { displayStep: 2 },
  'assessment-prompt': { displayStep: 3 },
} as const;

type StepName = (typeof StepNames)[number];

export function FormFlowRoute() {
  const { path } = useRouteMatch();
  return <Route path={`${path}/:step?`} component={FormFlow} />;
}
const getSignUpDetailsFromUser = (user): SignUpDetailTypes => {
  if (!user) {
    return {
      email: '',
      firstName: '',
      lastName: '',
      countryCode: '',
      stateCode: '',
      jobTitle: '',
      jobArea: '',
      goal: 0,
      goalType: [],
      onboardingCompleted: false,
    };
  }

  return {
    email: user.emailAddress,
    firstName: user.firstName,
    lastName: user.lastName,
    countryCode: user.countryCode,
    stateCode: user.stateCode,
    jobArea: user.properties?.jobArea || '',
    jobTitle: user.properties?.jobTitle || '',
    goal: 0,
    goalType: [],
    onboardingCompleted: user.onboardingCompleted || false,
  };
};

function FormFlow(): React.ReactElement {
  const { token } = useParams<{ token: string }>();
  const { isLoggedIn, user } = useMaybeUser();
  const [step, setStep] = useHistorySteps<StepName>('login', 'step', false);
  const userData = getSignUpDetailsFromUser(user);
  const [signUpDetails, setSignUpDetails] = useState<SignUpDetailTypes>(userData);

  const stepIdx = StepNames.indexOf(step);
  const nextStep = () => {
    const next = StepNames[stepIdx + 1];
    setStep(next);
  };
  const { displayStep, displayCompleted = false, displayLogin = false } = STEPS[step];
  const totalSteps = Math.max(...Object.values(STEPS).map((s) => s.displayStep || 0));

  useEffect(() => {
    if (isLoggedIn && user) setSignUpDetails(getSignUpDetailsFromUser(user));
    if (isLoggedIn && step === 'login') {
      if (user?.onboardingCompleted) setStep('join-org');
      else setStep('about-you');
    }
  }, [isLoggedIn, step]);

  const {
    isSuccess,
    data: inviteData,
    isLoading,
    error,
  } = useGetInvitation({
    token,
  });

  if (isLoading) {
    return <Loader />;
  }
  if (!isSuccess) {
    // TODO: error handling
    throw error;
  }
  const handleInputChange = (name: string, value: string | number) => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const stepContainer = () => {
    switch (step) {
      case 'login': {
        if (isLoggedIn) {
          nextStep();
          return null;
        }
        return (
          <SigninForm
            onSuccess={nextStep}
            ssoRedir={`/sign-in/organization/invite/${token}/join-org`}
            signupFlow="2022-org-invite"
            banner={
              <OrgInviteBanner
                org={inviteData?.organization}
                token={token}
                from="sign-in"
              />
            }
          />
        );
      }
      case 'about-you': {
        if (user?.onboardingCompleted) {
          nextStep();
          return null;
        }
        return (
          <AuthDashboardIndexV2
            displayStep={displayStep}
            totalSteps={totalSteps}
            displayLogin={displayLogin}
            displayCompleted={displayCompleted}
          >
            <div className={styles.loginFormRoot}>
              <AboutYouStep
                signUpDetails={signUpDetails}
                handleInputChange={handleInputChange}
                nextStep={nextStep}
              />
            </div>
          </AuthDashboardIndexV2>
        );
      }
      case 'join-org': {
        if (!isLoggedIn) return <Loader size="lg" />;
        return (
          <AuthDashboardIndexV2
            displayStep={displayStep}
            totalSteps={totalSteps}
            displayLogin={displayLogin}
            displayCompleted={displayCompleted}
          >
            <div className={styles.loginFormRoot}>
              <JoinOrg nextStep={nextStep} />
            </div>
          </AuthDashboardIndexV2>
        );
      }
      case 'assessment-prompt': {
        return (
          <AuthDashboardIndexV2
            displayStep={displayStep}
            totalSteps={totalSteps}
            displayLogin={displayLogin}
            displayCompleted={displayCompleted}
          >
            <div className={styles.loginFormRoot}>
              <AssessmentPrompt />
            </div>
          </AuthDashboardIndexV2>
        );
      }
      default: {
        throw new Error('Unreachable: Attempt to render non-existant step ' + step);
      }
    }
  };

  return <>{stepContainer()}</>;
}
