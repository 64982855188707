import { processTemplateString } from '../utils';

type TextInputProps = {
  store: string;
  data: any;
  onChange: (...args: any) => void;
  className: string;
  placeholder: string;
  autoFocus: boolean;
};

/**
 * @deprecated As much as possible, we want to reduce the use of these flow-form components, and if we want to use something like this in the future, it's probably
 * easier to just plan and build a new set of components. We also abuse the type any quite a bit here, which makes things perilous to continue interating upon
 */
export function TextInput({
  store,
  data,
  onChange,
  className = '',
  autoFocus = false,
  ...props
}: TextInputProps) {
  const placeholder = props.placeholder
    ? processTemplateString(props.placeholder, data)
    : null;
  return (
    <div className="input-sizer" data-value={data[store] || placeholder}>
      <input
        className={`input text ${className}`}
        type="text"
        autoFocus={autoFocus}
        placeholder={placeholder}
        value={data[store] || ''}
        onChange={(e) => onChange({ ...data, [store]: e.target.value })}
      />
    </div>
  );
}
TextInput.requiresNext = true;
