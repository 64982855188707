import { FeatureFlagsContextWrapper } from './feature-flags';
import { InsightShareContextWrapper } from './insight-share';
import { LanguageContextWrapper } from './language';
import { OrgCreationContextWrapper } from './org-creation';
import { ReturnToContextWrapper } from './return-to';
import { renderContextStack } from './util';

export const InitialContextStack: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) =>
  renderContextStack(
    [
      FeatureFlagsContextWrapper,
      LanguageContextWrapper,
      ReturnToContextWrapper,
      OrgCreationContextWrapper,
      InsightShareContextWrapper,
    ],
    children,
  );
