import {
  type GroupMember,
  type OrgPermission,
  type Questionnaire,
  type User,
} from '@f4s/types';

import { useUser } from '@/api-queries/app-settings';

import { type CreatableTeam, type SelectableTeamMember } from './types';

export function doesMemberNeedOrgInvite(
  member: SelectableTeamMember,
  orgId: 'personal' | number,
  orgPermission?: OrgPermission,
  isShare?: boolean,
) {
  if (orgId === 'personal') return false;

  if (member.type === 'meOverTime') return false;

  if (orgPermission?.canCompareWithNonMembers === true && !isShare) return false;

  if (member.type === 'email') return true;

  // User is existing / pending member and doesn't need new invite
  if (member.type === 'user' && 'status' in member.user) {
    return false;
  }

  // TODO: Fix type inference on OrgSearchResult[] | Organization[]
  const userOrgIDs = member.user.orgs?.map(
    (org: any) => (org.id || org.organizationId) as number,
  );
  const isMemberOfOrg = userOrgIDs?.includes(orgId);

  return !isMemberOfOrg;
}

export function validateTeam(
  team: CreatableTeam,
  isOrgAdmin: boolean,
  orgPermission?: OrgPermission,
): { valid: true } | { valid: false; error: string } {
  if (team.name.trim().length === 0) {
    return { valid: false, error: 'Team name field is required' };
  }

  const usersNeedingOrgInvites = team.members.filter((member) =>
    doesMemberNeedOrgInvite(member, team.organizationId, orgPermission, team.isShare),
  );

  const hasInvitePermission = isOrgAdmin || orgPermission?.allMembersCanInvite === true;

  if (!hasInvitePermission && usersNeedingOrgInvites.length > 0) {
    return {
      valid: false,
      error:
        'You can only add people from this workspace. If you want to invite someone else, please reach out to your space admin.',
    };
  }

  return { valid: true };
}

export const getTeamMemberKey = (member: SelectableTeamMember) =>
  member.type === 'email'
    ? member.email
    : member.type === 'user'
      ? `${member.user.userId}-${'questionnaireId' in member.user ? member.user.questionnaireId : 'latest'}`
      : `${member.user.userId}-${member.questionnaire.id}`;

export const handleMemberChange = <T extends SelectableTeamMember>(
  onChange: (members: T[]) => void,
  members: T[],
  changedMember: T,
  removeUser = false,
) => {
  const newMembers = [...members];

  const index = newMembers.findIndex((member) => {
    if (member.type !== changedMember.type) return false;

    if (member.type === 'user' && changedMember.type === 'user')
      return member.user.userId === changedMember.user.userId;

    if (member.type === 'email' && changedMember.type === 'email')
      return member.email === changedMember.email;

    if (member.type === 'meOverTime' && changedMember.type === 'meOverTime')
      return member.questionnaire.id === changedMember.questionnaire.id;
  });

  if (removeUser) {
    newMembers.splice(index, 1);
  } else {
    newMembers.splice(index, 1, changedMember);
  }

  onChange(newMembers);
};

export const useIsMe = (member: SelectableTeamMember) => {
  const {
    user: { id: myUserID, questionnaireId: myQuestionnaireId },
  } = useUser();

  const isMe = member.type === 'user' && member.user.userId === myUserID;

  const isMeOverTime =
    member.type === 'meOverTime' ||
    Boolean(
      isMe &&
        'questionnaireId' in member.user &&
        member.user.questionnaireId !== myQuestionnaireId,
    );

  return { isMe, isMeOverTime };
};

export function isMeOverTimeTeamMember(member: GroupMember, user: User) {
  return {
    isMe: member.userId === user.id,
    isMeOverTime: Boolean(
      member.userId === user.id &&
        member.questionnaireId &&
        member.questionnaireId !== user.questionnaireId,
    ),
  };
}

export function isMeOverTimeQuestionnaire(questionnaire: Questionnaire, user: User) {
  return {
    isMe: questionnaire.userId === user.id,
    isMeOverTime: Boolean(
      questionnaire.userId === user.id &&
        questionnaire.id &&
        questionnaire.id !== user.questionnaireId,
    ),
  };
}
