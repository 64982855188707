import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import Axios from 'axios';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';
import { FeatureFlags } from '@f4s/types';

import { useDeclineInvite } from '@/api-queries/invitations';
import { Notification } from 'Components/view/snackbar';

import './reject.scss';

export function RejectInvite() {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const isItJustWorksFlagged = useFlag(FeatureFlags.itJustWorks);
  const { flagsReady } = useFlagsStatus();

  const { mutateAsync: declineInvite, error: declineError, isError } = useDeclineInvite();

  const handleInvite = async () => {
    const inviteData = await declineInvite(token);
    if (isError && Axios.isAxiosError(declineError)) {
      Notification.create(declineError.response?.data.message, 'error');
      history.push('/');
    }
    if (inviteData && inviteData.type === 'connection') {
      Notification.create(AppText.invite_rejected, 'success', { autoDismiss: null });
      history.push('/');
    } else if (inviteData.type === 'group') {
      // TODO Handle groups here when ready
    }
  };

  useEffect(() => {
    if (flagsReady) {
      // to test: decline org invite, does it need to use this function

      handleInvite();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItJustWorksFlagged, flagsReady]);

  return null;
}
