import { z } from 'zod';

import { undefy } from '../utils';

/**
 * NOTE: This doesn't seem to encompass all the invite variants yet
 * see client/pages/teams/pillar/pillar-list-item/pending-invite-item.tsx
 * for some examples, like where there's no type property???
 */
type BaseInvite = {
  title: string;
  profileImageUrl: string;
  invitationDate: string;
  message: string;
  token?: string;
  fromUser?: {
    firstName?: string;
    lastName?: string;
  };
};
export type UserInvite = BaseInvite & {
  type: 'user_invite';
  subtitle: null;
  invite: Invite;
};
export type GroupInvite = BaseInvite & {
  type: 'group_invite';
  subtitle: string;
  group: GroupInviteInfo;
};
export type Invitation = UserInvite | GroupInvite;

type GroupInviteInfo = {
  id: number;
  name: string;
  token: string | null;
  isViewer: boolean;
  isMember: boolean;
  invitedUserId?: number | null;
  invitationId?: number | null;
};
type Invite = {
  id: number;
  invitedUserEmail: string;
  invitedUserName: string | null;
  message: string | null;
  token: string;
  invitedUserId?: number | null;
  hasPaid: boolean;
};

export type SentInvitationData = {
  sentToEmailAddress: string | null;
  token: string;
  updatedAt: Date | null;
};

/** /api/v3/invitations/pending-org-invites */
export type GetPendingOrgInvitesParam = z.infer<typeof GetPendingOrgInvitesParamSchema>;
export const GetPendingOrgInvitesParamSchema = z.object({ orgId: z.coerce.number() });

export type OrgInviteStatus = z.infer<typeof OrgInviteStatusSchema>;
export const OrgInviteStatusSchema = z.enum([
  'approved', // Member of organization
  'removed', // Invitee removed from org by admin
  'request', // Magic link join waiting for approval - disabled auto approve
  'inviting', // Email waiting to be sent / blocked by SSO protections
  'invited', // Email waiting to be opened

  // @deprecated
  // Intermediate states / not helpful for invite business logic
  'pending', // User shown consent screen and pending their acceptance/rejection

  // TODO: Check which one needs to be deprecated
  'rejected', // Invitee rejected invite
  'declined', // '' - same as rejected?
]);
export const OrgInviteStatus = OrgInviteStatusSchema.enum;

export type GetPendingOrgInvitesResponse = z.infer<
  typeof GetPendingOrgInvitesResponseSchema
>;
export const GetPendingOrgInvitesResponseSchema = z
  .discriminatedUnion('userType', [
    z.object({
      type: z.literal('pending-org-invite'),
      status: OrgInviteStatusSchema,
      userType: z.literal('existing'),
      users: z.object({
        id: z.number(),
        firstName: z.string(),
        lastName: undefy(z.string()),
        avatarUrl: undefy(z.string()),
      }),
      email_address: z.string().nullable(),
    }),
    z.object({
      type: z.literal('pending-org-invite'),
      status: OrgInviteStatusSchema,
      userType: z.literal('email'),
      users: z.null(),
      email_address: z.string(),
    }),
  ])
  .array();
