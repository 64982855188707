import type { RefObject } from 'react';

import type { Callback } from 'Types';

type Options = {
  targetRef: RefObject<HTMLElement>;
  onOutside: Callback;
  alsoIgnore?: (HTMLElement | null)[];
};

/** Run a function when clicking outside of the given ref object */
export function ClickOutside({
  targetRef,
  onOutside,
  alsoIgnore = [],
}: Options): Callback {
  const handleClick = (event) => {
    const ignoredElements = [targetRef?.current, ...alsoIgnore];

    // Only run if the ignored elements do not contain the target element
    if (!ignoredElements.some((el) => el?.contains(event.target))) {
      onOutside();
    }
  };

  document.addEventListener('mousedown', handleClick);

  // return function to be called when unmounted
  return () => document.removeEventListener('mousedown', handleClick);
}
