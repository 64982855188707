import Axios from 'axios';

/**
 * @see Axios.isAxiosError
 * Re-export to avoid requiring default import of Axios
 * */
export const { isAxiosError } = Axios;

/** Type Guard to confirm if error has a message value */
export const isErrorWithMessage = (
  error: unknown,
): error is Error & { message: string } => {
  return (
    error instanceof Error && 'message' in error && typeof error.message === 'string'
  );
};
