import {
  Select,
  SelectContent,
  SelectItem,
  SelectPortal,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from '@f4s/ui';

import { type LanguageCode } from '@/constants';
import { useAppContext } from '@/contexts';
import { LanguageContext } from '@/contexts/language';

import { Emoji } from '../rich-text/emoji';

export const LocaleSelector = () => {
  const { setLanguage, language } = useAppContext(LanguageContext);

  return (
    // TODO: Do not hide selector on mobile sizes
    <div className="hidden md:block xl:w-44">
      <Select
        value={language}
        onValueChange={(value) => setLanguage(value as LanguageCode)}
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectPortal>
          <SelectContent>
            <SelectViewport>
              <SelectItem value="en-us">
                <Emoji value="🇦🇺" />
                <span className="ml-2 whitespace-nowrap">English</span>
              </SelectItem>
              <SelectItem value="fr-fr">
                <Emoji value="🇫🇷" />
                <span className="ml-2 whitespace-nowrap">French</span>
              </SelectItem>

              {import.meta.env.MODE !== 'production' && (
                <SelectItem value="en-psaccent">
                  <Emoji value="🎲" />
                  <span className="ml-2 whitespace-nowrap">Pseudo Accent</span>
                </SelectItem>
              )}
            </SelectViewport>
          </SelectContent>
        </SelectPortal>
      </Select>
    </div>
  );
};
