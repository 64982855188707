import { z } from 'zod';

export const languageCodeSchema = z
  .enum(['en-us', 'fr-fr', 'en-psaccent'])
  .default('en-us');

export type LanguageCode = z.infer<typeof languageCodeSchema>;

// Older static assessment language details
export const assessmentLanguageSchema = z
  .enum([
    'ar-ae',
    'zh-cn',
    'cs-cz',
    'da-dk',
    'he-il',
    'id-id',
    'it-it',
    'ja-jp',
    'ko-kr',
    'ms-my',
    'nl-nl',
    'es-ar',
    'sv-se',
    'fr-fr',
    'de-de',
    'pl-pl',
    'pt-pt',
    'ro-ro',
    'ru-ru',
    'en-us',
    'th-th',
    'tr-tr',
  ])
  .catch('en-us');

export type AssessmentLanguageCode = z.infer<typeof assessmentLanguageSchema>;

export const assessmentLanguages = [
  {
    flag: '🇦🇺',
    display: 'English',
    code: 'en-us',
    prompts: ['Most like you', 'Least like you', 'Back', 'Next', 'Finish', 'Language'],
  },
  {
    flag: '🇦🇪',
    display: 'Arabic',
    code: 'ar-ae',
    prompts: ['معظم مثلك', 'على الأقل مثلك', 'عودة', 'التالى', 'إنهاء', 'لغة'],
  },
  {
    flag: '🇨🇳',
    display: 'Chinese',
    code: 'zh-cn',
    prompts: ['最符合你', '最不符合你', '返回', '下一个', '完成', '语言'],
  },
  {
    flag: '🇨🇿',
    display: 'Czech',
    code: 'cs-cz',
    prompts: [
      'Nejvíc se ti líbí',
      'Nejméně jako ty',
      'Zadní',
      'další',
      'Dokončit',
      'Jazyk',
    ],
  },
  {
    flag: '🇩🇰',
    display: 'Danish',
    code: 'da-dk',
    prompts: [
      'Mest ligesom dig',
      'Mindst som dig',
      'Tilbage',
      'Næste',
      'Afslut',
      'Sprog',
    ],
  },
  {
    flag: '🇮🇱',
    display: 'Hebrew',
    code: 'he-il',
    prompts: ['הכי כמוך', 'פחות כמוך', 'חזור', 'הַבָּא', 'סיים', 'שפה'],
  },
  {
    flag: '🇮🇩',
    display: 'Indonesian',
    code: 'id-id',
    prompts: [
      'Paling seperti kamu',
      'Setidaknya sepertimu',
      'Kembali',
      'Lanjut',
      'Selesai',
      'Bahasa',
    ],
  },
  {
    flag: '🇮🇹',
    display: 'Italian',
    code: 'it-it',
    prompts: [
      'Più come te',
      'Almeno come te',
      'Indietro',
      'Avanti',
      'Fine',
      'linguaggio',
    ],
  },
  {
    flag: '🇯🇵',
    display: 'Japanese',
    code: 'ja-jp',
    prompts: [
      'あなたのような',
      'あなたのように少なくとも',
      'バック',
      '次',
      '仕上げ',
      '言語',
    ],
  },
  {
    flag: '🇰🇷',
    display: 'Korean',
    code: 'ko-kr',
    prompts: ['당신처럼', '당신처럼', '뒤', '다음', '끝', '언어'],
  },
  {
    flag: '🇲🇾',
    display: 'Malay',
    code: 'ms-my',
    prompts: [
      'Paling suka anda',
      'Kurang seperti anda',
      'Belakang',
      'Seterusnya',
      'Selesai',
      'Bahasa',
    ],
  },
  {
    flag: '🇳🇱',
    display: 'Dutch',
    code: 'nl-nl',
    prompts: [
      'Het meest op jou',
      'Het minst zoals jij',
      'Terug',
      'De volgende',
      'Af hebben',
      'Taal',
    ],
  },
  {
    flag: '🇪🇸',
    display: 'Spanish',
    code: 'es-ar',
    prompts: [
      'se parece mas a ti',
      'se parece menos a ti',
      'anterior',
      'siguiente',
      'Terminar',
      'Idioma',
    ],
  },
  {
    flag: '🇸🇪',
    display: 'Swedish',
    code: 'sv-se',
    prompts: ['Gillar dig', 'Minst som du', 'Tillbaka', 'Nästa', 'Avsluta', 'Språk'],
  },
  {
    flag: '🇹🇭',
    display: 'Thai',
    code: 'th-th',
    prompts: [
      'ใช่คุณมากที่สุด',
      'ไม่ค่อยเหมือนคุณ',
      'กลับ',
      'ต่อไป',
      'เสร็จสิ้น',
      'ภาษา',
    ],
  },
  {
    flag: '🇫🇷',
    display: 'French',
    code: 'fr-fr',
    prompts: [
      'La plupart comme toi',
      'Le moins comme toi',
      'Arrière',
      'Prochain',
      'terminer',
      'Langue',
    ],
  },
  {
    flag: '🇩🇪',
    display: 'German',
    code: 'de-de',
    prompts: [
      'Am liebsten wie du',
      'Am wenigsten wie du',
      'Zurück',
      'Nächster',
      'Fertig',
      'Sprache',
    ],
  },
  {
    flag: '🇵🇱',
    display: 'Polish',
    code: 'pl-pl',
    prompts: [
      'Najbardziej jak ty',
      'Najmniej jak ty',
      'Plecy',
      'Kolejny',
      'koniec',
      'Język',
    ],
  },
  {
    flag: '🇵🇹',
    display: 'Portuguese',
    code: 'pt-pt',
    prompts: [
      'Mais parecido com você',
      'Menos parecido com você',
      'Costas',
      'Próxima',
      'Terminar',
      'Língua',
    ],
  },
  {
    flag: '🇷🇴',
    display: 'Romanian',
    code: 'ro-ro',
    prompts: [
      'Cel mai mult ca tine',
      'Cel puțin ca tine',
      'Înapoi',
      'Următor',
      'finalizarea',
      'Limba',
    ],
  },
  {
    flag: '🇷🇺',
    display: 'Russian',
    code: 'ru-ru',
    prompts: [
      'Больше всего как ты',
      'Меньше всего как ты',
      'Назад',
      'следующий',
      'финиш',
      'Язык',
    ],
  },
  {
    flag: '🇹🇷',
    display: 'Turkish',
    code: 'tr-tr',
    prompts: ['En çok senin gibi', 'En az senin gibi', 'Geri', 'Sonraki', 'Bitiş', 'Dil'],
  },
] as const satisfies {
  flag: string;
  display: string;
  code: AssessmentLanguageCode;
  prompts: string[];
}[];

export const assessmentLanguageCodes = assessmentLanguages.map((l) => l.code);
