import { z, type Schema } from 'zod';

type ObjectName = 'communication';

const communicationSchema = z.object({
  domainName: z.string(),
  domainMatch: z.boolean(),
  communicationName: z.enum(['matchWithPeople']),
  communicationType: z.enum(['email']),
});

const communicationSchemas = {
  communication_sent: communicationSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { communicationSchemas };
