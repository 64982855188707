import { range } from 'lodash-es';

type DateOption = {
  key: number;
  display: string;
};

type DateObject = {
  year: number;
  month: number;
  day: number;
};

// prettier-ignore
const MONTHS = [
  'Jan', 'Feb', 'March',
  'April', 'May', 'June',
  'July','Aug', 'Sep',
  'Oct', 'Nov', 'Dec'
];

/**
 * Get the total number of days for the given month and year
 * @param {number} [year=0] - Integer representing the gregorian year
 * @param {number} [month] - Integer representing the month from 1 to 12
 * */
export function daysInMonth(year = 0, month?: number): number {
  if (!month) return 31;

  // February has max 29 days
  if (month === 2 && !year) return 29;

  // Date() expects a day index from 1-31
  // Using 0 goes back to last day in the previous month
  // However, Date()'s months are indexed from 0 to 11
  // this means we land on the requested month's last day
  return new Date(year, month, 0).getDate();
}

export function getDayOptions(year = 0, month?: number, ageLimit = 16): DateOption[] {
  const dayArray = Array.from({ length: daysInMonth(year, month) }).map((_, index) => ({
    display: String(index + 1),
    key: index + 1,
  }));

  if (year > 0 && month) {
    const now = new Date();
    const ageThreshold = subtractYears(now, ageLimit);
    // if the month is set and the year is greater than the threshold or
    // the selected year is the same as the threshold and the the month is greater
    // then return an empty array.
    const firstOfSelectedMonth = new Date(year, month, 1);
    if (
      firstOfSelectedMonth.getFullYear() > ageThreshold.getFullYear() ||
      (firstOfSelectedMonth.getFullYear() === ageThreshold.getFullYear() &&
        month > ageThreshold.getMonth() + 1)
    )
      return [];

    // if the year is the selected year and month is the same ans the threshold date then return all days
    // greater than the threshold date for that month
    if (
      firstOfSelectedMonth.getFullYear() === ageThreshold.getFullYear() &&
      month === ageThreshold.getMonth() + 1
    )
      return dayArray.filter((d) => d.key <= ageThreshold.getDate());
  }

  // return the full array of days if we could not filter the list appropriately
  return dayArray;
}

function subtractYears(date: Date, years: number): Date {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export function getMonthOptions(year: number = 0, ageLimit = 16): DateOption[] {
  let month = 0;
  if (year > 0 && new Date().getFullYear() - year === ageLimit) {
    month = subtractYears(new Date(), ageLimit).getMonth();
  }
  return MONTHS.map((display, index) => ({ display, key: index + 1 })).filter(
    (v) => v.key > month,
  );
}

export function getYearOptions(totalYears = 100, ageLimit = 16): DateOption[] {
  const start = new Date().getFullYear() - ageLimit - totalYears;
  return range(start, start + totalYears + 1) // added + 1 as the range method does not include the second value
    .reverse()
    .map((year) => ({ display: String(year), key: year }));
}

/** Utility function for parsing dates of the format `year-month-day` */
export const parseDate = (dateString: string): DateObject => {
  const [year, month, day] = dateString.split('-').map(Number);
  return { year, month, day };
};

// prettier-ignore
/** Utility function for formatting dates as `year-month-day` */
export const formatDateObject = ({ year, month, day }: DateObject): string => [
    year,
    String(month).padStart(2, '0'),
    String(day).padStart(2, '0'),
  ].join('-');
