import { z, type Schema } from 'zod';

type ObjectName = 'talentPool';

const talentPoolBaseSchema = z.object({
  talentPoolLinkedIn: z.string().optional(),
  talentPoolExperience: z.string().optional(),
  talentPoolDirectlyManaged: z.string().optional(),
  talentPoolLastUpdated: z.string().datetime(),
  talentPoolMakeTheMove: z.string().optional(),
  talentPoolHopeToAchieve: z.string().optional(),
  talentPoolCompanySize: z.string().optional(),
  talentPoolWorkType: z.string().optional(),
  talentPoolOtherRoles: z.boolean().optional(),
  talentPoolCompanyCommitments: z.string().optional(),
  talentPoolEmail: z.string(),
});

const talentPoolSchemas = {
  talentPool_submitted: talentPoolBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { talentPoolSchemas };
