import { loadIntercomJs } from 'Utils/js_css_loader';

function bootIntercom({ intercomAppId, user }) {
  window.Intercom('boot', {
    'app_id': intercomAppId,
    'email': user?.emailAddress,
    'user_hash': user?.intercomEmailHash,
    'name': `${user?.firstName} ${user?.lastName}`,
    'Current Plan': user?.plan,
  });
  window.Intercom('onHide', () => window.Intercom('shutdown'));
  window.Intercom('showNewMessage');
}

export function showIntercom({ intercomAppId, user }) {
  if (!window.Intercom) {
    loadIntercomJs(() => bootIntercom({ intercomAppId, user }));
  } else {
    bootIntercom({ intercomAppId, user });
  }
}
