import clsx from 'clsx';

import { MaxWidthContent } from '@/components/common/containers/max-width-content';
import { Column } from '@/components/common/layouts/column/column';
import { Heading } from '@/components/common/typography/heading/heading';
import { Typography } from '@/components/common/typography/typography';

import { ZoomButton } from './components/zoom-button';

import styles from './zoom-page.module.scss';

export const ZoomInstallPage = () => (
  <main className={clsx(styles.page)}>
    <MaxWidthContent padding="md" width800>
      <Column flexOne gap="md">
        <img
          className={styles.logo}
          src="/assets/f4s_logo.svg"
          alt="Fingerprint for Success"
        />{' '}
        <Heading level={4} color="gray-900" textAlign="center">
          Introducing &quot;F4S for Zoom&quot; 🚀
        </Heading>
        <Typography size="tw-lg">Use this button to install our Zoom App: </Typography>
        <ZoomButton />
        <Typography size="tw-lg">
          Or within Zoom, to add the F4S app to your Zoom account please follow these
          steps:
          <ul>
            <li>- Login to your Zoom Account and navigate to the Zoom App Marketplace</li>
            <li>- Search for “F4S for Zoom”</li>
            <li>- Click “Add”</li>
          </ul>
        </Typography>
        <Typography size="tw-lg">
          If you have any questions, please go to{' '}
          <a href="https://www.fingerprintforsuccess.com/help">
            Need Help? | Fingerprint for Success
          </a>
        </Typography>
        <Heading level={4} color="gray-900" textAlign="center">
          Usage
        </Heading>
        <Typography size="tw-lg">
          Once you have added the F4S app to your Zoom account, you will be able to sign
          up or log in to your F4S account. If you are a new F4S user, you will be
          prompted to answer 4 short questions that assess your communication style.
        </Typography>
        <Typography size="tw-lg">
          Once you have taken the assessment, you will see your results within the Zoom
          App interface, and you will be able to update your Zoom account to share your
          communication style with your friends and teammates.
        </Typography>
        <Typography size="tw-lg">
          Once you have added your communication style, you can connect with other meeting
          attendees and see their communication styles in comparison to yours.
        </Typography>
        <Heading level={4} color="gray-900" textAlign="center">
          Removing the app
        </Heading>
        <Typography size="tw-lg">
          To remove the F4S app from your Zoom account:
          <ul>
            <li>
              - Login to your Zoom Account and navigate to the Zoom App Marketplace.
            </li>
            <li>- Click Manage {'>>'} Added Apps or search for the “F4S” app.</li>
            <li>- Click the “F4S” app.</li>
            <li>- Click Remove.</li>
          </ul>
        </Typography>
        <Typography size="tw-lg">
          To delete your F4S account please go to{' '}
          <a href="https://www.fingerprintforsuccess.com/help">
            Need Help? | Fingerprint for Success
          </a>{' '}
          and our Customer Service team will walk you through how to delete your F4S
          account.
        </Typography>
      </Column>
    </MaxWidthContent>
  </main>
);
