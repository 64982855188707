import './divider.scss';

type DividerProps = {
  text?: string;
};

// TODO: remove force-show requirement
export const Divider = ({ text }: DividerProps) => (
  <div className="divider__root">
    <hr className="divider force-show" />
    {text && <div className="divider__text">{text}</div>}
  </div>
);
