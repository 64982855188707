import { Switch } from 'react-router-dom';

import { FormFlowRoute } from './form-flow';

import './style-signup.module.scss';

function SignUpV2Root(): React.ReactElement {
  return (
    <Switch>
      <FormFlowRoute />
    </Switch>
  );
}

export { SignUpV2Root };
