import { cn, Image } from '@f4s/ui';

import { Tile } from '../common/tile/tile';

export const CobrandedBanner = ({
  className,
  isLogin = false,
}: {
  className?: string;
  isLogin?: boolean;
}) => {
  return (
    <div className={cn('flex gap-3 rounded-xl bg-pink-50 p-3', className)}>
      <div className="ring-border/5 flex size-8 shrink-0 items-center rounded-lg bg-white p-1 ring-1">
        <Image customStyle="w-full" src="/assets/marlee-logomark.svg" alt="" />
      </div>
      <p className="text-sm">
        <strong>Fingerprint For Success is becoming Marlee</strong>
        <br />
        {isLogin ? (
          <>
            A whole new way of understanding yourself and your team!
            <a
              href="https://getmarlee.com/app/login"
              className="mt-3 block font-semibold"
            >
              Go to Marlee login -&gt;
            </a>
          </>
        ) : (
          'For now, sign up for F4S and we’ll take care of the rest.'
        )}
      </p>
    </div>
  );
};

export const CobrandedRightContent = ({
  className,
  isLogin = false,
}: {
  className?: string;
  isLogin?: boolean;
}) => {
  return (
    <div
      className={cn(
        'flex h-full flex-col items-center justify-center bg-authority-100',
        className,
      )}
    >
      <div className="max-h-fit max-w-[375px]">
        <Tile padding="xl">
          <div className="flex flex-col gap-6">
            <div className="ring-primary/5 m-auto flex size-16 shrink-0 items-center rounded-lg bg-white p-3 shadow-sm ring-1">
              <Image customStyle="w-full" src="/assets/marlee-logomark.svg" alt="" />
            </div>
            <div className="text-center">
              <p className="text-lg">
                <strong>Fingerprint For Success is becoming Marlee.</strong>
              </p>
              <p className="mt-3 text-sm opacity-60">
                {isLogin ? (
                  <>
                    A whole new way of understanding yourself and your team!
                    <a
                      href="https://getmarlee.com/app/login"
                      className="mt-3 block font-semibold"
                    >
                      Go to Marlee login -&gt;
                    </a>
                  </>
                ) : (
                  'For now, sign up for F4S and we’ll take care of the rest.'
                )}
              </p>
            </div>
          </div>
        </Tile>
      </div>
    </div>
  );
};
