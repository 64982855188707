import * as RScrollArea from '@radix-ui/react-scroll-area';
import clsx from 'clsx';
import React from 'react';

type Props = {
  children: React.ReactNode;
  size?: 'sm' | 'md';
  direction?: 'both' | 'horizontal' | 'vertical';
  flex?: boolean;
  fixedWidth?: boolean;
  flexDirection?: 'flex-col' | 'flex-row';
};

export const ScrollArea = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      size = 'md',
      direction = 'vertical',
      fixedWidth = false,
      flex = false,
      flexDirection,
    },
    ref,
  ) => (
    <RScrollArea.Root
      className={clsx(flex ? 'flex max-h-[100%] flex-1' : 'h-full', flexDirection)}
    >
      <RScrollArea.Viewport
        ref={ref}
        className={clsx(
          flex
            ? 'flex max-h-[100%] flex-1 [&>div]:!flex [&>div]:flex-1'
            : 'h-full w-full',
          fixedWidth && '[&>div]:w-full [&>div]:table-fixed',
          flexDirection,
        )}
      >
        {children}
      </RScrollArea.Viewport>
      {['both', 'horizontal'].includes(direction) && (
        <ScrollBar direction="horizontal" size={size} />
      )}
      {['both', 'vertical'].includes(direction) && (
        <ScrollBar direction="vertical" size={size} />
      )}
      <RScrollArea.Corner className="bg-gray-800" />
    </RScrollArea.Root>
  ),
);

ScrollArea.displayName = 'ScrollArea';

const ScrollBar = ({
  direction,
  size,
}: {
  direction: 'vertical' | 'horizontal';
  size: 'sm' | 'md';
}) => (
  <RScrollArea.Scrollbar
    className={clsx(
      'duration-[160ms] z-10 flex touch-none select-none rounded-[10px] bg-gray-300 bg-opacity-50 p-0.5 transition-colors ease-out hover:bg-opacity-100 data-[orientation=horizontal]:flex-col',
      size === 'sm' &&
        'data-[orientation=horizontal]:h-2 data-[orientation=vertical]:w-2',
      size === 'md' &&
        'data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5',
    )}
    orientation={direction}
  >
    <RScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-gray-400 before:absolute before:left-1/2 before:top-1/2 before:h-full before:w-full before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
  </RScrollArea.Scrollbar>
);
