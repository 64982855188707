import { useEffect, useRef } from 'react';

import { ClickOutside } from 'Utils/click-outside';

// Extract options parameter type from ClickOutside excluding targetRef
type ClickOutsideOptions = Omit<Parameters<typeof ClickOutside>[0], 'targetRef'>;

/** React hook utility for triggering a callback
 * when the user clicks outside of the referenced element */
export function useClickOutside<T extends HTMLElement = HTMLDivElement>(
  options: ClickOutsideOptions,
  dependencies: any[] = [],
) {
  const targetRef = useRef<T>(null);
  useEffect(() => ClickOutside({ ...options, targetRef }), dependencies);
  return targetRef;
}
