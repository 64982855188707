import { UserIcon, UsersIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';

import AppText from '@f4s/i18n/en.json';

import { useAcceptInvite } from '@/api-queries/invitations';
import { useSetUserProperty } from '@/api-queries/users';
import { sendEvent } from '@/apis/analytics';
import { Button } from '@/components/common/button/button';
import { Typography } from '@/components/common/typography/typography';
import { INVITE_LOCAL_STORAGE_KEY } from '@/constants';
import { Notification } from 'Components/view/snackbar';

import { getStoredInvitationData } from '../../utils';
import { type OnboardingStepProps, type OnboardingWhyAreYouHereType } from '../types';
import { Messages } from './why-are-you-here-step.messages';

export const WhyAreYouHereStep = ({
  signUpDetails,
  handleInputChange,
  nextStep,
}: OnboardingStepProps) => {
  const { onboardingWhyAreYouHere } = signUpDetails;
  const setOnboardingWhyAreYouHere = (selection: OnboardingWhyAreYouHereType) => {
    handleInputChange?.('onboardingWhyAreYouHere', selection);
  };
  const { mutateAsync: acceptInvite } = useAcceptInvite();

  useEffect(() => {
    // we handle accepting an invite here now.
    const inviteData = getStoredInvitationData();
    if (inviteData === null) {
      return;
    }
    async function fetchData(invite) {
      // You can await here
      if (
        invite.inviteToken &&
        invite.inviteType &&
        ['groups', 'connection', 'user'].includes(invite.inviteType)
      ) {
        const result = await acceptInvite(invite.inviteToken);
        if (result.status === 'accepted') {
          Notification.create(AppText.user_invite_notification_accepted, 'success');
        }
        sessionStorage.removeItem(INVITE_LOCAL_STORAGE_KEY);
      }
      // ...
    }
    fetchData(inviteData).catch(() => {
      Notification.create(AppText.user_invite_notification_error_generic, 'error');
    });
  }, []);

  const { mutate: setUserProperty } = useSetUserProperty();

  const submitHandler = () => {
    setUserProperty({ property: 'userType', value: onboardingWhyAreYouHere });

    if (onboardingWhyAreYouHere !== undefined && onboardingWhyAreYouHere !== '') {
      sendEvent('signUp_clicked', {
        signUpCategory: onboardingWhyAreYouHere,
      });
    }

    nextStep();
  };

  return (
    <div>
      <h2 className="mb-4 text-3xl font-bold">{Messages.welcomeStepTitle()}</h2>
      <div className="mb-6">
        <Typography size="lg" color="gray-500">
          {Messages.welcomeStepIntro()}
        </Typography>
      </div>
      <div className="flex flex-col md:mb-6 md:flex-row-reverse">
        <button
          type="button"
          onClick={() => setOnboardingWhyAreYouHere('team')}
          className={`border-border/5 mb-4 flex flex-col items-stretch rounded-xl border border-solid p-4 md:mb-0 md:ml-4 ${
            onboardingWhyAreYouHere === 'team'
              ? 'outline outline-4 outline-offset-2 outline-brand-500'
              : ''
          }`}
        >
          <div className="mb-1 flex justify-between">
            <Typography weight="bold" size="tw-lg">
              {Messages.optionForTeamTitle()}
            </Typography>
            <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-brand-50">
              <UsersIcon className="h-5 w-5 fill-brand-500" />
            </div>
          </div>
          <Typography color="gray-500">{Messages.optionForTeamDescription()}</Typography>
        </button>
        <button
          type="button"
          onClick={() => setOnboardingWhyAreYouHere('personal')}
          className={`border-border/5 mb-6 flex flex-col items-stretch rounded-xl border border-solid p-4 md:mb-0 ${
            onboardingWhyAreYouHere === 'personal'
              ? 'outline outline-4 outline-offset-2 outline-brand-500'
              : ''
          }`}
        >
          <div className="mb-1 flex justify-between">
            <Typography weight="bold" size="tw-lg">
              {Messages.optionForPersonalTitle()}
            </Typography>
            <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-brand-50">
              <UserIcon className="h-5 w-5 fill-brand-500" />
            </div>
          </div>
          <Typography color="gray-500">
            {Messages.optionForPersonalDescription()}
          </Typography>
        </button>
      </div>

      <Button
        variant="primary"
        text={Messages.stepNextButton()}
        size="lg"
        onClick={submitHandler}
        isFullWidth
        disabled={onboardingWhyAreYouHere === ''}
      />
    </div>
  );
};
