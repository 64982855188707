import clsx from 'clsx';

// Import required classes for color prop
import 'Styles/colors.scss';

import type { TypographyProps } from './typography.props';

import styles from './typography.module.scss';

export const getTypographyStyles = ({
  weight,
  textAlign = 'left',
  wrap = true,
  size,
  color = 'black',
  ellipsis = false,
}: TypographyProps) =>
  clsx(
    styles[`align-${textAlign}`],
    styles[`weight-${weight}`],
    styles[`size-${size}`],
    ellipsis && styles.ellipsis,
    !wrap && styles.noWrap,
    `color-${color}`,
  );

export const Typography = ({
  children,
  ...props
}: React.PropsWithChildren<TypographyProps>) => (
  <span className={getTypographyStyles(props)}>{children}</span>
);
