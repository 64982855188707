import { sendEvent } from '@/apis/analytics';
import tracking from '@/lib/tracking';

import { Button } from '../common/button/button';

type Props = {
  link: string;
  type: string;
  text: string;
  disabled?: boolean;
  img?: string;
  isSignUp?: boolean;
};

type SignUpConnection = 'google' | 'facebook' | 'linkedin';

export function SocialButton({
  link,
  type,
  text,
  disabled = false,
  img,
  isSignUp = false,
}: Props): React.ReactElement {
  const redirectSocialSignin = () => {
    tracking.clicks.social('Sign In', 'Sign in with', type);
    if (isSignUp) {
      sendEvent('signUp_started', {
        signUpConnection: type as SignUpConnection,
        signUpMarketing: false,
        signUpFlow: 'oauth',
      });
    }

    window.open(link, '_self');
  };

  const imgSrc = img || `/assets/icon-social-${type}.svg`;

  return (
    <Button
      variant="white"
      size="sm"
      frontIcon={<img src={imgSrc} alt="" />}
      text={text}
      disabled={disabled}
      onClick={redirectSocialSignin}
      isFullWidth
    />
  );
}
