import { type FC } from 'react';

import { cn } from '../lib/utils';

const cfImagePrefix = 'https://app.fingerprintforsuccess.com/cdn-cgi/image';

const getCloudflareImageURL = (
  fullURL: string,
  quality: number,
  width?: number,
  height?: number,
) => {
  const options = { f: 'auto', q: quality, w: width, h: height };

  const optionsString = Object.entries(options)
    .filter(([, value]) => Boolean(value))
    .map(([option, value]) => `${option}=${value}`)
    .join(',');

  // Replace double slash in nested url to support Ahrefs
  const nestedURL = fullURL.replace('//', '/');

  // Replace double slash after https in src url
  return `${cfImagePrefix}/${optionsString}/${nestedURL}`;
};

type ImageProps = {
  src: string;
  width?: number;
  height?: number;
  alt: string;
  loading?: 'eager' | 'lazy';
  quality?: number;
  customStyle?: string;
};

export const Image: FC<ImageProps> = ({
  src,
  width,
  height,
  alt,
  loading = 'lazy',
  quality = 80,
  customStyle,
}) => {
  const fullURL = new URL(src, window.location.origin).toString();

  const optimizedSrc = fullURL.startsWith('http://localhost')
    ? src // Cloudflare Images cannot access localhost - disable optimizations
    : getCloudflareImageURL(fullURL, quality, width, height);

  return (
    <img
      className={cn('max-w-full', customStyle)}
      loading={loading}
      src={optimizedSrc}
      alt={alt}
      width={width}
      height={height}
    />
  );
};
