import { Switch } from 'react-router-dom';

import { FormFlowRoute } from './form-flow';

function InviteSignInRoot(): React.ReactElement {
  return (
    <Switch>
      <FormFlowRoute />
    </Switch>
  );
}

export { InviteSignInRoot };
