import { DateTime } from 'luxon';

import type { UserSearchResult } from '@f4s/types';

import { UserProfile } from '@/components/common/profile/user-profile';
import { formatName } from '@/lib/i18n';

import type { SelectableTeamMember, TeamMember } from '../../types';
import { useIsMe } from '../../utils';
import { AvatarPlaceholder } from '../avatar-placeholder';

export const MemberRow = ({
  member,
  controls,
}: {
  member: SelectableTeamMember;
  controls: React.ReactNode;
}) => {
  const { isMe, isMeOverTime } = useIsMe(member);

  return (
    <div className="flex h-[30px] gap-2">
      <div className="flex basis-full items-center gap-2 text-sm text-gray-700">
        {member.type === 'email' && (
          <>
            <AvatarPlaceholder name={member.email} />
            <span>{member.email}</span>
          </>
        )}
        {member.type === 'user' && (
          <>
            <UserProfile user={member.user} size="sm" />
            <span>{getMemberName(member.user, isMe, isMeOverTime)}</span>
          </>
        )}
        {member.type === 'meOverTime' && (
          <>
            <UserProfile user={member.user} size="sm" />
            <span>{getMemberName(member.user, isMe, isMeOverTime)}</span>
            <span className="text-muted-foreground ml-auto text-xs">
              {DateTime.fromISO(member.questionnaire.completedAt).toLocaleString()}
            </span>
          </>
        )}
      </div>
      {controls}
    </div>
  );
};

function getMemberName(
  user: UserSearchResult | TeamMember,
  isMe: boolean,
  isMeOverTime: boolean,
): string {
  const name = formatName('full', user);

  if (isMeOverTime) {
    return `${name} (me over time)`;
  }

  if (isMe) {
    return `${name} (me)`;
  }

  return name;
}
