import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';

import { useInvalidateAppSettings } from '@/api-queries/app-settings';
import { Button } from '@/components/common/button/button';
import { Row } from '@/components/common/layouts/row/row';
import { LinkButton } from '@/components/common/link-button/link-button';
import { Loader } from '@/components/common/loader/loader';
import { Input } from '@/components/form_elements';
import { RichText } from '@/components/rich-text/text';
import { AuthDashboardIndex } from '@/layouts/auth-dashboard-index';
import * as API from 'APIs';
import { PasswordStrength } from 'Components/view/password-strength';
import { Notification } from 'Components/view/snackbar';
import { usePageView } from 'Hooks/usePageView';
import { useQuery } from 'Hooks/useQuery';
import { errorHandler } from 'Utils/error-handler';
import { formSubmit } from 'Utils/form-submit';

export function ResetPassword({ create }: { create: boolean }): React.ReactElement {
  const type = create ? 'Create' : 'Reset';

  usePageView(`Password ${type}`);

  const history = useHistory();
  const { token, grandfathered } = useQuery();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showStrength, setShowStrength] = useState(false);
  const [pwd, resetNewPassword] = useState({
    new: '',
    confirm: '',
  });
  const invalidateAppSettings = useInvalidateAppSettings();

  const getPageTitle = () => {
    if (grandfathered) return 'Migrate my iWAM results';
    if (create) return 'Create a password';
    return 'Reset my password';
  };

  const onPWDChange = (data) => {
    setShowStrength(Boolean(data?.new?.length > 0));
    resetNewPassword({
      ...pwd,
      ...data,
    });
  };

  const verifyToken = useCallback(async () => {
    if (!token) {
      setLoading(false);
      setErrorMessage(
        create
          ? AppText.notification_modal_password_create_error_link_expired
          : AppText.notification_modal_password_reset_error_link_expired,
      );
      return;
    }

    try {
      await API.password.verifyResetToken({ token: String(token) });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An error occurred while verifying your reset token');
      }
    }
    setLoading(false);
  }, [create, token]);

  const resetPassword = (e) =>
    formSubmit(e, async () => {
      if (pwd.new !== pwd.confirm) {
        Notification.create('Password does not match', 'error');
        return;
      }

      try {
        const {
          data: { message },
        } = await API.password.updatePassword({
          token: String(token),
          password: pwd.new,
        });
        Notification.create(message, 'success');
        await invalidateAppSettings();
        history.push('/');
      } catch (error) {
        errorHandler(error, Notification);
      }
    });

  const renderPasswordForm = () => (
    <form className="login-form__root mt-5" onSubmit={resetPassword}>
      {grandfathered && (
        <div className="login-form__subtitle text-center">
          <RichText>{AppText.create_password_grandfather_account_text}</RichText>
        </div>
      )}
      <Input
        label="Password"
        type="password"
        onchange={(value) => onPWDChange({ new: value })}
        forceFocused
        required
      />
      <PasswordStrength password={pwd.new} visible={showStrength} />
      <Input
        label="Confirm Password"
        type="password"
        onchange={(value) => onPWDChange({ confirm: value })}
        forceFocused
        required
      />
      <Button variant="primary" text="Continue" isSubmitBtn isFullWidth />
    </form>
  );

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  return (
    <AuthDashboardIndex pageTitle={getPageTitle()}>
      {!loading && !errorMessage && renderPasswordForm()}
      {!loading && <div className="login-form__subtitle text-center">{errorMessage}</div>}
      {loading && <Loader size="lg" />}
      <Row justify="center" padding="sm">
        <LinkButton to="/sign-in" variant="link" text="Return to sign in" size="sm" />
      </Row>
    </AuthDashboardIndex>
  );
}
