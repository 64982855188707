import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { configureApp } from '@/pages/zoom/util';

import * as API from '../apis';

type AcceptInvitationProp = {
  notificationId: string;
  type: string;
  token: string;
};

export function useZoomPublishableData() {
  return useQuery(['zoom-publishable-data'], () =>
    API.integrations.getZoomPublishableData().then((response) => response.data),
  );
}

export function useZoomVirtualForegroundPosition() {
  return useQuery(['zoom-virtual-foreground-position'], () =>
    API.integrations.getZoomVirtualForegroundPosition(),
  );
}
export function useZoomVirtualForegroundStatus() {
  return useQuery(['zoom-virtual-foreground-status'], () =>
    API.integrations.getZoomVirtualForegroundStatus(),
  );
}

export function useZoomRetrieveParticipantResultsForMeeting(
  userId?: number,
  meetingId?: string,
) {
  return useQuery(
    ['zoom.connected.participants.results', meetingId, userId],
    () =>
      meetingId
        ? API.integrations
            .getZoomParticipantResults(meetingId)
            .then((response) => response.data)
        : API.insights.getConvincerInputs([userId!]).then((response) => response.data),
    { refetchInterval: meetingId ? 10000 : false, enabled: Boolean(userId) },
  );
}

export function useZoomConnectedParticipantsConvincerInputs(userIds: number[]) {
  return useQuery(['zoom.connected.participants.convincerInputs', userIds], () =>
    API.insights.getConvincerInputs(userIds).then((response) => response.data),
  );
}

export const useConvincerInputsInvalidation = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(['zoom.connected.participants.convincerInputs']);
};

export const useAcceptInvitationFromZoom = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ notificationId, token, type }: AcceptInvitationProp) => {
      if (type === 'group_invite') {
        API.groups.updateInvite({ token, status: 'approved' });
      } else if (type === 'org_invite') {
        API.organizations.acceptInvite(token);
      } else {
        API.invitations.updateInvite({
          token,
          status: 'approved',
        });
      }
      return API.notifications.accept(notificationId, 1);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['unAcceptedConnectionNotification']);
        queryClient.invalidateQueries(['zoom.connected.participants.convincerInputs']);
      },
    },
  );
};

export const useZoomAppConfig = () =>
  useQuery(['zoomSdkConfigure'], () => configureApp());

export const useZoomAppConfigInvalidation = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(['zoomSdkConfigure']);
};
