import { Tile } from '@/components/common/tile/tile';
import { Typography } from '@/components/common/typography/typography';

export const SignUpTestimonialCard = () => (
  <div className="flex h-full flex-col items-center justify-center bg-authority-100">
    <div className="max-h-fit max-w-[375px]">
      <Tile
        padding="sm"
        footer={
          <div className="-mb-2 flex">
            <div className="mr-4">
              <img
                width="65"
                alt="Testimony's profile"
                src="/assets/experiments/testimonial-image.png"
              />
            </div>
            <div className="flex flex-col self-center text-gray-900">
              <Typography weight="medium" size="tw-lg">
                Dave Rouse
              </Typography>
              <p className="-mt-1 text-lg font-medium text-gray-500">CEO, Carbon Click</p>
            </div>
          </div>
        }
      >
        <div className="-mb-4">
          <Typography weight="medium" size="tw-lg">
            &quot;F4S has added immense value to our business. We&apos;re expanding it to
            all teams and all incoming team members. We plan to follow this up with a
            workshop once our new team members have found their rhythm.&quot;
          </Typography>
        </div>
      </Tile>
    </div>
  </div>
);
