import { z } from 'zod';

export type CardToken = {
  token: string;
  vaultToken: string;
};

export const BillingDetailsSchema = z.object({
  billingAddress: z
    .object({
      city: z.string(),
      country: z.string(),
      line1: z.string(),
      line2: z.string().optional(),
      postalCode: z.string(),
      state: z.string().optional(),
    })
    .optional(),
  billingEmail: z.string().email().optional(),
});
export type BillingDetails = z.infer<typeof BillingDetailsSchema>;

export type SubscriptionCreationDocument = {
  billingDetails: BillingDetails;
  cardToken: CardToken;
  organization?: { id: number; name: string };
  product: string;
  paymentCycle: SUBSCRIPTIONS_PAYMENT_CYCLE;
  coupon?: string;
};

export const SubscriptionsPaymentCycleSchema = z.enum(['annual', 'monthly']);
export const SUBSCRIPTIONS_PAYMENT_CYCLE = SubscriptionsPaymentCycleSchema.Enum;
export type SUBSCRIPTIONS_PAYMENT_CYCLE = z.infer<typeof SubscriptionsPaymentCycleSchema>;

export const SubscriptionLicenseType = z.enum(['individual', 'member', 'organization']);
export const SUBSCRIPTION_LICENSETYPE = SubscriptionLicenseType.Enum;
export type SUBSCRIPTION_LICENSETYPE = z.infer<typeof SubscriptionLicenseType>;

export const SubscriptionStatusSchema = z.enum([
  'active',
  'cancel',
  'cancel_scheduled',
  'inactive',
]);
export const SUBSCRIPTION_STATUS = SubscriptionStatusSchema.Enum;
export type SUBSCRIPTION_STATUS = z.infer<typeof SubscriptionStatusSchema>;

// All parameters that could be included in subscription
export const SubscriptionCommonSchema = z.object({
  product: z.string(),
  quantity: z.number(),
  status: z.nativeEnum(SUBSCRIPTION_STATUS),
  paymentCycle: SubscriptionsPaymentCycleSchema,
  nextPaymentDateUTCSeconds: z.number().optional(),
  currentPaymentDateUTCSeconds: z.number().optional(),
  licenseType: z.nativeEnum(SUBSCRIPTION_LICENSETYPE),
});

export const SubscriptionSchema = SubscriptionCommonSchema.extend({
  subscriptionExternalId: z.string(),
});

export const SubscriptionResponseSchema = SubscriptionCommonSchema.extend({
  freeUsers: z.number().optional(),
  paidUsers: z.number().optional(),
  users: z
    .object({
      active: z.number(),
      removed: z.number(),
      pending: z.number(),
    })
    .optional(),
});

export type Subscription = z.infer<typeof SubscriptionSchema>;
export type SubscriptionResponse = z.infer<typeof SubscriptionResponseSchema>;

// Contains line items that make up a Subscription Estimate
export const SubscriptionEstimateLineItemSchema = z.object({
  dateFromUTCSeconds: z.number(),
  dateToUTCSeconds: z.number(),
  itemAmount: z.number(),
  totalAmount: z.number(),
  itemQuantity: z.number(),
  description: z.string(),
});

export const SubscriptionEstimateSchema = z.object({
  createdAtUTCSeconds: z.number(),
  currencyCode: z.string(),
  nextBilledAtUTCSeconds: z.number(),
  total: z.number(),
  lineItems: z.array(SubscriptionEstimateLineItemSchema),
});

export type SubscriptionEstimateLineItem = z.infer<
  typeof SubscriptionEstimateLineItemSchema
>;
export type SubscriptionEstimate = z.infer<typeof SubscriptionEstimateSchema>;

export const InvoiceSchema = z.object({
  createdAt: z.string().optional(),
  createdAtUTCSeconds: z.number().optional(),
  currencyCode: z.string(),
  id: z.string(),
  invoiceUrl: z.string().optional(),
  status: z.string(),
  total: z.number(),
  totalActual: z.number(),
  totalEstimated: z.number(),
  nextBilledAtUTCSeconds: z.number(),
  lineItems: z.array(
    z.object({
      description: z.string(),
      dateFromUTCSeconds: z.number(),
      dateToUTCSeconds: z.number(),
      itemAmount: z.number(),
      totalAmount: z.number(),
      itemQuantity: z.number(),
      currencyCode: z.string().optional(),
    }),
  ),
});

export const InvoiceDownloadSchema = z.object({
  id: z.string(),
  downloadUrl: z.string(),
});

export type Invoice = z.infer<typeof InvoiceSchema>;
export type InvoiceDownload = z.infer<typeof InvoiceDownloadSchema>;

export const BillingInvoiceSchema = z.object({
  invoices: z.array(InvoiceSchema),
  estimate: InvoiceSchema,
});

export type BillingInvoices = z.infer<typeof BillingInvoiceSchema>;

/**
 * Holds a list of available features configured in our external payment system
 * org-admin-permissions - tab enabled
 * org-admin-member-roles - tab enabled
 * org-admin-shared-groups - tab enabled
 * org-admin-developers - tab enabled
 */
const featureIds = [
  'create-organization',
  'visible-results-limit',
  'take-assessment',
  'marlee-session',
  'insights-visible',
  'org-admin-permissions',
  'org-admin-member-roles',
  'org-admin-shared-groups',
  'org-admin-developers',
  'permissions',
  'askmarlee-credits-period',
  'askmarlee-credits-qty',
  'askmarlee-trial-credits-qty',
  'askmarlee-trial-credits-period',
  'askmarlee-trial-credits-duration',
  'create-team',
  'organization',
  'add-team-member',
] as const;

export type Feature = (typeof featureIds)[number];

/**
 * Schema to validate the correct data shape of an Entitlement
 */
export const EntitlementSchema = z.object({
  id: z.enum(featureIds),
  isAvailable: z.boolean(),
  quantity: z.number(),
  value: z.boolean().or(z.number()).or(z.array(z.string())).or(z.string()),
  scope: z.object({ organizationIds: z.number().array().optional() }).optional(),
  subscriptionExternalId: z.number().optional(), // Only used in tests
});
export type Entitlement = z.infer<typeof EntitlementSchema>;

/** Plan object schema for API - see server/modules/payments/plans.json */
export type PlanData = z.infer<typeof PlanDataSchema>;
export const PlanDataSchema = z.object({
  id: z.enum(['free', 'pro', 'organization', 'team', 'individual']),
  displayName: z.string(),
  description: z.string(),
  shouldDisplay: z.boolean(),
  pricing: z.object({
    USD: z.object({
      monthly: z.object({ value: z.number(), text: z.string() }).optional(),
      annual: z.object({ value: z.number(), text: z.string() }).optional(),
    }),
  }),
  features: z.string().array(),
});

/**
 * Schema to validate estimate responses from the API
 */
export const EstimateSchema = z.object({
  subTotal: z.number(),
  taxes: z
    .object({
      amount: z.number(),
      description: z.string(),
    })
    .nullable(),
  total: z.number(),
});

/**
 * An estimate calculates subscription pricing that can be displayed to the user before confirming the subscription
 */
export type Estimate = z.infer<typeof EstimateSchema>;

/**
 * Schema to validate an estimate request to the API
 */
export const EstimateRequestSchema = z.object({
  plan: z.string(),
  paymentCycle: z.nativeEnum(SUBSCRIPTIONS_PAYMENT_CYCLE),
  quantity: z.number().optional().default(1),
  coupons: z.string().array().optional(),
  currencyCode: z.string().optional(),
});

/**
 * An estimate request with the details about plan and quantity
 */
export type EstimateRequest = z.infer<typeof EstimateRequestSchema>;

export type PaymentMethod = {
  brand: string;
  card: string;
};

export type EstimatedInvoice = {
  createdAtUTCSeconds: number;
  currencyCode: string;
  nextBilledAtUTCSeconds: number;
  total: number;
  lineItems: Array<{
    dateFromUTCSeconds: number;
    dateToUTCSeconds: number;
    itemAmount: number;
    totalAmount: number;
    itemQuantity: number;
    description: string;
  }>;
};
