import { ExclamationCircleIcon } from '@heroicons/react/solid';

import type { Organization } from '@f4s/types';

import type { CreatableTeamMember, Team } from '../../types';
import { doesMemberNeedOrgInvite } from '../../utils';

export const OrgInviteAlert = ({
  member,
  org,
  orgId,
}: {
  member: CreatableTeamMember;
  org?: Organization;
  orgId: Team['organizationId'];
}) => {
  const memberNeedsOrgInvite = doesMemberNeedOrgInvite(member, orgId, org?.permission);

  if (!memberNeedsOrgInvite) return null;

  return <ExclamationCircleIcon className="w-7 text-yellow-400" />;
};
