import { z } from 'zod';

export type MotivationGroupSlug = z.infer<typeof MotivationGroupSlug>;
export const MotivationGroupSlug = z.enum([
  'action-level',
  'action-direction',
  'authority',
  'task-direction',
  'scope',
  'communication',
  'environment',
  'responsibility',
  'change',
  'work-approach',
  'time',
  'influence',
  'rules',
  'convincer-input',
  'convincer-process',
  'interest-filters',
]);

export type MotivationPattern = z.infer<typeof MotivationPatternSchema>;
export const MotivationPatternSchema = z.enum([
  'Co1',
  'Co2',
  'Co3',
  'Co4',
  'Co5',
  'Co6',
  'Co7',
  'Co8',
  'IF1',
  'IF2',
  'IF3',
  'IF4',
  'IF5',
  'IF6',
  'IF7',
  'IF8',
  'Mo1',
  'Mo2',
  'Mo3',
  'N1',
  'N2',
  'N3',
  'N4',
  'OF1M',
  'OF1P',
  'OF2M',
  'OF2P',
  'OF3M',
  'OF3P',
  'OF4M',
  'OF4P',
  'OF5M',
  'OF5P',
  'OF6M',
  'OF6P',
  'OF7M',
  'OF7P',
  'OF8M',
  'OF8P',
  'So1',
  'So2',
  'So3',
  'TP1',
  'TP2',
  'TP3',
  'WA1',
  'WA2',
  'WA3',
]);

export const MotivationPatterns = Object.values(MotivationPatternSchema._def.values);

export type MotivationSlug = z.infer<typeof MotivationSlug>;
export const MotivationSlug = z.enum([
  'initiation',
  'reflection-and-patience',
  'goal-orientation',
  'away-from-problems',
  'internal-reference',
  'external-reference',
  'alternatives',
  'procedures',
  'breadth',
  'depth',
  'affective-communication',
  'neutral-communication',
  'group-environment',
  'solo-environment',
  'sole-responsibility',
  'shared-responsibility',
  'sameness',
  'evolution',
  'difference',
  'use',
  'concept',
  'structure',
  'past',
  'present',
  'future',
  'power',
  'affiliation',
  'achievement',
  'assertiveness',
  'indifference',
  'compliance',
  'tolerance',
  'seeing',
  'hearing',
  'reading',
  'doing',
  'examples',
  'automatically',
  'consistency',
  'period-of-time',
  'people',
  'tools',
  'systems',
  'information',
  'money',
  'place',
  'time',
  'activity',
]);

const MotivationGroupSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  displayOrder: z.number(),
  questionnaireOrder: z.number(),
  prompt: z.string().optional(),
});
const MotivationDetailSchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
  intervals: z.number(),
  motivationGroupId: z.number(),
  motivationGroup: MotivationGroupSchema,
});
export type MotivationDetail = z.infer<typeof MotivationDetailSchema>;

export const MotivationDetailsSchema = z.array(MotivationDetailSchema);
export type MotivationDetails = z.infer<typeof MotivationDetailsSchema>;
