import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FeatureFlags } from '@f4s/types';

import { useGetMagicLinkByToken } from '@/api-queries/magic-links';
import { useGetReducedUserById } from '@/api-queries/users';
import { user as UserAPI } from 'APIs';
import * as API from 'APIs';
import { setCampaignCode, setReferrerToken } from 'Pages/sign-up/utils';

import { CultureSurvey } from './landing-pages/culture-survey';
import { General } from './landing-pages/general';

export const UserReferralRoot = () => {
  const { token, campaign } = useParams<{ token: string; campaign: string }>();
  const [referrer, setReferrer] = useState<{ name: string }>();
  const [campaignData, setCampaignData] = useState<API.referrals.ReferralProgram>();
  const isItJustWorksFlagged = useFlag(FeatureFlags.itJustWorks);
  const { flagsReady } = useFlagsStatus();
  const { data: magicLink } = useGetMagicLinkByToken(
    token,
    isItJustWorksFlagged && flagsReady,
  );
  const { data: referrerUser } = useGetReducedUserById(magicLink?.userId, !!magicLink);

  useEffect(() => {
    const handleRenderHook = async () => {
      setCampaignCode(campaign);
      setReferrerToken(token);
      try {
        const user = await UserAPI.getPublicData(token);
        setReferrer(user.data);
      } catch (error) {
        console.error('Error getting referrer info', error);
      }
    };

    const handleRenderHookV2 = async () => {
      setCampaignCode(campaign);

      if (referrerUser) {
        setReferrerToken(referrerUser.uuid);
        setReferrer({
          name: referrerUser.firstName,
        });
      }
    };
    if (flagsReady) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isItJustWorksFlagged ? handleRenderHookV2() : handleRenderHook();
    }
  }, [campaign, isItJustWorksFlagged, flagsReady, referrerUser, token]);

  useEffect(() => {
    (async () => {
      const { data } = await API.referrals.getProgram(campaign);
      setCampaignData(data);
    })();
  }, [campaign]);

  if (!campaignData) return null;

  const { landingPage, landingPageData = {} } = campaignData;

  if (landingPage === 'default') return <General referrer={referrer} />;
  if (landingPage === 'culture')
    return (
      <CultureSurvey
        referrer={referrer}
        country={landingPageData.country}
        {...landingPageData}
      />
    );
  return <h1>Not Found</h1>;
};
