import { useQuery } from '@tanstack/react-query';

import { type SelectableTeamMember } from '@/components/invitation-modal/types';
import * as API from 'APIs';

import { useDebounce } from './useDebounce';

const DEBOUNCE_DELAY = 300;

export function useUserSearch({
  query,
  excludeMembers,
  orgId,
}: {
  query: string;
  excludeMembers: SelectableTeamMember[];
  maxResults: number;
  orgId?: number;
}) {
  const [debouncedQuery, isDebouncing] = useDebounce(query, DEBOUNCE_DELAY);
  const isQueryEnabled = query.length > 1;
  const isWaiting = isQueryEnabled && isDebouncing;

  const excludeMemberIds = excludeMembers.map((member) =>
    member.type === 'email' ? member.email : member.user.userId,
  );

  const searchQuery = useQuery(
    ['API.utils.search', debouncedQuery],
    () =>
      API.utils
        .search({
          searchText: debouncedQuery,
          orgId,
        })
        .then((response) => response.data.users),
    { enabled: isQueryEnabled, keepPreviousData: true },
  );

  const results = (searchQuery.data || []).filter(
    (member) => !excludeMemberIds.includes(member.userId),
  );

  const isLoading = searchQuery.isFetching || isWaiting;
  const isNoResults = !isLoading && searchQuery.isFetched && results.length === 0;

  return { results, isLoading, isNoResults };
}
