import clsx from 'clsx';

import { useSetGoalsMutation } from '@/api-queries/users';
import { sendEvent } from '@/apis/analytics';
import { Button } from '@/components/common/button/button';
import { errorHandler } from '@/lib/utils/error-handler';

import { type GoalType } from '../types';
import { Messages } from './goal-step.messages';

import styles from '../style-signup.module.scss';

export const GoalStep = ({
  signUpDetails = {},
  handleMultiSelectValue,
  nextStep,
  selectedGoalType,
  isExtraGoal,
}: GoalType): React.ReactElement => {
  // TODO: renaming will be needed around goals
  // goalType here is actually the goal name and goal is the subarea id
  const { goalType, goal } = signUpDetails;
  const { mutateAsync: setGoalsCall, isLoading: loaderState } = useSetGoalsMutation();

  const setGoalsApiCaller = async () => {
    const baseProperties = {
      signUpGoalCategory: selectedGoalType.name ?? '',
      signUpGoalAchieve: goalType?.[0] ?? '',
    };

    const properties = isExtraGoal
      ? { ...baseProperties, signUpAddAnotherGoal: true }
      : baseProperties;

    sendEvent('signUp_selected', { ...properties });
    try {
      await setGoalsCall({
        goals: goalType,
        subareas: goal,
      });

      if (goalType?.[0]) {
        sendEvent('goal_added', {
          goalAction: 'added',
          fromSignUpFlow: true,
          goalType: 'preSelected',
          isMultiple: goalType.length > 1,
          goalName: goalType[0], // TODO: Send multiple goals to analytics
        });
      }

      nextStep();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <div className={clsx(styles.signUpStepsHeadingBox)}>
        <h2 className={clsx(styles.signUpStepsHeading)}>
          {Messages.goalSubAreaTitle(selectedGoalType?.name)}
        </h2>
      </div>
      <div className={clsx(styles.container, styles.mb20Px)}>
        {selectedGoalType?.goals?.map((option: any) => (
          <div key={option.goalId} className="mr-1.5 mt-3 inline-block">
            <Button
              variant={
                goalType?.includes(option.name) || option.subAreas ? 'secondary' : 'white'
              }
              size="lg"
              text={option.name}
              onClick={() => handleMultiSelectValue('goalType', option.name)}
              disabled={option?.subAreas}
            />
          </div>
        ))}
      </div>
      <div className={clsx(styles.signupBtnWrapper)}>
        <Button
          variant="primary"
          text={Messages.nextStepButton()}
          size="lg"
          isLoading={loaderState}
          disabled={goalType?.length === 0}
          onClick={() => setGoalsApiCaller()}
          isFullWidth
        />
      </div>
    </>
  );
};
