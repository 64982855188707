import clsx from 'clsx';
import { type PropsWithChildren, type ReactElement } from 'react';

import { type FlexProps } from '../layout.props';

import styles from '../layout.module.scss';

export const Column = ({
  wrap,
  gap,
  justify,
  align,
  width,
  maxWidth,
  flexOne,
  height,
  compact,
  children,
  padding,
  mobilePadding,
}: PropsWithChildren<FlexProps>): ReactElement => {
  const justifyContent = justify ? styles[`${justify}Justify`] : '';
  const alignContent = align ? styles[`${align}Align`] : '';
  const wrapContent = wrap ? styles.wrap : '';
  const gapContent = gap ? styles[`${gap}Gap`] : '';
  const paddingContent = padding ? styles[`${padding}Padding`] : '';
  const flexContent = flexOne ? styles.flexOne : '';
  const mobilePaddingContent = mobilePadding
    ? styles[`${mobilePadding}PaddingMobile`]
    : '';

  const inline: React.CSSProperties = {};
  if (width) inline.width = width === 'full' ? '100%' : `${width}px`;
  if (maxWidth) inline.maxWidth = `${maxWidth}px`;
  if (height) inline.height = height === 'full' ? '100%' : `${height}px`;

  return (
    <div
      className={clsx(
        styles.columns,
        alignContent,
        gapContent,
        justifyContent,
        wrapContent,
        paddingContent,
        flexContent,
        mobilePaddingContent,
        compact && styles.compact,
      )}
      style={inline}
    >
      {children}
    </div>
  );
};
