import { useQuery, type QueryKey, type UseQueryOptions } from '@tanstack/react-query';

export const queryFactory =
  <
    TQueryFnData = unknown,
    TFnParams = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
  >(
    queryFn: (params: TFnParams) => Promise<TQueryFnData>,
    queryKey: TQueryKey,
  ) =>
  <SelectType = TData>(
    params: Parameters<typeof queryFn>[0],
    options?: Omit<
      UseQueryOptions<TQueryFnData, TError, SelectType, TQueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery({
      queryFn: () => queryFn(params),
      queryKey: [...queryKey, params] as unknown as typeof queryKey,
      ...options,
    });
  };
