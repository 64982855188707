import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';

import { LOGO } from 'Constants';
import { range } from 'Underscore';

import { Messages } from './auth-dashboard-header.messages';

import styles from './auth-dashboard-header.module.scss';

const dotsStepClassFactory = (dotIndex: number, activeStep: number): string => {
  if (dotIndex < activeStep - 1) return clsx(styles.dot, styles.dotVisited);
  if (dotIndex === activeStep - 1) return clsx(styles.dot, styles.dotActive);
  return styles.dot;
};

type AuthDashboardHeaderProps = {
  displayStep: number | false;
  displayLogin: boolean;
  displaySignup?: boolean;
  displayCompleted: boolean;
  totalSteps: number;
};
export const AuthDashboardHeader = ({
  displayStep,
  totalSteps,
  displayLogin,
  displaySignup,
  displayCompleted,
}: AuthDashboardHeaderProps): React.ReactElement => {
  const allStepNumbers = range(0, totalSteps);
  const params = useParams<{ token: string }>();

  return (
    <div className={clsx(styles.dotsWrapper)}>
      <div className={clsx(styles.pageHeaderLogoWrapper)}>
        <img
          className={clsx(styles.pageHeaderLogo)}
          width="30"
          height="30"
          src={LOGO}
          alt="f4s logo"
        />
      </div>
      <div className={clsx(styles.dotBox)}>
        {displayStep && (
          <>
            <ul className={clsx(styles.dotList)}>
              {allStepNumbers.map((index) => (
                <li key={index} className={dotsStepClassFactory(index, displayStep)} />
              ))}
            </ul>
            <p className={clsx(styles.textBox)}>
              {displayStep} / {totalSteps}
            </p>
          </>
        )}
        {displayLogin && (
          <Link
            to={
              params?.token
                ? `/sign-in/organization/join/${params.token}/login`
                : '/sign-in'
            }
            className={clsx(styles.textBox, styles.cursorPointer)}
          >
            {Messages.signInButton()}
          </Link>
        )}
        {displaySignup && (
          <Link to="/sign-up" className={clsx(styles.textBox, styles.cursorPointer)}>
            {Messages.createAccountButton()}
          </Link>
        )}
        {displayCompleted && <p className={clsx(styles.textBox)}>Completed</p>}
      </div>
    </div>
  );
};
