import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { sendEvent } from '@/apis/analytics';
import { Button } from '@/components/common/button/button';
import { useQuery } from '@/lib/hooks/useQuery';

import { Messages } from './assessment-prompt-step.messages';

import styles from '../style-signup.module.scss';

export const AssessmentPromptStep = (): React.ReactElement => {
  const history = useHistory();
  const { redir } = useQuery<{ redir: string }>();

  return (
    <div>
      <div className={clsx(styles.signUpStepsHeadingBox)}>
        <h2 className={clsx(styles.signUpStepsHeading)}>
          {Messages.assessmentPromptTitle()}
        </h2>
        <p className="about-section-6">{Messages.assessmentPromptDescription()}</p>
        <p className="about-section-6">{Messages.assessmentPromptQuestion()}</p>
      </div>
      <div
        className={clsx(styles.signupBtnWrapper, styles.skipBtnWrapper, styles.mb16Px)}
      >
        <Button
          variant="white"
          text={Messages.skipButton()}
          size="lg"
          onClick={() => {
            sendEvent('signUp_explored', { signUpAssessment: false });
            // Navigate to home and allow rules to send user to final destination
            history.push(redir ?? '/');
          }}
          isFullWidth
        />
      </div>
      <div className={clsx(styles.signupBtnWrapper)}>
        <Button
          variant="primary"
          text={Messages.takeAssessmentButton()}
          size="lg"
          onClick={() => {
            const toQuestionnaire = redir
              ? `/questions/welcome?redir=${redir}`
              : '/questions/welcome';

            history.push(toQuestionnaire);
            sendEvent('signUp_explored', { signUpAssessment: true });
          }}
          isFullWidth
        />
      </div>
    </div>
  );
};
