import {
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type ElementRef,
  type ReactNode,
  type RefObject,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { debounce } from 'Underscore';

import { type ContextValue } from './types';
import { contextValueFactory } from './util';

type ScrollContextValue = {
  setIsScrollNeeded: (isNeeded: boolean) => void;
  resetScroll: () => void;
  setResetToTopIsNeeded: (isResetNeeded: boolean) => void;
  scrollAreaRef: RefObject<ElementRef<'div'>>;
  isScrollToBottom: boolean;
};

const { initialState, wrapState } =
  contextValueFactory<ScrollContextValue>('ScrollContext');

export const ScrollContext =
  createContext<ContextValue<ScrollContextValue>>(initialState);

export const ScrollContextWrapper = ({ children }: { children?: ReactNode }) => {
  const [isScrollNeeded, setIsScrollNeeded] = useState(false);
  const [isScrollToBottom, setIsScrollToBottom] = useState(false);
  const [isResetToTopNeeded, setResetToTopIsNeeded] = useState(true);
  const history = useHistory();

  const scrollAreaRef = useRef<ElementRef<'div'>>(null);
  const pathMap = useRef(new Map<string, number>());
  const { pathname } = useLocation();

  const resetScroll = useCallback(() => {
    if (isScrollNeeded) return;

    if (isResetToTopNeeded) scrollAreaRef.current?.scrollTo(0, 0);
  }, [isScrollNeeded, isResetToTopNeeded]);

  useLayoutEffect(() => {
    resetScroll();
    const currentScrollArea = scrollAreaRef.current;
    if (!isScrollNeeded || !currentScrollArea) return;

    if (history.action !== 'POP') {
      const scrollY = pathMap.current.get(pathname);
      if (scrollY !== undefined) {
        currentScrollArea.scrollTo(0, scrollY);
        setResetToTopIsNeeded(true);
      }
    } else {
      // Set the initial scroll in case no event is triggered
      pathMap.current.set(pathname, currentScrollArea.scrollTop);
    }
    setIsScrollNeeded(false);
  }, [pathname, history.action, scrollAreaRef, resetScroll, isScrollNeeded]);

  useEffect(() => {
    const currentScrollArea = scrollAreaRef.current;
    const onScroll = debounce((e) => {
      const { scrollTop, scrollHeight } = e.target as HTMLElement;
      const { innerHeight } = window;
      const scrollToBottom = scrollTop + innerHeight === scrollHeight;
      setIsScrollToBottom(scrollToBottom);

      pathMap.current?.set(pathname, currentScrollArea?.scrollTop || 0);
    }, 200);

    currentScrollArea?.addEventListener('scroll', onScroll);

    return () => {
      currentScrollArea?.removeEventListener('scroll', onScroll);
    };
  }, [pathname, scrollAreaRef]);

  const contextValue = useMemo(
    (): ContextValue<ScrollContextValue> =>
      wrapState({
        setIsScrollNeeded,
        scrollAreaRef,
        resetScroll,
        setResetToTopIsNeeded,
        isScrollToBottom,
      }),
    [
      setIsScrollNeeded,
      scrollAreaRef,
      resetScroll,
      setResetToTopIsNeeded,
      isScrollToBottom,
    ],
  );

  return <ScrollContext.Provider value={contextValue}>{children}</ScrollContext.Provider>;
};
