import { emptyFunc } from 'Underscore';

const loadedFiles = new Set();
const loadingCallbacks = new Map();

function loadJsFile(filename, callback = emptyFunc) {
  if (loadedFiles.has(filename)) {
    callback();
    return;
  }

  const pending = loadingCallbacks.get(filename);
  if (pending) {
    loadingCallbacks.set(filename, [...pending, callback]);
    return;
  }
  loadingCallbacks.set(filename, [callback]);

  const onLoad = () => {
    loadedFiles.add(filename);
    const allCallbacks = loadingCallbacks.get(filename);
    try {
      allCallbacks.forEach((call) => call());
    } catch (error) {
      console.warn(error);
    }
  };

  const fileRef = document.createElement('script');
  fileRef.type = 'text/javascript';
  fileRef.setAttribute('type', 'text/javascript');
  fileRef.setAttribute('src', filename);
  fileRef.addEventListener('load', onLoad);
  if (typeof fileRef !== 'undefined') {
    document.querySelectorAll('head')[0].append(fileRef);
  }
}

function loadJsFilePromise(filename) {
  return new Promise((resolve) => {
    loadJsFile(filename, () => resolve(true));
  });
}

function loadWistaJS() {
  if (!window.Wistia) {
    loadJsFile('//fast.wistia.com/assets/external/E-v1.js');
  }
}

function loadPasswordEstimatedJs() {
  if (!window.zxcvbn) {
    loadJsFile('/assets/zxcvbn.js');
  }
}

function loadIntercomJs(callback) {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    // @ts-expect-error FIXME: intercomSettings not defined
    ic('update', intercomSettings);
  } else {
    const i = (...args) => {
      i.c(args);
    };
    i.q = [];
    i.c = (...args) => {
      i.q.push(args);
    };
    w.Intercom = i;
    loadJsFile('https://widget.intercom.io/widget/zfsh2kd2', callback);
  }
}

export { loadJsFilePromise, loadWistaJS, loadPasswordEstimatedJs, loadIntercomJs };
