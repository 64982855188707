import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useUser } from '@/api-queries/app-settings';
import { SignupOnboardingIndex } from '@/layouts/signup-onboarding-index';
import { FlowForm } from 'Components/flow-form';
import { Notification } from 'Components/view/snackbar';
import { DEFAULT_PAGE_APP } from 'Constants';
import emailVerifyScript from 'JSONs/member-verification.json';

import { getLocalOrgData } from '../utils';
import { clearStorageAfter } from './join-utils';

type Props = {
  orgName: string;
  reportUser?: object;
  domain?: string;
};

const clientError = ({ orgName, reportUser }: Props) => (
  <div className="modal-content onboard-error">
    <h2>Uh-oh</h2>
    <div>
      Looks like there&#39;s a problem connecting you to {orgName}. If you are seeing this
      for the first time, please click the <strong>Sign out</strong> button and give it
      another try after signing in again. If the problem persists, please{' '}
      <a
        href={`mailto:support@f4s.com?subject=Connection error&body=${JSON.stringify(
          reportUser,
        )}`}
      >
        contact our support team
      </a>{' '}
      and provide the following information:
    </div>
    <textarea defaultValue={JSON.stringify(reportUser)} disabled />
  </div>
);

const invalidEmailError = ({ orgName, domain }: Props) => (
  <div className="modal-content onboard-error">
    <h2>Uh-oh</h2>
    <div>
      Looks like you didn&#39;t use your official {orgName} email account to connect.
      Please use your email ending in <strong>{domain}</strong>. If you believe this to be
      an error, <a href="mailto:support@f4s.com">contact our support team</a> and
      they&#39;ll get you moving again in no time.
    </div>
  </div>
);

const emailUsedError = ({ orgName }: Props) => (
  <div className="modal-content onboard-error">
    <h2>Uh-oh</h2>
    <div>
      Looks like your {orgName} account has already been connected to an F4S account. If
      you believe this to be an error,{' '}
      <a href="mailto:support@f4s.com">contact our support team</a> and they&#39;ll get
      you moving again in no time.
    </div>
  </div>
);

const ERROR_MAPPING = {
  clientInfo: clientError,
  invalidEmail: invalidEmailError,
  emailExists: emailUsedError,
  unauthorized: emailUsedError,
};

export function MemberEmailVerification() {
  const { clientIDP, clientDomain, orgName, orgId } = getLocalOrgData();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { user, signOut } = useUser();

  const { error, redir } = queryString.parse(search);

  const [errorCode, setErrorCode] = useState(String(error));
  const [data, setData] = useState({
    verifyWorkEmail: '',
    brandName: orgName,
  });

  const renderErrorPopup = () => {
    const props = {
      orgName,
      reportUser: {
        uuid: user.uuid,
        name: `${user.firstName} ${user.lastName}`,
      },
      domain: clientDomain,
    };

    return (
      <div className="modal">
        <div className="modal-wrapper">
          <div className="modal-container sm">
            {ERROR_MAPPING[errorCode](props)}
            <div className="modal-footer clearfix">
              <button
                type="button"
                onClick={() => signOut()}
                className="butn action active sm onboard-signout"
              >
                Sign out
              </button>
              <button
                type="button"
                onClick={() => setErrorCode('')}
                className="butn action inactive sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      user.clients.some((client) => client.id === orgId && client.status === 'approved')
    ) {
      Notification.create(`You already have joined ${orgName}`, 'error');
      clearStorageAfter();
      history.push(String(redir) || DEFAULT_PAGE_APP);
      return;
    }

    if (!errorCode) return;
    history.replace(`${pathname}?redir=${redir}`);
  }, []);

  const onStepChange = () => {
    if (data.verifyWorkEmail === 'no') {
      signOut();
      return;
    }
    const query = {
      clientId: orgId,
      clientDomain,
      redir: encodeURIComponent(`/brand-join/client/permission?redir=${redir}`),
      errorRedir: encodeURIComponent(`${pathname}${search}`),
    };
    window.location.href = `/auth/verify-client/${clientIDP}?${queryString.stringify(
      query,
    )}`;
  };

  return (
    <>
      {errorCode && renderErrorPopup()}
      <SignupOnboardingIndex>
        <FlowForm
          script={emailVerifyScript}
          data={data}
          onDataChange={(v) => {
            setData(v);
          }}
          onStepChange={onStepChange}
        />
      </SignupOnboardingIndex>
    </>
  );
}
