import { z, type Schema } from 'zod';

type ObjectName = 'coaching';

const coachingBaseSchema = z.object({
  coachingProgram: z.string(), // TODO: could be an enum of our available coaching program names
});

const coachingActionBaseSchema = coachingBaseSchema.extend({
  coachingAction: z.enum(['addedToList', 'started', 'completed', 'cancelled']),
});

const coachingSessionStartedSchema = coachingActionBaseSchema.extend({
  coachingStartTime: z.date(),
});

const coachingSessionFinishedSchema = coachingActionBaseSchema.extend({
  coachingFinishTime: z.date(),
});

const coachingProgramSwitchSchema = coachingActionBaseSchema.extend({
  coachingSwitchTime: z.date(),
});

const coachingHumanProgramActionSchema = z.object({
  humanProgram: z.enum(['bookFreeTeamAnalysisSession', 'bookFreeIntroduction']),
});

const coachingSchemas = {
  coaching_viewed: coachingBaseSchema,
  coaching_added: coachingActionBaseSchema,
  coaching_sessionStarted: coachingSessionStartedSchema,
  coaching_sessionFinished: coachingSessionFinishedSchema,
  coaching_completed: coachingActionBaseSchema,
  coaching_switched: coachingProgramSwitchSchema,
  coaching_clicked: coachingHumanProgramActionSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { coachingSchemas };
