type Props = {
  name: string;
};

/**
 * Small placeholder avatar for users/workspaces without an image
 * Takes first letter from given name
 * */
export const AvatarPlaceholder = ({ name }: Props) => (
  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 font-medium uppercase leading-none">
    {name[0] || '?'}
  </div>
);
