import { Redirect, useHistory } from 'react-router-dom';

import { useUser } from '@/api-queries/app-settings';
import { Button } from '@/components/common/button/button';
import { Typography } from '@/components/common/typography/typography';
import { ReturnToContext, useAppContext } from '@/contexts';

import { Messages } from './assessment-prompt.messages';

import styles from '../style-sign-up-by-invites.module.scss';

export const AssessmentPrompt = () => {
  const { user } = useUser();
  const history = useHistory();
  const { returnURL } = useAppContext(ReturnToContext);

  if (user?.questionnaires.length >= 1) {
    // Can't use goToReturnURL as it makes the component return void
    if (returnURL) {
      return <Redirect to={returnURL} />;
    }
    return <Redirect to="/me" />;
  }

  const startAssessment = () => {
    history.push('/questions/welcome');
  };
  return (
    <div>
      <div className={styles.signUpStepsHeadingBox}>
        <h2 className={styles.signUpStepsHeading}>{Messages.assessmentPromptTitle()}</h2>
        <p className={styles.signUpHeadingDesc}>
          <Typography color="gray-500">
            {Messages.assessmentPromptDescription()}
          </Typography>
        </p>
      </div>

      <div className={styles.signupBtnWrapper}>
        <Button
          variant="primary"
          text={Messages.startAssessmentButton()}
          size="lg"
          onClick={startAssessment}
          isFullWidth
        />
      </div>
    </div>
  );
};
