import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';

import { useSignUpMutation } from '@/api-queries/users';
import { Button } from '@/components/common/button/button';
import { Typography } from '@/components/common/typography/typography';
import * as segment from 'Lib/segment';

import { type WelcomeStepType } from '../types';

import styles from '../style-sign-up-by-invites.module.scss';

export const WelcomeStep = ({
  nextStep,
  orgName,
  emailAddress,
  signupFlow,
  banner,
}: WelcomeStepType): React.ReactElement => {
  const { mutateAsync: registerCall, isLoading: registerLoading } = useSignUpMutation();
  const registerCallHandler = async () => {
    await registerCall({ emailAddress, signupFlow });
    nextStep();
  };
  useEffect(() => {
    segment.pageView('Organization Sign Up Welcome', { orgName });
  }, [orgName]);
  return (
    <>
      <div className={styles.signUpStepsHeadingBox}>
        {banner}
        <h2 className={styles.signUpStepsHeading}>Welcome to F4S!</h2>
        <p className={styles.signUpHeadingDesc}>
          <Typography color="gray-500">
            Take a few minutes and we’ll get you set up and understanding yourself right
            away!
          </Typography>
        </p>
        <p className={styles.signUpHeadingDesc}>
          <Typography color="gray-500">
            By continuing, you’ll be creating an account for the email{' '}
            <span className={styles.userEmail}>{emailAddress}</span>
          </Typography>
        </p>
      </div>

      <div className={styles.signupBtnWrapper}>
        <Button
          variant="primary"
          text="Next"
          size="lg"
          onClick={() => registerCallHandler()}
          isLoading={registerLoading}
          isFullWidth
        />
      </div>

      <div className={styles.textForPrivacyTerms}>
        {AppText.sign_up}
        <Link
          className={styles.linkTerms}
          target="_blank"
          to={{
            pathname: 'https://www.fingerprintforsuccess.com/terms-and-conditions',
          }}
        >
          Terms and Conditions
        </Link>
        and
        <Link
          className={styles.linkPolicy}
          target="_blank"
          to={{ pathname: 'https://www.fingerprintforsuccess.com/privacy-policy' }}
        >
          Privacy Policy
        </Link>
      </div>
    </>
  );
};
