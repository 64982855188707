import { z, type Schema } from 'zod';

type ObjectName = 'invite';

export const inviteDisplaySchema = z
  .enum(['modal', 'fullPage', 'dropdown', 'calender'])
  .optional();

export type InviteDisplay = z.infer<typeof inviteDisplaySchema>;

export const inviteSourceSchema = z
  .enum([
    'topButton',
    'orgSetting',
    'zoom',
    'debrief',
    'askMarlee',
    'setupGuide',
    'emptyState',
    'rightPillar',
    'searchResult',
    'chromeExtension',
    'sharedInsight',
    'sharedReport',
    'sharedDashboard',
    'url',
    'onboarding',
    'slack',
    'noCredits',
    'publicProfile',
  ])
  .optional();

export type InviteSource = z.infer<typeof inviteSourceSchema>;

const inviteBaseSchema = z.object({
  inviteType: z.string(), // types ['connection', 'workspace', 'team', 'org_and_group', 'request_org'] // TODO: extend with other options
  inviteTargetUserType: z.enum(['existing', 'new']).optional(),
  inviteTargetUserId: z.number().optional(),
  inviteTargetEmail: z.string().optional(),
  inviteDisplay: inviteDisplaySchema, // TODO: extend with other options
  inviteSource: inviteSourceSchema, // TODO: extend with other options
});
const inviteUserSchema = inviteBaseSchema.extend({ inviteUserId: z.number().optional() });

const inviteSchemas = {
  invite_clicked: inviteBaseSchema,
  invite_sent: inviteUserSchema,
  invite_accepted: inviteUserSchema,
  invite_cancelled: inviteUserSchema,
  invite_rejected: inviteUserSchema,
  invite_viewed: inviteUserSchema,
  invite_linkCopied: inviteBaseSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { inviteSchemas };
