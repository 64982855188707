import { z } from 'zod';

export type ResearchStudy = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  isPublished: boolean;
  publishedAt: Date | null;
  name: string;
  slug: string;
  studyText: string;
};

export type ResearchQuestionType = 'selection' | 'boolean' | 'scale';

export type ResearchQuestion = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  questionText: string;
  questionPosition: number;
  type: ResearchQuestionType;
  scaleStart?: number | null;
  scaleEnd?: number | null;
  studyId: number;
  selections: ResearchAnswerSelection[];
};

export type ResearchAnswerSelection = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  answerText: string;
  answerPosition: number;
  questionId: number;
};

export type ResearchAnswer = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  type: ResearchQuestionType;
  isShared: boolean;
  userId: number;
  studyId: number;
  questionId: number;
  hasResponded: boolean;
  hasDismissed: boolean;
  dismissedAt?: Date;
  firstRespondedAt?: Date;
  responseBoolean?: boolean;
  responseScale?: number;
  responseSelectionId?: number;
};

export type ResearchStudyWithQuestions = ResearchStudy & {
  questions: ResearchQuestion[];
};

export type ResearchStudyWithQuestionsAndAnswers = ResearchStudy & {
  questions: (ResearchQuestion & { answers: ResearchAnswer[] })[];
};

export type ResearchStudyWithQuestionsAndAggregates = ResearchStudyWithQuestions & {
  _count: { answers: number; questions: number };
};

export type ResearchAnswerUpdate = {
  answerId: number;
  isShared?: boolean;
  hasDismissed?: boolean;
  dismissedAt?: Date;
  responseBoolean?: boolean;
  responseScale?: number;
  responseSelectionId?: number;
  responseFreeText?: string;
};

const ResearchScopeSchema = z.enum(['global', 'workspace', 'selection']);

const ResearchSurveyFrequencySchema = z.enum([
  'daily',
  'weekly',
  'monthly',
  'yearly',
  'custom',
]);

const ResearchQuestionTypeSchema = z.enum(['boolean', 'selection', 'scale', 'text']);

const ResearchQuestionCreateSchema = z.object({
  questionText: z.string(),
  type: ResearchQuestionTypeSchema,
  questionPosition: z.number(),
});

export const ResearchQuestionsCreateSchema = ResearchQuestionCreateSchema.array();

export type ResearchQuestionCreate = z.infer<typeof ResearchQuestionCreateSchema>;

const ResearchQuestionUpdateSchema = ResearchQuestionCreateSchema.extend({
  id: z.number().optional(),
});

export const ResearchQuestionsUpdateSchema = ResearchQuestionUpdateSchema.array();

export type ResearchQuestionUpdate = z.infer<typeof ResearchQuestionUpdateSchema>;

export const ResearchRecipientCreateSchema = z.object({
  selectedUserIds: z.array(z.number()).optional(),
  organizationId: z.number().optional(),
});

export type ResearchRecipientCreate = z.infer<typeof ResearchRecipientCreateSchema>;

export const ResearchRecipientUpdateSchema = z.object({
  id: z.number().optional(),
  studyId: z.number(),
  organizationId: z.number().nullable(),
  selectedUserId: z.number().nullable(),
});

export type ResearchRecipientUpdate = z.infer<typeof ResearchRecipientUpdateSchema>;

export const ResearchBaseSchema = z.object({
  name: z.string(),
  scope: ResearchScopeSchema,
  studyText: z.string(),
  repeatFrequency: ResearchSurveyFrequencySchema.optional(),
  repeatAt: z.date().optional(),
  creatorId: z.number(),
  organizationId: z.number(),
  isPublished: z.boolean(),
  publishedAt: z.date().optional(),
  recipients: ResearchRecipientCreateSchema,
});

export type ResearchBase = z.infer<typeof ResearchBaseSchema>;

export const ResearchCreateSchema = ResearchBaseSchema.omit({ recipients: true });

export type ResearchCreate = z.infer<typeof ResearchCreateSchema>;

export const ResearchUpdateSchema = ResearchBaseSchema.extend({
  id: z.number(),
  recipients: ResearchRecipientUpdateSchema.array(),
});

export const ResearchWithIdSchema = ResearchBaseSchema.extend({
  id: z.number(),
}).omit({ recipients: true });

export type ResearchWithId = z.infer<typeof ResearchWithIdSchema>;

export type ResearchUpdate = z.infer<typeof ResearchUpdateSchema>;
