import { useFlag } from '@unleash/proxy-client-react';
import queryString from 'query-string';

import { SocialButton } from 'Components/buttons/social';

import './social-buttons-v2.scss';

import { Messages } from './social-buttons-v2.messages';

type Props = {
  links?: {
    google?: string;
    facebook?: string;
    linkedin?: string;
  };
  continueWith?: boolean;
};

export const socialLink = (
  type: string,
  params?: { [K: string]: string | undefined | null },
) => {
  const query = queryString.stringify({
    errorRedir: '/sign-in',
    ...params,
  });

  return `/auth/connect/${type}?${query}`;
};

export const SocialButtonsV2 = ({ links, continueWith }: Props) => {
  const showLinkedIn = useFlag('Signup.LinkedIn');
  return (
    <div className="social-buttons-v2">
      <SocialButton
        type="google"
        link={links?.google || socialLink('google')}
        text={
          continueWith
            ? Messages.continueWithProviderButton('Google')
            : Messages.signUpWithProviderButton('Google')
        }
        disabled={false}
        img="/assets/icon-social-google.svg"
        isSignUp={!continueWith}
      />
      <SocialButton
        type="facebook"
        link={links?.facebook || socialLink('facebook')}
        text={
          continueWith
            ? Messages.continueWithProviderButton('Facebook')
            : Messages.signUpWithProviderButton('Facebook')
        }
        disabled={false}
        img="/assets/icon-social-facebook.svg"
        isSignUp={!continueWith}
      />
      {showLinkedIn && (
        <SocialButton
          type="linkedin"
          link={links?.linkedin || socialLink('linkedin')}
          text={
            continueWith
              ? Messages.continueWithProviderButton('Linkedin')
              : Messages.signUpWithProviderButton('Linkedin')
          }
          disabled={false}
          img="/assets/icon-social-linkedin.svg"
          isSignUp={!continueWith}
        />
      )}
    </div>
  );
};
