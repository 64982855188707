import { loadPasswordEstimatedJs } from 'Utils/js_css_loader';

export function passwordEstimate(password) {
  try {
    if (!window.zxcvbn) {
      loadPasswordEstimatedJs();
      return 2;
    }
    return Number.parseInt(window.zxcvbn(password).score, 10);
  } catch {
    return 2;
  }
}
