import clsx from 'clsx';
import type { XOR } from 'ts-xor';

import styles from './max-width-content.module.scss';

// TODO: Rename width800 to be more specific to a recognized design term
type Props = React.PropsWithChildren<{}> &
  XOR<{}, { width800?: true; width1040?: true; padding?: 'md'; mobilePadding?: 'none' }>;

/** Variation of MaxWidthContainer specifically for redesign page content */
export const MaxWidthContent = ({
  children,
  width800,
  width1040,
  padding,
  mobilePadding,
}: Props) => {
  const paddingContent = padding ? styles[`${padding}Padding`] : '';
  const mobilePaddingContent = mobilePadding
    ? styles[`${mobilePadding}MobilePadding`]
    : '';
  return (
    <div
      className={clsx(styles.container, paddingContent, mobilePaddingContent, {
        [styles.width800]: width800,
        [styles.width1040]: width1040,
      })}
    >
      {children}
    </div>
  );
};
