import { type AxiosResponse } from 'axios';

import { type UserSearchResult } from '@f4s/types';

import { httpAgent as http } from './agents/http';

export const uploadImage = (file: any) => {
  const formData = new FormData();
  formData.append('file', file);
  return http().post('/api/v1/upload-image', formData);
};
export const getCountries = () => http().get('/api/v1/countries');

export const search = (data: {
  searchText: string;
  orgId?: number;
}): Promise<AxiosResponse<{ users: UserSearchResult[] }>> =>
  http().post('/api/v3/users/search', data);

export const getPermissions = (type: string, name: string) =>
  http().get(`/api/v2/content/permissions/${type}/${name}`);
