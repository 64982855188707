import { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';
// import { DEFAULT_PAGE_APP } from '@/constants';
import { user as userApi } from 'APIs';
import { useQuery } from 'Hooks/useQuery';

import { InviteSignUpRoot } from './sign-up-by-invites/root';
import { SharedSignUpRoot } from './sign-up-shared/root';
import { SignUpV2Root } from './sign-up-v2/root';
import { SignUpZoomRoot } from './sign-up-zoom/root';
import { SignupMagicInviteRoot } from './signup-magic-link-invite/root';
import {
  getCampaignCode,
  getReferrerToken,
  setCampaignCode,
  setIntegrationCandidateToken,
  setReferrerToken,
  setSignupGoal,
  setSignupProgram,
} from './utils';

export function SignupRoot(): React.ReactElement {
  const { path, url } = useRouteMatch();
  const { program, goal, type, token } = useQuery();
  const { redir } = useQuery<{ redir?: string }>();

  /**
   * Note (Search 91c34bdb for related notes)
   * We set redir at root-level for both sign-in and sign-up so that there's no confusion about who has priority in redirection
   * The priority goes to localStorage's redir as the user should end up where the site initially wanted to redirect them
   */
  localStorage.setItem('redir', redir ?? '');

  const isIntegrationCandidate = type === 'candidate' && token;

  useEffect(() => {
    if (program) setSignupProgram(program);
    if (goal) setSignupGoal(goal);
    if (isIntegrationCandidate) setIntegrationCandidateToken(token);
  }, [program, goal, isIntegrationCandidate]);

  // TODO: check how form flow was handling integrationCandidate
  const baseRedirect = 'get-started';

  const { user, isLoggedIn } = useMaybeUser();

  useEffect(() => {
    const inner = async () => {
      const referrer = getReferrerToken();
      const campaign = getCampaignCode();
      if (referrer && campaign && user) {
        const { data: referral } = await userApi.registerAffiliate(
          referrer,
          campaign,
          'sign-up',
        );
        if (referral.completed) {
          setReferrerToken();
          setCampaignCode();
        }
      }
    };
    inner();
  }, [isLoggedIn]);

  return (
    <Switch>
      <Route path={`${path}/organization/invite/:token`} component={InviteSignUpRoot} />
      <Route
        path={`${path}/organization/join/:token`}
        component={SignupMagicInviteRoot}
      />
      {/* Match invites first and only redirect other routes */}
      {/* TODO: Fix this so it doesn't redirect subpages of signup flows 😭 */}
      {/* {isLoggedIn && user?.onboardingCompleted && <Redirect to={DEFAULT_PAGE_APP} />} */}
      <Route path={`${path}/get-started`} component={SignUpV2Root} />
      <Route path={`${path}/shared`} component={SharedSignUpRoot} />
      <Route path={`${path}/zoom`} component={SignUpZoomRoot} />
      {navigator.userAgent.includes('ZoomApps') && (
        <Redirect from={`${path}/`} to={`${path}/zoom`} />
      )}
      {/* Otherwise pick a flow based on flags */}
      <Redirect
        from={path}
        to={{
          pathname: `${url}/${baseRedirect}`,
          search: window.location.search,
        }}
      />
    </Switch>
  );
}
