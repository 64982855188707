import { useEffect, useState } from 'react';

import type {
  ConnectionByOrgAndGroup,
  ConnectionMember,
  Organization,
  OrgCreationSearchUser,
  UserSearchResult,
} from '@f4s/types';

import { useInvalidateAppSettings } from '@/api-queries/app-settings';
import { useConnectionsInvalidation } from '@/api-queries/connections';
import { sendEvent } from '@/apis/analytics';
import { useAppContext } from '@/contexts';
import { OrgCreationContext } from '@/contexts/org-creation';

import { ActionButtons } from './common/action-buttons';
import { AddMember } from './common/members/add-member';
import { NameAndAvatarInput } from './common/name-avatar-input';
import { type CreatableTeamMember, type UserOrEmail } from './types';

type InitialMember =
  | ConnectionMember
  | (ConnectionMember & {
      orgs: Partial<ConnectionByOrgAndGroup>[];
    })
  | UserSearchResult;

type Props = {
  initialMembers?: InitialMember[];
  hideClose?: boolean;
  onClose?: () => void;
  onSuccess?: (organization: Organization) => void;
  onMemberAdded?: (member: UserOrEmail) => void;
  onMemberRemoved?: (member: CreatableTeamMember) => void;
};

export const CreateWorkspaceCommon = ({
  initialMembers = [],
  onSuccess,
  hideClose = false,
  onClose,
  onMemberAdded,
  onMemberRemoved,
}: Props) => {
  const {
    handleMemberChange,
    handleImageURLChange,
    orgName,
    handleOrgNameChange,
    members,
    runFieldVerification,
    setOrgType,
    runSubmitNewOrg,
    clear: clearOrgCreationContext,
  } = useAppContext(OrgCreationContext);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageChanged, setImageChanged] = useState<boolean>(false);
  const refetchConnections = useConnectionsInvalidation();
  const refetchUserOrgs = useInvalidateAppSettings();

  useEffect(() => {
    setOrgType('space');
    const mappedInitialMembers = initialMembers.map<OrgCreationSearchUser>(
      (member) =>
        ({
          userId: member.userId,
          id: member.userId,
          firstName: member.firstName,
          lastName: member.lastName,
          isAdmin: false,
          isMember: true,
          profileImageUrl:
            'avatarUrl' in member ? member.avatarUrl : member.profileImageUrl,
          emailAddress: 'emailAddress' in member ? member.emailAddress : undefined,
        }) as OrgCreationSearchUser,
    );
    handleMemberChange([...members, ...mappedInitialMembers]);
  }, []);

  const changeImageUrl = async (imageString: string) => {
    handleImageURLChange(imageString);
    setImageChanged(true);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const organization = await runSubmitNewOrg();
    setIsSubmitting(false);

    // invalidate organization caches
    refetchConnections();
    refetchUserOrgs();

    sendEvent('workspace_created', {
      workspaceId: organization.id,
      workspaceAction: 'created',
      workspaceDisplay: 'modal',
      workspaceImageUploaded: isImageChanged,
      workspaceSize: members.length,
      workspaceSource: 'modal',
      workspaceType: 'workspace',
    });

    onSuccess?.(organization);
  };

  return (
    <>
      <span className="text-sm text-gray-500">
        Create a space to optimize your team’s collaboration and performance
      </span>
      <NameAndAvatarInput
        label="Workspace name* and icon"
        name={orgName || ''}
        setName={handleOrgNameChange}
        setImageURL={changeImageUrl}
        errorMsg={errorMessage}
      />
      <AddMember onMemberAdded={onMemberAdded} onMemberRemoved={onMemberRemoved} />
      <div className="my-4 h-0.5 w-full bg-gray-100" />
      <ActionButtons
        isDisabled={isSubmitting}
        isSubmitting={isSubmitting}
        submitBtnText="Create workspace"
        hideClose={hideClose}
        onClose={() => {
          onClose?.();
          clearOrgCreationContext();
        }}
        onSubmit={async () => {
          const result = await runFieldVerification();
          if (!result.valid) {
            setErrorMessage(result.error);
          } else {
            setErrorMessage('');
            handleSubmit();
          }
        }}
      />
    </>
  );
};
