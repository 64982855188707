import { z } from 'zod';

export const SelectionCreateSchema = z.object({
  workspaceId: z.number().optional(),
  name: z.string(),
  userIds: z.array(z.number()),
});
export type SelectionCreate = z.infer<typeof SelectionCreateSchema>;

export const SelectionUpdateSchema = z.object({
  workspaceId: z.number().optional(),
  selectionId: z.number(),
  name: z.string().optional(),
  userIds: z.array(z.number()).optional(),
});
export type SelectionUpdate = z.infer<typeof SelectionUpdateSchema>;

export const SelectionDeleteSchema = z.object({
  workspaceId: z.number().optional(),
  selectionId: z.number(),
});
export type SelectionDelete = z.infer<typeof SelectionDeleteSchema>;

export type MinimalUser = {
  userId: number;
  firstName: string | null;
  lastName: string | null;
  avatarUrl?: string | null;
};
export type Selection = {
  id: number;
  name: string;
  workspaceId: number | null;
  _count: { users: number };
};
export type SelectionDetail = {
  id: number;
  name: string;
  userId: number;
  users: MinimalUser[];
};
