import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';
import { type User } from '@f4s/types';

import { useMaybeUser } from '@/api-queries/app-settings';
import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';
import { DEFAULT_PAGE_APP } from 'Constants';
import { useQuery } from 'Hooks/useQuery';
import * as segment from 'Lib/segment';

import { submitPendingMiniAssessment } from '../sign-in/mini-assessment';

const updateUserPostSignIn = async (userId: number) => {
  // We only want this to fire after signing IN, not signing up
  const propertiesToUpdate: Partial<User> = {
    lastLoginAt: new Date().toISOString(),
    userId: userId,
  };
  await API.user.updateProfile(propertiesToUpdate);
};

export function AfterSocialSignInOrUp() {
  const history = useHistory();
  const { isLoggedIn, user, isLoading } = useMaybeUser();
  const { error, type, inviteToken, socialType, redir: redirRaw, isNew } = useQuery();
  const redir = String(redirRaw || '');

  const redirectUser = useCallback(() => {
    segment.pageView('Sign In', { method: socialType });

    if (!isLoggedIn && error !== 'unauthorized') {
      history.push('/sign-in');
      return;
    }

    if (error) {
      const hasQuery = /\?/.test(redir);
      history.push(`${redir}${hasQuery ? '&' : '?'}error=${error}&type=${type}`);
      return;
    }

    if (redir) {
      /**
       * If new user signs up and has no redir, push them to onboarding flow
       * If new user signs up and has redir, push them to redir
       * If existing user signs in through sign-up flow, and has no redir, push them to /
       * If existing user signs in through sign-up flow, and has redir, push them to redir
       * If existing user signs in normally and has no redir, push them to /
       * If existing user signs in normally and has redir, push them to redir
       */
      history.push(redir);
      return;
    } else {
      if (isNew) {
        // Moved this here from CreateAccountStep's ssoRedir prop
        history.push('/sign-up/get-started/why-are-you-here');
        return;
      }
    }

    if (user) {
      // Check for and submit any pending mini assessments
      submitPendingMiniAssessment().catch((error_) => console.warn(error_));
    }

    if (user && !user.onboardingCompleted) {
      // Send them back to signup let it deal with sending them to the right place
      history.push('/sign-up');
      return;
    }

    history.push(DEFAULT_PAGE_APP);
  }, [error, history, isLoggedIn, redir, socialType, type, user]);

  useEffect(() => {
    if (isLoading) return;

    if (user) {
      if (isNew === true) {
        segment.signedUpClientEvent();
      } else {
        updateUserPostSignIn(user.id);
      }
      segment.identify(user);
    }

    if (inviteToken) {
      Notification.create(
        AppText.notification_modal_invitation_connection_success,
        'success',
      );
      setTimeout(redirectUser, 1500);
    } else {
      redirectUser();
    }
  }, [inviteToken, isLoading, isNew, redirectUser, user]);

  return (
    <div className="wrapper">
      <div className="user-screen center text-center">
        You will be redirected shortly. <br />
        <button type="button" onClick={redirectUser}>
          {' '}
          Click here{' '}
        </button>{' '}
        if you are not redirected automatically.
      </div>
    </div>
  );
}
