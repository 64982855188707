// Insight could potentially be merged into the broader models schema

import { z, type Schema } from 'zod';

import { MotivationPatternSchema } from '../common/motivations';

type ObjectName = 'insight';

const insightBaseSchema = z.object({
  cardName: z.string(), // TODO: could be an enum of our insight model names
  cardType: z.enum(['insight']),
  matchScore: z.number().optional(),
  groupSize: z.number(),
});

const insightOrderedSchema = insightBaseSchema.extend({
  cardOrder: z.number().optional(),
});

const insightMotivationSchema = insightBaseSchema.extend({
  motivation: MotivationPatternSchema,
  // cardOrder: z.number(),
  motivationScore: z.number().optional(),
  motivationResults: z.enum(['team', 'individual']),
  motivationMatch: z.enum(['Too Low', 'Too High', 'Strength', '']), // TODO: Replace empty string with value for mini assessments
});

const insightFeedbackSchema = insightBaseSchema.extend({
  feedbackResponse: z.enum(['positive', 'negative']),
});

const insightSchemas = {
  insight_clicked: insightOrderedSchema,
  insight_hovered: insightOrderedSchema,
  insight_viewed: insightBaseSchema,
  insight_feedback: insightFeedbackSchema,
  insight_explored: insightMotivationSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { insightSchemas };
