import queryString from 'query-string';

import { type AggregateResponse, type QuestionnaireResponse } from '@f4s/types';

import { httpAgent as http } from './agents/http';

export const getV3 = (opts: {
  orderedQuery?: string;
  organizationId?: number;
  groupId?: number;
  userId?: number | number[];
  id?: number | number[];
  page?: number;
  pageSize?: number;
  scope?: number;
}) =>
  http().get<QuestionnaireResponse>(
    `/api/v3/questionnaires?${queryString.stringify(opts)}`,
  );

export const getDemo = () =>
  http().get<QuestionnaireResponse>(`/api/v3/questionnaires/demo`);

type AggregateOpts = {
  organizationId?: number;
  groupId?: number;
  userId?: number | number[];
  id?: number | number[];
  page?: number;
  pageSize?: number;
  scope?: number;
};

export const getAggregates = (opts: AggregateOpts) =>
  http().get<AggregateResponse>(
    `/api/v3/questionnaires/aggregates?${queryString.stringify(opts)}`,
  );
export const adminGetAggregates = (opts: AggregateOpts) =>
  http().get<AggregateResponse>(
    `/api/v3/admin/questionnaires/aggregates?${queryString.stringify(opts)}`,
  );

export const getShareQuestionnaire = (shareCode: string) =>
  http().get(`/api/v3/public/questionnaires/share/${shareCode}`);

export const getQuestionnaireResultShareCode = () =>
  http().post(`/api/v3/questionnaires/share-code`);

export const getTotalLockedMotivationsCount = () =>
  http().get(`/api/v3/questionnaires/locked-motivations-count`);

export const finalizeQuestionnaire = () => http().post(`/api/v3/questionnaires/finalize`);
