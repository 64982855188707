import { Label } from '@radix-ui/react-label';

import { AvatarUploader } from '@/components/form_elements';
import * as API from 'APIs';

type Props = {
  label: string;
  name: string;
  setName: (name: string) => void;
  setImageURL: (url: string) => void;
  errorMsg?: string;
};

export const NameAndAvatarInput = ({
  label,
  name,
  setName,
  setImageURL,
  errorMsg,
}: Props) => {
  const uploadAvatar = async (file: File) => {
    const { data } = await API.utils.uploadImage(file);
    setImageURL(data.imageURL);
  };

  return (
    <div className="mb-2 mt-4 flex gap-6">
      <div className="basis-full">
        <Label
          htmlFor="team-name"
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          {label}
        </Label>
        <input
          id="team-name"
          type="text"
          name="Team name"
          className="h-10 w-full rounded-lg border border-gray-300 text-sm placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="eg. Your company name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        {errorMsg && (
          <span className="text-sm font-normal leading-5 text-red-600">{errorMsg}</span>
        )}
      </div>
      <AvatarUploader size="md" onFileSelected={uploadAvatar} />
    </div>
  );
};
