import React, { type ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@/components/common/button/button';
import { logError } from '@/lib/log-error';
import { Validations } from 'Utils/validations';

import {
  DateInput,
  DropdownInput,
  SearchableDropdown,
  SelectInput,
  TextInput,
} from './input';
import { renderOutput } from './output/render';

import './form.scss';

const inputMap = {
  text: TextInput,
  select: SelectInput,
  dropdown: DropdownInput,
  date: DateInput,
  searchableDropdown: SearchableDropdown,
};

const validationMap = {
  ...Validations,
  'array-of-emails': (arr) =>
    arr &&
    arr.reduce &&
    arr.reduce((res, val) => res && (val === '' || Validations.email(val)), true),
};

function renderComponent(component, props) {
  const Component = component;
  return <Component {...props} />;
}

function renderInput(inputData) {
  const { type, ...inputProps } = inputData;
  if (inputMap[type]) {
    return renderComponent(inputMap[type], inputProps);
  }
  throw new Error(`Could not find input render component for "${type}"`);
}

type FlowFormProps = {
  script: any[];
  step?: string;
  data: { [key: string]: any };
  onStepChange: (...args: any) => void;
  onStepValidate?: (arg: any) => boolean;
  onDataChange?: (value: any) => void;
  className?: string;
  error?: { [key: string]: any } | null;
  clearError?: () => void;
  onError?: (error: any) => void;
};

function FlowForm({
  script,
  data,
  step,
  onStepChange,
  onStepValidate,
  onDataChange,
  className = '',
  error = null,
  clearError,
  onError,
}: FlowFormProps) {
  const history = useHistory();
  const stepIndex = step ? script.findIndex((stepData) => stepData.id === step) : 0;
  const stepEntry = script[stepIndex];
  const controls: ReactElement[] = [];
  let inputCount = 0;
  let requiresNext = true;
  let isValid = true;

  if (error && onError) {
    onError(error);
  }

  const nextStep = async () => {
    const currentStepId = script[stepIndex].id;
    const nextStepId = script[stepIndex + 1]?.id;

    clearError?.();
    await onStepChange(currentStepId, nextStepId, 1);
  };

  const skipStep = () => {
    const { skipAction } = script[stepIndex];
    clearError?.();

    if (skipAction?.action === 'redirect') return history.push(skipAction.path);
    return nextStep();
  };

  const conversations = stepEntry.conversation.map((conversation) => {
    let outputComponents;
    let inputComponents;
    if (conversation.say) {
      outputComponents = conversation.say.map((statement, oIdx) =>
        renderOutput(statement, data, oIdx),
      );
    }

    if (conversation.input) {
      inputComponents = conversation.input.map((input, iIdx) => {
        const { required, validation, type, store } = input;
        if (store === 'email' && data[store]) {
          data[store] = data[store].replaceAll(/\s/g, '');
        }
        const value = data[store];
        const compValidFunc = inputMap[type]?.validation;
        const validFunc = validationMap[validation];
        inputCount += 1;

        requiresNext = inputCount > 1 || inputMap[type]?.requiresNext;

        if (required && !value) isValid = false;
        if (compValidFunc && !compValidFunc(value, input)) isValid = false;
        if (validFunc && !validFunc(value)) isValid = false;

        return renderInput({
          ...input,
          data,
          onChange: onDataChange,
          key: `${type}${iIdx}`,
          next: () => nextStep(),
        });
      });
    }

    return (
      // Flow form conversation elements do not change order
      <div key={step}>
        <div className="conversation">
          {outputComponents && <div className="output">{outputComponents}</div>}
          {inputComponents && <div className="input input-column">{inputComponents}</div>}
        </div>
      </div>
    );
  });

  if (onStepValidate) {
    isValid = isValid && onStepValidate(stepEntry.id);
  }

  if (stepEntry.skippable) {
    controls.push(
      <Button
        variant="white"
        key="skip"
        onClick={skipStep}
        text={stepEntry.skipLabel || 'Maybe later'}
      />,
    );
  }

  if (requiresNext) {
    controls.push(
      <Button
        variant="primary"
        key="next"
        disabled={!isValid}
        onClick={nextStep}
        text={stepEntry.nextLabel || 'Next'}
      />,
    );
  }

  return (
    <div
      className={`flow-form f4s-forms step-${step} ${className}`}
      role="presentation"
      onKeyPress={(e) => {
        if (e.key === 'Enter' && isValid) nextStep();
      }}
    >
      <div className="container">
        {conversations}
        {controls.length > 0 && <div className="controls">{controls}</div>}
      </div>
    </div>
  );
}

export class FlowFormErrorBoundary extends React.Component<
  FlowFormProps,
  { error: Error | null }
> {
  constructor(props: FlowFormProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    logError(error);
  }

  render() {
    const formProps = {
      ...this.props,
      error: this.state.error,
      clearError: () => this.setState({ error: null }),
    };
    return <FlowForm {...formProps} />;
  }
}
