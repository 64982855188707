import './env/init-load-client-env';
import 'focus-visible/dist/focus-visible.min';
import '@/lib/polyfills/at';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FlagProvider } from '@unleash/proxy-client-react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { Route, Router } from 'react-router-dom';

import { ErrorPage } from 'Components/error_page';
import { Notification } from 'Components/view/snackbar';

import { App } from './app';
import { InitialContextStack } from './contexts/context-stack-initial';
import { initSentry, Sentry } from './lib/sentry';

import './styles/app.scss';

const customHistory = createBrowserHistory();

initSentry({ history: customHistory });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 3600000, // 1hr
      retry: (count, error) => count < 3 && !/(?!(404|408|429))4\d\d/.test(String(error)),
    },
  },
});

const unleashConfig = {
  url: `${window.location.protocol}//${window.location.host}/feature-flags/proxy`,
  clientKey: 'mt9Bebe82GAgxsqTqY2f',
  appName: 'f4s-app',
  environment: '',
};

window.addEventListener('storage', (event) => {
  if (event.key === 'f4s-logout-event') {
    Notification.create('Your session has expired', 'warning', {});
    window.location.href = '/';
  }
});

const container = document.querySelector('#appContainer');
if (container) {
  const root = createRoot(container);

  root.render(
    <FlagProvider config={unleashConfig}>
      <Sentry.ErrorBoundary
        fallback={(sentryProps) => <ErrorPage sentryProps={sentryProps} />}
      >
        <QueryClientProvider client={queryClient}>
          <Router history={customHistory}>
            <InitialContextStack>
              <Route path="/" component={App} />
            </InitialContextStack>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </FlagProvider>,
  );
}
