import { z, type Schema } from 'zod';

type ObjectName = 'assessment';

const assessmentBaseSchema = z.object({
  assessmentId: z.number().optional(), // TODO: Remove. ID doesn't make much sense now
  assessmentName: z.string().default('full'),
});

const assessmentNextQuestionSchema = assessmentBaseSchema.extend({
  questionId: z.number(),
  questionPosition: z.number(),
  percentCompleted: z.number().optional(),
});

const assessmentCompletedSchema = assessmentBaseSchema.extend({
  isStatisticallyValid: z.boolean().optional(),
});

const assessmentSchemas = {
  assessment_created: assessmentBaseSchema,
  assessment_started: assessmentBaseSchema,
  assessment_next: assessmentNextQuestionSchema,
  assessment_completed: assessmentCompletedSchema,
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { assessmentSchemas };
