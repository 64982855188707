import { ChevronDownIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';

import { getDayOptions, getMonthOptions, getYearOptions } from '../../../lib/date';

/**
 * Renders dropdown options from a DateOptions array
 * @param { Object[] } options
 * @param { number } options[].key
 * @param { string } options[].display
 * */
const renderOptions = (options) =>
  options.map((option) => (
    <option key={option.key} value={option.key}>
      {option.display}
    </option>
  ));

type DateInputProps = {
  store: string;
  data: { [key: string]: any };
  onChange: (...args: any) => void;
  next: () => void;
  className: string;
  required: boolean;
  yearStartOffset: number;
  yearEndOffset: number;
};

/**
 * @deprecated As much as possible, we want to reduce the use of these flow-form components, and if we want to use something like this in the future, it's probably
 * easier to just plan and build a new set of components. We also abuse the type any quite a bit here, which makes things perilous to continue interating upon
 */
export function DateInput({
  store,
  data,
  onChange,
  next: _next,
  yearStartOffset: _yearStartOffset,
  yearEndOffset: _yearEndOffset,
  ...props
}: DateInputProps) {
  const { ...extraProps } = props;
  extraProps.className = `date-input ${extraProps.className || ''}`;
  const dataStore = data[store] || {};

  const [dayOptions, setDayOptions] = useState(
    getDayOptions(Number(dataStore.year), Number(dataStore.month)),
  );
  const [monthOptions] = useState(getMonthOptions());
  const [yearOptions] = useState(getYearOptions());

  useEffect(() => {
    setDayOptions(getDayOptions(Number(dataStore.year), Number(dataStore.month)));
  }, [dataStore.year, dataStore.month]);

  return (
    <div {...extraProps}>
      <div className="select">
        <div className="input dropdown select-control">
          <div className="select-icon w-5">
            <ChevronDownIcon className="text-gray-500" />
          </div>
          <select
            value={dataStore.day || 'blank'}
            onChange={(event) => {
              onChange({
                ...data,
                [store]: {
                  ...dataStore,
                  day: event.target.value,
                },
              });
            }}
            {...extraProps}
          >
            <option key="blank" value="blank" disabled>
              Day
            </option>
            {renderOptions(dayOptions)}
          </select>
        </div>
      </div>

      <div className="select">
        <div className="input dropdown select-control">
          <div className="select-icon w-5">
            <ChevronDownIcon className="text-gray-500" />
          </div>
          <select
            value={dataStore.month || 'blank'}
            onChange={(event) => {
              onChange({
                ...data,
                [store]: {
                  ...dataStore,
                  month: event.target.value,
                },
              });
            }}
            {...extraProps}
          >
            <option key="blank" value="blank" disabled>
              Month
            </option>
            {renderOptions(monthOptions)}
          </select>
        </div>
      </div>

      <div className="select">
        <div className="input dropdown select-control">
          <div className="select-icon w-5">
            <ChevronDownIcon className="text-gray-500" />
          </div>
          <select
            value={dataStore.year || 'blank'}
            onChange={(event) => {
              onChange({
                ...data,
                [store]: {
                  ...dataStore,
                  year: event.target.value,
                },
              });
            }}
            {...extraProps}
          >
            <option key="blank" value="blank" disabled>
              Year
            </option>
            {renderOptions(yearOptions)}
          </select>
        </div>
      </div>
    </div>
  );
}

DateInput.requiresNext = true;
DateInput.validation = ({
  day,
  month,
  year,
}: { day?: number; month?: number; year?: number } = {}) => {
  const days = getDayOptions(Number(year), Number(month));
  return Boolean(day && month && year && day <= days.length);
};
