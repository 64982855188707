import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FeatureFlags } from '@f4s/types';

import { useMaybeUser } from '@/api-queries/app-settings';
import { useGetMagicLinkByToken } from '@/api-queries/magic-links';
import {
  useGetReducedOrganizationByMagicLinkToken,
  useVerifyToken,
} from '@/api-queries/organizations';
import { Loader } from '@/components/common/loader/loader';
import { RequestSubmitted } from '@/components/request-submitted';
import { AuthDashboardIndexV2 } from '@/layouts';
import { useHistorySteps } from '@/lib/hooks/useHistorySteps';
import { redirectAsync } from '@/lib/underscore';
import { AssessmentPrompt } from '@/pages/sign-up/sign-up-by-invites/form-flow/assessment-prompt';
import { JoinOrg } from '@/pages/sign-up/sign-up-by-invites/form-flow/join-org-step';
import { OrgInviteBanner } from '@/pages/sign-up/sign-up-v2/components/org-invite-banner';
import { AboutYouStep } from '@/pages/sign-up/sign-up-v2/form-flow/about-you-step';
import { CreateAccountStep } from '@/pages/sign-up/sign-up-v2/form-flow/create-account-step';
import styles from '@/pages/sign-up/sign-up-v2/style-signup.module.scss';
import { type SignUpDetailTypes } from '@/pages/sign-up/sign-up-v2/types';

const StepNames = [
  'create-account',
  'about-you',
  'join-org',
  'request-submitted',
  'assessment-prompt',
] as const;
const STEPS: Record<
  (typeof StepNames)[number],
  { displayStep: number | false; displayLogin?: boolean; displayCompleted?: boolean }
> = {
  'create-account': { displayStep: false, displayLogin: true },
  'about-you': { displayStep: 1 },
  'join-org': { displayStep: 2 },
  'request-submitted': { displayStep: 3 },
  'assessment-prompt': { displayStep: false, displayCompleted: true },
} as const;

type StepName = (typeof StepNames)[number];

export function FormFlow(): React.ReactElement {
  const { user } = useMaybeUser();
  const { flagsReady } = useFlagsStatus();

  const [step, setStep] = useHistorySteps<StepName>('create-account', 'step', false);
  const history = useHistory();

  const [signUpDetails, setSignUpDetails] = useState<SignUpDetailTypes>(() => {
    const {
      emailAddress: email = '',
      firstName = '',
      lastName = '',
      properties = {},
      onboardingCompleted = false,
      countryCode = '',
    } = user ?? {};
    const { jobArea = '', jobTitle = '' } = properties;

    return {
      email,
      firstName,
      lastName,
      jobArea,
      jobTitle,
      goal: 0,
      goalType: [],
      onboardingCompleted,
      countryCode,
    };
  });
  const isItJustWorksFlagged = useFlag(FeatureFlags.itJustWorks);

  const stepIdx = StepNames.indexOf(step);
  const nextStep = (inc = 1) => {
    const next = StepNames[stepIdx + inc];
    setStep(next);
  };
  const params: any = useParams();
  const {
    refetch: verifyToken,
    isSuccess: tokenSuccess,
    isLoading: isVerifyTokenLoading,
    data: tokenData,
  } = useVerifyToken({
    token: params.token,
    enabled: false,
  });
  const { isLoading: isLoadingMagicLinkData, data: magicLinkData } =
    useGetMagicLinkByToken(params.token, isItJustWorksFlagged && flagsReady);
  const { isLoading: isLoadingOrgData, data: orgData } =
    useGetReducedOrganizationByMagicLinkToken(magicLinkData?.token);

  const handleInputChange = (name: string, value: string | number) => {
    setSignUpDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const { displayStep, displayCompleted = false, displayLogin = false } = STEPS[step];
  const totalSteps = Math.max(...Object.values(STEPS).map((s) => s.displayStep || 0));

  const stepContainer = () => {
    switch (step) {
      case 'create-account': {
        return (
          <CreateAccountStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
            signupFlow="2022-org-link"
            ssoRedir={`sign-up/organization/join/${params?.token}/about-you`}
            banner={
              <OrgInviteBanner
                org={isItJustWorksFlagged ? orgData : tokenData}
                token={params.token}
                from="sign-up"
              />
            }
          />
        );
      }
      case 'about-you': {
        return (
          <AboutYouStep
            signUpDetails={signUpDetails}
            handleInputChange={handleInputChange}
            nextStep={nextStep}
          />
        );
      }
      case 'join-org': {
        return <JoinOrg nextStep={nextStep} magicLink />;
      }
      case 'request-submitted': {
        return (
          <RequestSubmitted
            nextStep={nextStep}
            orgName={isItJustWorksFlagged ? orgData?.name : tokenData?.name}
          />
        );
      }
      case 'assessment-prompt': {
        return <AssessmentPrompt />;
      }
      default: {
        return <div className="App" />;
      }
    }
  };

  useEffect(() => {
    const getVerification = async () => {
      const { error, isError }: { error: any; isError: boolean } = await verifyToken(
        params.token,
      );
      if (isError && error?.response?.status === 404) {
        await redirectAsync(`/sign-up?redir=${window.location.pathname}`);
      }
    };

    const getVerificationV2 = async () => {
      if (!isLoadingMagicLinkData && !magicLinkData) {
        await redirectAsync(`/sign-up?redir=${window.location.pathname}`);
      }
    };

    if (flagsReady) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isItJustWorksFlagged ? getVerificationV2() : getVerification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyToken, history, params.token, magicLinkData, flagsReady]);

  return (
    <>
      {((tokenSuccess && !isItJustWorksFlagged) || (orgData && isItJustWorksFlagged)) && (
        <AuthDashboardIndexV2
          displayStep={displayStep}
          totalSteps={totalSteps}
          displayCompleted={displayCompleted}
          displayLogin={displayLogin}
        >
          <div className={styles.loginFormRoot}>{stepContainer()}</div>
        </AuthDashboardIndexV2>
      )}

      {!isItJustWorksFlagged && isVerifyTokenLoading && <Loader size="lg" />}
      {isItJustWorksFlagged && isLoadingMagicLinkData && isLoadingOrgData && (
        <Loader size="lg" />
      )}
    </>
  );
}
