import { boolean, number, object, string, z } from 'zod';

import { sortTrackingEventSchema } from '../analytics';

export const SwitchUserPreferenceSchema = object({
  preferenceId: number(),
  isEnabled: boolean(),
});

export const UserPreferenceSchema = object({
  name: string(),
  preferenceId: number(),
  description: string(),
  isEnabled: boolean(),
  type: string().optional(),
  /** Has the user explicitly set this preference, or are we using a default? */
  isFallbackValue: boolean().optional(),
});

export const SwitchUserPreferencesRequestSchema = object({
  preferences: SwitchUserPreferenceSchema.array(),
});

export type SwitchUserPreferencesRequest = z.infer<
  typeof SwitchUserPreferencesRequestSchema
>;

export type SwitchUserPreference = z.infer<typeof SwitchUserPreferenceSchema>;

export type UserPreference = z.infer<typeof UserPreferenceSchema>;

export const SetUserInsightOrderSchema = z.object({
  preferenceId: z.number(),
  sortClickedTrackingEventObj: sortTrackingEventSchema,
});

const PreferenceTypeSchema = z.enum(['insightOrder']);

export const StaticPreferenceSchema = z.object({
  id: number(),
  name: string(),
  description: string(),
  defaultIsEnabled: boolean(),
  type: PreferenceTypeSchema.optional().nullable(),
});

export type StaticPreference = z.infer<typeof StaticPreferenceSchema>;

export const InsightOrderPreferenceSchema = object({
  preferenceId: number(),
  name: string(),
});

export type InsightOrderPreference = z.infer<typeof InsightOrderPreferenceSchema>;
