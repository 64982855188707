export type AskMarleeContextType = 'workspace' | 'team' | 'user';

export enum AskMarleeQuestionInputType {
  preSelected = 'preSelected',
  freeText = 'freeText',
}

export enum AskMarleeQuestionType {
  individual = 'individual',
  groupComparison = 'groupComparison',
}

export enum AskMarleeLLM {
  gpt = 'GPT',
  gptOmni = 'GPT_OMNI',
  gemini = 'GEMINI',
}

type AskMarleeContextCommon = {
  id: number;
  displayName: string;
  type: AskMarleeContextType;
  // TODO we could refine this and remove the optionals from this common type
  firstName?: string;
  lastName?: string;
  gender?: string;
  includesMe?: boolean;
  isMe?: boolean;
};

export type AskMarleeContext = AskMarleeContextCommon &
  (
    | {
        type: 'team' | 'workspace';
        includesMe?: boolean;
      }
    | {
        type: 'user';
        firstName: string;
        lastName: string;
        gender: string;
        isMe?: boolean;
      }
  );

export type PromptQuestion = {
  id: number;
  question: string;
  shortCircuitType?: string;
  response?: string;
};
