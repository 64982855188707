import { ScrollContext, useAppContext } from '@/contexts';

import { ScrollArea } from './scroll-area';

type Props = {
  children: React.ReactNode;
};

export const AppScrollArea = ({ children }: Props) => {
  const { scrollAreaRef } = useAppContext(ScrollContext);

  return (
    <ScrollArea flex flexDirection="flex-col" ref={scrollAreaRef}>
      {children}
    </ScrollArea>
  );
};
