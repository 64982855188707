import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useMaybeUser } from '@/api-queries/app-settings';

import { ForgotPassword } from './forgot-password';
import { ResetPassword } from './reset-password';
import { SigninForm } from './sign-in-form';
import { InviteSignInRoot } from './sign-in-invites/root';
import { SignInMagicInviteRoot } from './sign-in-magic-link-invite/root';
import { SigninToken } from './sign-in-token';

import './style.scss';

import { useQuery } from '@/lib/hooks/useQuery';

export function SigninRoot(): React.ReactElement {
  const { path } = useRouteMatch();
  const { isLoggedIn } = useMaybeUser();
  const { redir } = useQuery<{ redir?: string }>();

  /**
   * Note (Search 91c34bdb for related notes)
   * We set redir at root-level for both sign-in and sign-up so that there's no confusion about who has priority in redirection
   * The priority goes to localStorage's redir as the user should end up where the site initially wanted to redirect them
   */
  localStorage.setItem('redir', redir ?? '');

  return (
    <Switch>
      <Route path={`${path}/organization/invite/:token`} component={InviteSignInRoot} />
      <Route
        path={`${path}/organization/join/:token`}
        component={SignInMagicInviteRoot}
      />
      <Route exact path={`${path}/forgot`} component={ForgotPassword} />
      <Route exact path={`${path}/reset`} component={ResetPassword} />
      <Route exact path={`${path}/token`} component={SigninToken} />
      <Route exact path={`${path}/create`}>
        <ResetPassword create />
      </Route>

      <Route exact path={path}>
        {isLoggedIn ? <Redirect to={redir || '/'} /> : <SigninForm />}
      </Route>
    </Switch>
  );
}
