
import { z } from 'zod';

export const LanguageSchema = z.enum([
  'ar-ae',
  'zh-cn',
  'cs-cz',
  'da-dk',
  'he-il',
  'id-id',
  'it-it',
  'ja-jp',
  'ko-kr',
  'ms-my',
  'nl-nl',
  'es-ar',
  'sv-se',
  'fr-fr',
  'de-de',
  'pl-pl',
  'pt-pt',
  'ro-ro',
  'ru-ru',
  'en-us',
  'th-th',
  'tr-tr',
]).catch('en-us');

export type LanguageCode = z.infer<typeof LanguageSchema>;

export const QUESTIONS_LANGUAGES = [
  { flag: '🇦🇪', display: 'Arabic', code: 'ar-ae' },
  { flag: '🇨🇳', display: 'Chinese', code: 'zh-cn' },
  { flag: '🇨🇿', display: 'Czech', code: 'cs-cz' },
  { flag: '🇩🇰', display: 'Danish', code: 'da-dk' },
  { flag: '🇮🇱', display: 'Hebrew', code: 'he-il' },
  { flag: '🇮🇩', display: 'Indonesian', code: 'id-id' },
  { flag: '🇮🇹', display: 'Italian', code: 'it-it' },
  { flag: '🇯🇵', display: 'Japanese', code: 'ja-jp' },
  { flag: '🇰🇷', display: 'Korean', code: 'ko-kr' },
  { flag: '🇲🇾', display: 'Malay', code: 'ms-my' },
  { flag: '🇳🇱', display: 'Dutch', code: 'nl-nl' },
  { flag: '🇪🇸', display: 'Spanish', code: 'es-ar' },
  { flag: '🇸🇪', display: 'Swedish', code: 'sv-se' },
  { flag: '🇫🇷', display: 'French', code: 'fr-fr' },
  { flag: '🇩🇪', display: 'German', code: 'de-de' },
  { flag: '🇵🇱', display: 'Polish', code: 'pl-pl' },
  { flag: '🇵🇹', display: 'Portuguese', code: 'pt-pt' },
  { flag: '🇷🇴', display: 'Romanian', code: 'ro-ro' },
  { flag: '🇷🇺', display: 'Russian', code: 'ru-ru' },
  { flag: '🇦🇺', display: 'English', code: 'en-us' },
  { flag: '🇹🇭', display: 'Thai', code: 'th-th' },
  { flag: '🇹🇷', display: 'Turkish', code: 'tr-tr' },
] as const satisfies { flag: string; display: string; code: LanguageCode }[]
