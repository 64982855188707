import queryString from 'query-string';

import { type UnacceptedConnectionNotification } from '@f4s/types';

import { httpAgent as http } from './agents/http';

type GetOptions = { page?: number; limit?: number };
export const get = (opts: GetOptions = {}) => {
  const { page = 1, limit = 10 } = opts;
  const query = queryString.stringify({ page, limit });
  return http().get(`/api/v1/users/notifications?${query}`);
};

export const getUnreadNotificationsCount = () =>
  http().get('/api/v2/notifications/unread-count');

export const getUnacceptedConnectionNotifications = () =>
  http().get<UnacceptedConnectionNotification[]>(
    '/api/v2/notifications/unaccepted-connections',
  );

export const read = (id: string) => http().put('/api/v1/notification/status', { id });
export const accept = (id: string, status: 1 | 2) =>
  http().put('/api/v1/notification/accept', { id, status });

/**
 * New It just works notifications route
 */
export const getAll = (opts: GetOptions = {}) => {
  const { page = 1, limit = 10 } = opts;
  const query = queryString.stringify({ page, limit });
  return http().get(`/api/v3/notifications?${query}`);
};
