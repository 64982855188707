import { z } from 'zod';

export const SwitchShareCodeSchema = z.object({
  shareCode: z.string(),
  enabled: z.boolean(),
});

export const ShareTypeSchema = z.enum(['dashboard', 'report']);
export type ShareType = z.infer<typeof ShareTypeSchema>;

export const ShareScopeSchema = z.enum(['published', 'anyoneWithLink', 'private']);
export type ShareScope = z.infer<typeof ShareScopeSchema>;

const BaseSchema = z.object({
  id: z.number(),
  code: z.string(),
  ownerId: z.number(),
  scope: ShareScopeSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const ReportShareSchema = BaseSchema.merge(
  z.object({
    type: z.literal('report'),
    reportId: z.number(),
  }),
);
export type ReportShare = z.infer<typeof ReportShareSchema>;
export const DashboardShareSchema = BaseSchema.merge(
  z.object({
    type: z.literal('dashboard'),
    dashboardId: z.number(),
  }),
);
export type DashboardShare = z.infer<typeof DashboardShareSchema>;

export const ShareSchema = z.union([ReportShareSchema, DashboardShareSchema]);
export type Share = z.infer<typeof ShareSchema>;

export const ShareViewerSchema = z.object({ id: z.number() }).and(
  z.union([
    z.object({
      type: z.literal('space'),
      spaceId: z.number(),
    }),
    z.object({
      type: z.literal('user'),
      userId: z.number(),
    }),
    // For when we have fresh new teams feature to link to
    // z.object({
    //   type: z.literal('team'),
    //   teamId: z.number(),
    // }),
  ]),
);
export type ShareViewer = z.infer<typeof ShareViewerSchema>;

const createOmits = {
  id: true,
  ownerId: true,
  code: true,
  createdAt: true,
  updatedAt: true,
} as const;
export const ShareReportCreateRequestSchema = ReportShareSchema.omit(createOmits);
export const ShareDashboardCreateRequestSchema = DashboardShareSchema.omit(createOmits);
export const ShareCreateRequestSchema = z.union([
  ShareReportCreateRequestSchema,
  ShareDashboardCreateRequestSchema,
]);
export type ShareCreateRequest = z.infer<typeof ShareCreateRequestSchema>;
export type ShareReportCreateRequest = z.infer<typeof ShareReportCreateRequestSchema>;
export type ShareDashboardCreateRequest = z.infer<
  typeof ShareDashboardCreateRequestSchema
>;
