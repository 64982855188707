import { z } from 'zod';

import { Question } from './question';
import { QuestionAnswerSchema } from './questionnaire';

export type MiniAssessment = {
  slug: string;
  name: string;
  description: string;
  motivations: string[];
  questions: {
    number: number;
    language: string;
    text: string;
    answers: { position: number; text: string }[];
    isAnswered?: boolean;
  }[];
};

export type MiniAssessmentQuestions = {
  questions: Question[];
  savedQuestionNo: number;
};
export type MiniAssessmentGeneralInfo = {
  slug: string;
  name: string;
  motivationCount: number;
  questionCount: number;
};
export type AssessmentBlock =
  | 'action-level'
  | 'action-direction'
  | 'authority'
  | 'task-direction'
  | 'scope'
  | 'communication'
  | 'environment'
  | 'responsibility'
  | 'change'
  | 'work-approach'
  | 'time'
  | 'influence'
  | 'rules'
  | 'convincer-process'
  | 'interest-filters'
  | 'convincer-input'
  | 'full';

export type MiniAssessmentResult = {
  relative: number;
  pattern: string;
};

export const MiniAssessmentCreateAndFinalizeSchema = z.object({
  answers: z.array(QuestionAnswerSchema),
  blockSlug: z.string(),
});

export type MiniAssessmentCreateAndFinalize = z.infer<
  typeof MiniAssessmentCreateAndFinalizeSchema
>;

export type MiniAssessmentBlock = {
  slug: string;
  name: string;
  motivations: string[];
  description: string;
  order: number | null;
};
