import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';

import { useUser } from '@/api-queries/app-settings';
import { SignupOnboardingIndex } from '@/layouts/signup-onboarding-index';
import { FlowForm } from 'Components/flow-form';
import { DEFAULT_PAGE_APP } from 'Constants';
import permissionScript from 'JSONs/member-permission.json';
import { t } from 'Lib/i18n';
import { isEmpty } from 'Underscore';

import { getLocalOrgData } from '../utils';
import { getPermissions, joinOrganization, updateJoinStatus } from './join-utils';

const checkIcon = "<img class='inline-icon' src='/assets/circle-check.svg'/>";
const cancelIcon = "<img class='inline-icon' src='/assets/circle-cancel.svg'/>";

export function MemberPermissionAgreement(): React.ReactElement {
  const { search } = useLocation();
  const { orgName } = getLocalOrgData();
  const { user, updateProfile } = useUser();
  const history = useHistory();
  const [data, setData] = useState({
    joinOrCancel: '',
    brandName: '',
    permissionAllows: '',
    permissionNotAllows: '',
    permissionAllowTitle: '',
    permissionNotAllowTitle: '',
    orgDeclineTitle: t('org_decline_title', { orgName }),
    declineIntroduction1: t('org_decline_introduction_1', { orgName }),
    declineIntroduction2: AppText.org_decline_introduction_2,
    declineIntroduction3: AppText.org_decline_introduction_3,
    declineIntroduction4: AppText.org_decline_introduction_4,
  });
  const [step, setStep] = useState('ask');

  // TODO: Add validation to ensure this isn't an array
  const { redir } = queryString.parse(search) as { redir?: string };

  const updateJoinBrandStatus = async (status) => {
    const updateUser = await updateJoinStatus({ status });
    if (!isEmpty(updateUser)) updateProfile(updateUser);
    history.push(redir || DEFAULT_PAGE_APP);
  };

  const preJoinOrganization = async () => {
    const shouldAskPermission = await joinOrganization({ user });
    if (shouldAskPermission) return;
    history.push(redir || DEFAULT_PAGE_APP);
  };

  const getBrandPermissions = async () => {
    const {
      data: { allowTitle, notAllowTitle, allows, notAllows },
    } = await getPermissions();

    setData({
      ...data,
      brandName: orgName,
      permissionAllows: allows.map((allow) => `<li>${checkIcon} ${allow}</li>`).join(''),
      permissionNotAllows: notAllows
        .map((notAllow) => `<li>${cancelIcon} ${notAllow}</li>`)
        .join(''),
      permissionAllowTitle: allowTitle.replaceAll('${name}', orgName),
      permissionNotAllowTitle: notAllowTitle.replaceAll('${name}', orgName),
    });
  };

  useEffect(() => {
    getBrandPermissions();
    preJoinOrganization();
  }, []);

  return (
    <SignupOnboardingIndex>
      <FlowForm
        script={permissionScript}
        data={data}
        onDataChange={(v) => {
          setData(v);
        }}
        onStepChange={async (oldStep) => {
          const agreeJoin = oldStep === 'ask' && data.joinOrCancel === 'yes';
          const declineJoin = oldStep === 'alert' && data.joinOrCancel === 'no';

          if (oldStep === 'ask' && data.joinOrCancel === 'no') {
            setStep('alert');
            return;
          }

          if (oldStep === 'alert' && data.joinOrCancel === 'yes') {
            setStep('ask');
            return;
          }

          if (agreeJoin) await updateJoinBrandStatus('approved');
          if (declineJoin) await updateJoinBrandStatus('declined');
        }}
        step={step}
      />
    </SignupOnboardingIndex>
  );
}
