import { z, type Schema } from 'zod';

type ObjectName = 'model';

const modelBaseSchema = z.object({
  detailedReport: z.string(),
  modelView: z.enum(['benchmarking']),
  groupSize: z.number(),
  modelName: z.string(),
  reportType: z.enum(['xFactor', 'culture', 'insight']),
});

const modelMotivationSchema = z.object({
  motivation: z.string(), // TODO: could be an enum of our motivation names
  motivationScore: z.number(),
  coachingRecommendations: z.string(), // TODO: could be an enum of our coaching programs
});

const modelSchemas = {
  model_clicked: modelBaseSchema,
  model_viewed: modelBaseSchema,
  model_explored: modelBaseSchema.and(modelMotivationSchema),
  model_added: modelBaseSchema.and(modelMotivationSchema),
} satisfies { [key: `${ObjectName}_${string}`]: Schema };

export { modelSchemas };
