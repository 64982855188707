import { getEmojiPath } from 'Lib/emoji';

import 'Styles/emoji.scss';

type EmojiProps = {
  value: string;
  className?: string;
};

// Render an emoji as an image with matching attributes for the markdown plugin
export const Emoji = ({ value, className }: EmojiProps) => (
  <img src={getEmojiPath(value)} alt={value} className={className} />
);
