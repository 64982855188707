import { ReferralPageTemplate } from './referral-template';

export const CultureSurvey = ({ country, referrer }) => (
  <ReferralPageTemplate useConfetti={false}>
    <h2>
      {referrer?.name} invited you to participate in our {country} culture study!
    </h2>
    <p>
      Click the button below to participate in the world first {country} culture study. It
      will take you just 15-20 minutes and can make a huge difference to {country}.
    </p>
    <p>
      On completion you will go in the draw from your change to win a Series 7 Apple
      Watch!
    </p>
    <p>You will also enjoy:</p>
    <ul>
      <li>Be able to invite others to increase your chances to win! </li>
      <li>revolutionary Insights into your motivations (soft human skills) at work</li>
      <li>connect & share results with {referrer?.name} and others you know</li>
      <li>world-class team building tools</li>
      <li>AI coaching with your own AI Coach Marlee</li>
      <li>and so much more!</li>
    </ul>
  </ReferralPageTemplate>
);
