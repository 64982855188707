import { type OrgCreationSearchUser, type UserSearchResult } from '@f4s/types';

import { useAppContext } from '@/contexts';
import { OrgCreationContext } from '@/contexts/org-creation';

import {
  type CreatableOrganizationMember,
  type CreatableTeamMember,
  type SelectableTeamMember,
  type UserOrEmail,
} from '../../types';
import { UserSearch } from '../user-search';
import { MemberListWithControls } from './member-list-base';

type Props = {
  onMemberAdded?: (member: UserOrEmail) => void;
  onMemberRemoved?: (member: CreatableTeamMember) => void;
};

export const AddMember = ({ onMemberAdded, onMemberRemoved }: Props) => {
  const { handleMemberChange, members } = useAppContext(OrgCreationContext);

  const handleUserSelect = (input: SelectableTeamMember) => {
    if (input.type === 'meOverTime') return;
    const member = mapUserSearchToOrgMember(input);
    const newMembers = [...members, member];
    handleMemberChange(newMembers);
    onMemberAdded?.(input);
  };

  return (
    <>
      <UserSearch
        label="Members"
        placeholder="Search existing connections or add more members via email"
        onUserSelect={handleUserSelect}
        excludeMembers={members.map(mapOrgMemberToUserSearch)}
      />
      <MemberListWithControls
        hasRemove
        members={members.map(mapOrgMemberToUserSearch)}
        onMemberRemoved={onMemberRemoved}
        updateMembers={(newMembers) =>
          handleMemberChange(
            newMembers.map((m) =>
              mapUserSearchToOrgMember(m as CreatableOrganizationMember),
            ),
          )
        }
      />
    </>
  );
};

function mapUserSearchToOrgMember(input: UserOrEmail): OrgCreationSearchUser {
  if (input.type === 'email')
    return {
      emailAddress: input.email,
      isAdmin: false,
      isMember: true,
    } satisfies OrgCreationSearchUser;

  return {
    id: input.user.userId,
    userId: input.user.userId,
    firstName: input.user.firstName,
    lastName: input.user.lastName,
    profileImageUrl: input.user.profileImageUrl,
    isAdmin: false,
    isMember: true,
  } as OrgCreationSearchUser;
}

function mapOrgMemberToUserSearch(member: OrgCreationSearchUser): CreatableTeamMember {
  if (!('userId' in member))
    return {
      type: 'email',
      email: member.emailAddress,
      role: {
        isAdmin: member.isAdmin,
        isMember: member.isMember,
        isViewer: false, // unused in workspaces
      },
    };

  return {
    type: 'user',
    user: {
      id: member.id,
      userId: member.id,
      firstName: member.firstName,
      lastName: member.lastName,
      profileImageUrl: member.profileImageUrl,
    } as UserSearchResult,
    role: {
      isAdmin: member.isAdmin,
      isMember: member.isMember,
      isViewer: false, // unused in workspaces
    },
  };
}
