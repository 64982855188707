import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import AppText from '@f4s/i18n/en.json';
import { type User } from '@f4s/types';

import { useFetchAppSettings } from '@/api-queries/app-settings';
import { Button } from '@/components/common/button/button';
import { Input } from '@/components/form_elements';
import { INVITE_LOCAL_STORAGE_KEY, ORG_LOCAL_STORAGE_KEY } from '@/constants';
import { logError } from '@/lib/log-error';
import { isEmpty } from '@/lib/underscore';
import * as API from 'APIs';
import { SocialButtonsV2, socialLink } from 'Components/buttons/social-buttons-v2';
import { Divider } from 'Components/view/divider';
import { Notification } from 'Components/view/snackbar';
import { usePageView } from 'Hooks/usePageView';
import { AuthDashboardIndexV2 } from 'Layouts';
import { t } from 'Lib/i18n';
import { errorHandler } from 'Utils/error-handler';
import { formSubmit } from 'Utils/form-submit';
import { BrowserStorage, getStorageItem } from 'Utils/local-storage';

import { CobrandedBanner } from '../../components/ui/cobranded-banner';
import {
  checkForPendingGoalsAndSave,
  getLocalReferralCode,
  getStoredInvitationData,
} from '../sign-up/utils';
import { submitPendingMiniAssessment } from './mini-assessment';
import { Messages } from './sign-in-form.messages';

import styles from './signin.module.scss';

const storage = BrowserStorage('local');

function shouldRedirectToIntegrationAuth(params: {
  integration?: string;
  redirect?: string;
}) {
  return Boolean(getRedirectToIntegrationAuth(params));
}

function getRedirectToIntegrationAuth(params: {
  integration?: string;
  redirect?: string;
}) {
  const { integration, redirect } = params;
  if (integration && integration === 'blisspot') {
    return `/integration-auth/${integration}?redir=${redirect}`;
  }
  return '';
}

function shouldRedirectToBrandJoinPermission(user: User): boolean {
  const { referralCode, partners } = user;
  const partner = partners?.find((p) => p.referralCode === referralCode);

  const askPermission =
    !isEmpty(partner) && partner?.askPermission && partner?.status === 'pending';

  if (askPermission) {
    storage.setItem(
      ORG_LOCAL_STORAGE_KEY,
      JSON.stringify({
        type: 'partner',
        referralCode,
        orgId: partner.id,
        orgName: partner.name,
      }),
    );
  }

  const localOrgStorage = getStorageItem({
    storage,
    key: ORG_LOCAL_STORAGE_KEY,
  });

  if (isEmpty(localOrgStorage)) return false;

  if (!localOrgStorage.orgId || !localOrgStorage.orgName) {
    logError(
      new Error(
        `User has hit org join page with bad org data: ${JSON.stringify(
          localOrgStorage,
        )}`,
      ),
    );
    return false;
  }

  return true;
}

function getBrandJoinPermissionPath(from = ''): string {
  const redir = from ? `?redir=${from}` : '';
  const localOrgStorage = getStorageItem({
    storage,
    key: ORG_LOCAL_STORAGE_KEY,
  });
  const { type, orgId } = localOrgStorage;

  if (orgId) {
    const page = type === 'client' ? 'email-verification' : 'permission';
    return `/brand-join/${type}/${page}${redir}`;
  }

  return `/brand-join/partner/permission${redir}`;
}

function getRedirectPathAfterSignin(user: User, params): string {
  if (shouldRedirectToIntegrationAuth(params)) {
    return getRedirectToIntegrationAuth(params);
  }

  const localInvitationStorage = getStorageItem({
    storage: sessionStorage,
    key: INVITE_LOCAL_STORAGE_KEY,
  });

  if (localInvitationStorage.redirect) return localInvitationStorage.redirect;
  if (shouldRedirectToBrandJoinPermission(user)) {
    return getBrandJoinPermissionPath('/');
  }

  return params.redir || '/';
}

function getInviteEmail() {
  const invitation = getStoredInvitationData();
  return invitation?.inviteEmail || '';
}

type SigninFormProps = {
  defaults: { emailAddress: string };
  propertiesToSetOnSignIn: Record<string, string>;
  onSuccess?: () => void;
  ssoRedir: string;
  signupFlow?: string;
  banner?: React.ReactElement;
};
export function SigninForm({
  defaults,
  propertiesToSetOnSignIn,
  onSuccess,
  ssoRedir,
  signupFlow,
  banner,
}: Partial<SigninFormProps>) {
  usePageView('Sign In', { method: 'Password' });

  const { refetch: refetchAppSettings } = useFetchAppSettings();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginDetail, setLoginDetail] = useState({
    emailAddress: getInviteEmail() ?? defaults?.emailAddress,
    password: '',
  });

  const referralCode = getLocalReferralCode();
  const { search } = useLocation();
  const params: any = queryString.parse(search);

  const onLoginDetailChange = (data: any) =>
    setLoginDetail({
      ...loginDetail,
      ...data,
    });

  const onSignin = (e: any) =>
    formSubmit(e, async () => {
      setLoading(true);
      try {
        const { data: signInUser } = await API.user.signInV2(loginDetail);

        let propertiesToUpdate: Partial<User> = {
          lastLoginAt: new Date().toISOString(),
          userId: signInUser.id,
        };
        if (propertiesToSetOnSignIn) {
          propertiesToUpdate = {
            ...propertiesToUpdate,
            properties: propertiesToSetOnSignIn,
          };
        }
        await API.user.updateProfile(propertiesToUpdate);

        // This is here just in case the user logs in via the 'normal' sign-in page
        await submitPendingMiniAssessment();

        checkForPendingGoalsAndSave();

        Notification.create(t('notification_modal_sign_in_success'), 'success');

        await refetchAppSettings();

        if (onSuccess) {
          onSuccess();
        } else {
          history.push(getRedirectPathAfterSignin(signInUser, params));
        }
      } catch (error) {
        errorHandler(error, Notification);
      }
      setLoading(false);
    });

  useEffect(() => {
    if (params.error === 'unauthorized') {
      Notification.create(AppText.notification_modal_sign_in_error_social, 'error', {});
    } else if (params.error === 'email-exists') {
      const errorMessage = AppText.notification_modal_sign_up_this_email_exists_error;
      Notification.create(errorMessage, 'error', {});
    }
  }, []);

  const socialParams = {
    referralCode: referralCode || undefined,
    redir: ssoRedir || params.redir,
    signupFlow: signupFlow || propertiesToSetOnSignIn?.signupFlow,
  };

  return (
    <AuthDashboardIndexV2 displaySignup>
      <form className={styles.loginFormRoot} onSubmit={onSignin}>
        <h2 className={styles.loginFormHeading}>{Messages.pageTitle()}</h2>
        <CobrandedBanner className="my-4" isLogin />
        {banner}
        <SocialButtonsV2
          links={{
            google: socialLink('google', socialParams),
            facebook: socialLink('facebook', socialParams),
            linkedin: socialLink('linkedin', socialParams),
          }}
          continueWith
        />
        <div className={styles.loginFormDivider}>
          <Divider text={Messages.optionsDividerText()} />
        </div>
        <Input
          type="email"
          placeholder={Messages.emailFieldPlaceholder()}
          defaultValue={loginDetail.emailAddress}
          onchange={(value) => onLoginDetailChange({ emailAddress: value })}
          autoFocused
          forceFocused
          required
          classes={styles.loginFormInput}
        />
        <Input
          placeholder={Messages.passwordFieldPlaceholder()}
          type="password"
          onchange={(value) => onLoginDetailChange({ password: value })}
          forceFocused
          required
          classes={styles.loginFormInput}
        />
        <Button
          variant="primary"
          text={Messages.signInButton()}
          isLoading={loading}
          isSubmitBtn
          isFullWidth
        />
        <div className="login-form__subtitle text-center">
          <Link to="/sign-in/forgot">{Messages.forgotPasswordButton()}</Link>
        </div>
      </form>
    </AuthDashboardIndexV2>
  );
}
