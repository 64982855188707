import { clsx } from 'clsx';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useSignUpMutation } from '@/api-queries/users';
import { sendEvent } from '@/apis/analytics';
import { SocialButtonsV2, socialLink } from '@/components/buttons/social-buttons-v2';
import { Button } from '@/components/common/button/button';
import { Input } from '@/components/form_elements';
import { RichText } from '@/components/rich-text/text';
import { Checkbox } from '@/components/ui/checkbox';
import { CobrandedBanner } from '@/components/ui/cobranded-banner';
import { Divider } from '@/components/view/divider';
import { Validations } from '@/lib/utils/validations';
import { getLocalReferralCode, getStoredInvitationData } from '@/pages/sign-up/utils';

import { type CreateAccountStepType } from '../types';
import { Messages } from './create-account-step.messages';

import styles from '../style-signup.module.scss';

function getInviteEmail() {
  const invitation = getStoredInvitationData();
  return invitation?.inviteEmail || '';
}

export const CreateAccountStep = ({
  signUpDetails,
  handleInputChange,
  nextStep,
  ssoRedir,
  signupFlow,
  banner,
  isZoom = false,
}: CreateAccountStepType): React.ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showHint, setShowHint] = useState<boolean>(false);
  const [isOpenToEmails, setIsOpenToEmails] = useState<boolean>(false);

  const { token } = useParams<{ token: string }>();
  const referralCode = getLocalReferralCode();

  const email = signUpDetails.email || getInviteEmail();
  const history = useHistory();
  const params: any = useParams();
  const { mutateAsync: signUpCall, isLoading: loaderState } = useSignUpMutation();

  const submitHandler = async (userEmail: string | undefined) => {
    if (!userEmail) {
      setErrorMessage(Messages.errorMissingEmail());
    } else if (userEmail && !Validations.email(userEmail)) {
      setErrorMessage(Messages.errorInvalidEmail());
    } else {
      setErrorMessage('');
      sendEvent('signUp_started', {
        signUpConnection: 'email',
        signUpMarketing: isOpenToEmails,
        signUpFlow: signupFlow,
        context: isZoom ? 'zoomApp' : 'app',
      });
      localStorage.setItem('newSignUp', 'true');
      try {
        await signUpCall({
          emailAddress: email,
          signupFlow,
          isOpenToMarketingEmails: isOpenToEmails,
        });
        nextStep();
      } catch {
        // this check to handle magic-link flow
        if (token) {
          // pause to display message
          setTimeout(() => {
            history.push(`/sign-in/organization/join/${token}`);
          }, 2000);
        }
      }
    }
  };

  const socialParams = {
    referralCode,
    redir: ssoRedir || params.redir,
    signupFlow,
  };

  return (
    <>
      <div className={styles.signUpStepsHeadingBox}>
        <h2 className={styles.signUpStepsHeading}>{Messages.pageTitle()}</h2>
      </div>
      <CobrandedBanner className="my-4" />
      {banner}
      <div className={styles.socialLinksContainer}>
        <div className={styles.socialButtonsV3}>
          <SocialButtonsV2
            links={{
              google: socialLink('google', socialParams),
              facebook: socialLink('facebook', socialParams),
              linkedin: socialLink('linkedin', socialParams),
            }}
          />
        </div>
      </div>
      <div className={styles.signupDivider}>
        <Divider text="Or" />
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Input
          type="email"
          defaultValue={email}
          onchange={(value: string) => {
            setErrorMessage('');
            setErrorMessage('');
            handleInputChange('email', value);
          }}
          autoFocused={false}
          forceFocused
          required
          placeholder={Messages.workEmailFieldPlaceholder()}
          classes={clsx(styles.step1Input, {
            [styles.invalidInput]: errorMessage.length > 0,
          })}
          onfocus={() => setShowHint(true)}
        />
        {errorMessage && (
          <div className={styles.mb12Px}>
            <span className={styles.errorText}>{errorMessage}</span>
          </div>
        )}
        {showHint && (
          <div className={styles.infoMessage}>
            <RichText className={styles.messageBox}>
              {Messages.workEmailFieldHint()}
            </RichText>
          </div>
        )}

        <div className="my-4">
          <label className="flex">
            <Checkbox
              onCheckedChange={(value) => {
                if (typeof value === 'boolean') {
                  // Without this check it value is indeterminate
                  setIsOpenToEmails(value);
                }
              }}
            />
            <span className="text-muted-foreground ml-3 text-xs md:w-5/6">
              {Messages.allowMarketingEmailsCheckboxLabel()}
            </span>
          </label>
        </div>

        <div className={styles.signupBtnWrapper}>
          <Button
            isSubmitBtn
            variant="primary"
            text={Messages.signUpSubmitButton()}
            size="lg"
            isLoading={loaderState}
            onClick={() => submitHandler(email)}
            isFullWidth
          />
        </div>
      </form>

      <RichText className={styles.textForPrivacyTerms}>
        {Messages.signUpAcknowledgementTOS()}
      </RichText>
    </>
  );
};
