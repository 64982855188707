import { useQuery } from 'Hooks/useQuery';

import './coaching.scss';

export function CoachingLinkDone() {
  const { provider } = useQuery();

  const copy = {
    slack:
      'You can close this window, and return to Slack to continue your conversation with Marlee now.',
  };

  return (
    <div className="coaching-link-done">
      <img src="/assets/coach-icon-400x400.png" alt="Coach Marlee" />
      <h3>All Done!</h3>
      <p>{copy[String(provider)]}</p>
    </div>
  );
}
