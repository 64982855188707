import { z } from 'zod';

export const talentPoolCreateReqSchema = z.object({
  name: z.string(),
  isPublic: z.boolean().optional(),
});
export const talentPoolUpdateReqSchema = talentPoolCreateReqSchema.partial();

export type TalentPoolCreateReq = z.infer<typeof talentPoolCreateReqSchema>;
export type TalentPoolUpdateReq = z.infer<typeof talentPoolUpdateReqSchema>;

export const talentOpeningCreateReqSchema = z.object({ name: z.string() });
export const talentOpeningUpdateReqSchema = talentPoolCreateReqSchema.partial();

export type TalentOpeningCreateReq = z.infer<typeof talentOpeningCreateReqSchema>;
export type TalentOpeningUpdateReq = z.infer<typeof talentOpeningUpdateReqSchema>;

export const talentProfileCreateReqSchema = z.object({ name: z.string() });
export const talentProfileUpdateReqSchema = talentPoolCreateReqSchema.partial();

export type TalentProfileCreateReq = z.infer<typeof talentProfileCreateReqSchema>;
export type TalentProfileUpdateReq = z.infer<typeof talentProfileUpdateReqSchema>;
